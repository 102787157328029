var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Row } from '@robinpowered/design-system';
import { Colors } from '@robinpowered/design-system';
import OfficeSpace from '../../../../images/OfficeSpace.png';
import { Body07 } from '@robinpowered/design-system';
import GroupSolid from '@robinpowered/icons/GroupSolid';
import PhoneSolid from '@robinpowered/icons/PhoneSolid';
import VideoCallSolid from '@robinpowered/icons/VideoCallSolid';
import { useTranslation } from 'react-i18next';
export var SpaceItem = function (_a) {
    var name = _a.name;
    var t = useTranslation('downloadMobileAppModal').t;
    return (_jsxs(_Fragment, { children: [_jsxs(Item, { children: [_jsxs(SpaceColumn, { children: [_jsx(SpaceName, { children: name }), _jsxs(SpaceRow, { children: [_jsx(VideoCallSolid, { size: 13, color: Colors.Gray60 }), _jsx(GroupSolid, { size: 13, color: Colors.Gray60 }), _jsx(PhoneSolid, { size: 13, color: Colors.Gray60 }), _jsx(Label, { children: t('space_item_label') })] })] }), _jsx(SpaceImage, { src: OfficeSpace })] }), _jsx(BookSpaceButton, { children: t('space_item_button') })] }));
};
var SpaceName = styled(Body07)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 2px;\n  font-size: 8;\n  font-weight: 400;\n"], ["\n  margin-bottom: 2px;\n  font-size: 8;\n  font-weight: 400;\n"])));
var Item = styled(Row)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-direction: row;\n  margin-bottom: 5px;\n  margin-top: 6px;\n"], ["\n  flex-direction: row;\n  margin-bottom: 5px;\n  margin-top: 6px;\n"])));
var SpaceRow = styled(Row)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-direction: row;\n  gap: 2;\n  width: 155px;\n"], ["\n  flex-direction: row;\n  gap: 2;\n  width: 155px;\n"])));
var SpaceColumn = styled(Row)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-direction: column;\n"], ["\n  flex-direction: column;\n"])));
var SpaceImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 45px;\n"], ["\n  width: 45px;\n"])));
var Label = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 8px;\n  color: ", ";\n  background-color: ", ";\n  border-radius: 2px;\n  padding: 1px 2px 1px 4px;\n  margin-left: 2px;\n"], ["\n  font-size: 8px;\n  color: ", ";\n  background-color: ", ";\n  border-radius: 2px;\n  padding: 1px 2px 1px 4px;\n  margin-left: 2px;\n"])), Colors.Gray60, Colors.Gray10);
var BookSpaceButton = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  border-radius: 4px;\n  padding: 5px 8px 5px;\n  width: 40px;\n  font-size: 8px;\n  font-weight: 500;\n"], ["\n  border: 1px solid ", ";\n  border-radius: 4px;\n  padding: 5px 8px 5px;\n  width: 40px;\n  font-size: 8px;\n  font-weight: 500;\n"])), Colors.Gray30);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
