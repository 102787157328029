import styled from '@emotion/styled';
import { Body06 } from '@robinpowered/design-system';

import { useAmplitude, useAuthContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import { Ellipsis } from 'styles';
import { createQueryString } from 'utilities';
import { UserWeekDay } from '../../../../../../../../constants';
import { DayInfo } from './DayInfo';
import { Popover } from './Popover';
import { PrimaryLink } from './PrimaryLink';

type Props = {
  avatar?: string | null;
  name?: string | null;
  day: UserWeekDay;
  closePopover: () => void;
};

export const DayPopover = ({ avatar, name, day, closePopover }: Props) => {
  const { t } = useTranslation('common');
  const { orgSlug } = useAuthContext();
  const { trackEvent } = useAmplitude();
  const reservation = day.reservation?.reservation;
  const floorString = `${reservation?.seat.level?.name}, ${reservation?.seat.location.name}`;

  if (!reservation && !day.visit) {
    return null;
  }

  return (
    <Popover bottomAdjustment={!!reservation} onBlur={() => closePopover()}>
      <DayInfo day={day} avatar={avatar} name={name} />
      {reservation && (
        <>
          <Floor>{floorString}</Floor>
          <PrimaryLink
            href={createQueryString(
              reservation.startTime,
              reservation.seat.level?.id,
              reservation.id,
              orgSlug
            )}
            onClick={() => trackEvent('clicked-compare-schedules-sit-nearby')}
          >
            {t('sit_nearby')}
          </PrimaryLink>
        </>
      )}
    </Popover>
  );
};

const Floor = styled(Body06)`
  margin-left: 25px;
  ${Ellipsis}
`;
