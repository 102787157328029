import { gql, useQuery } from '@apollo/client';
import { useAuthContext } from 'contexts';
import { GetDeskReservationsQuery } from 'generated';
import moment from 'moment-timezone';
import { useMemo } from 'react';

export const GET_DESK_RESERVATIONS_BY_USER_IDS = gql`
  query GetDeskReservations($ids: [ID!]!, $startTime: Date!, $endTime: Date!) {
    getDeskReservationsByUserIds(
      ids: $ids
      startTime: $startTime
      endTime: $endTime
    ) {
      userId
      reservations {
        id
        timeZone
        startTime
        visibility
        type
        seat {
          id
          name
          type
          rawType
          zone {
            id
            name
          }
          level {
            id
          }
        }
      }
    }
  }
`;

/**
 * A hook which returns a list of users and their desk reservations for a time period
 */
export function useDeskReservations(userIds: string[]) {
  const { currentUser } = useAuthContext();

  const timeZone = useMemo(
    () => currentUser?.timeZone || moment.tz.guess(),
    [currentUser]
  );

  return useQuery<GetDeskReservationsQuery>(GET_DESK_RESERVATIONS_BY_USER_IDS, {
    skip: userIds.length === 0,
    variables: {
      ids: userIds,
      startTime: moment().tz(timeZone).startOf('day').toISOString(),
      endTime: moment().tz(timeZone).endOf('day').toISOString(),
    },
  });
}
