import { useQuery } from '@apollo/client';
import { useAuthContext } from 'contexts';
import { GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery } from 'generated';
import { GET_EMPLOYEE_VISITS_BY_DATE_ASC } from 'graphql/getEmployeeVisitsByDate';
import moment, { Moment } from 'moment-timezone';
import { useMemo } from 'react';

export type EmployeeVisitsByDate = NonNullable<
  // eslint-disable-next-line max-len
  GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery['getEmployeeVisitsForUsersAtLocationGroupedByDateAscending']
>;
export type EmployeeVisitByDate = EmployeeVisitsByDate[0]['employeeVisits'][0];

/**
 * A hook which returns a list dates with employee visits for the passed in users
 */
export function useGetEmployeeVisitsByDate(
  userIds: string[],
  startTime: Moment,
  endTime: Moment,
  locationId?: string
) {
  const { currentUser } = useAuthContext();

  const timeZone = useMemo(
    () => currentUser?.timeZone || moment.tz.guess(),
    [currentUser]
  );

  return useQuery<GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery>(
    GET_EMPLOYEE_VISITS_BY_DATE_ASC,
    {
      skip: userIds.length === 0 || !locationId,
      variables: {
        userIds,
        locationId,
        startTime: startTime.clone().tz(timeZone).startOf('day').toISOString(),
        endTime: endTime.clone().tz(timeZone).endOf('day').toISOString(),
      },
    }
  );
}
