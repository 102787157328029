import React from 'react';
import { Colors, Table } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { Breakpoints } from 'styles';
import { NoResultsCTAContainer } from '../NoResultsCTA';

export type ColumnType = {
  Header: string | JSX.Element;
  accessor: string;
  disableSortBy: boolean;
  hidebreakpoint?: Breakpoints;
};

type TableColumns =
  | 'user'
  | 'department'
  | 'preferredoffice'
  | 'desk'
  | 'favorite';

type TableData = {
  component: JSX.Element | null;
  hidebreakpoint?: Breakpoints;
};

export type TableProps = {
  data: Array<Record<TableColumns, TableData>>;
  columns: Array<ColumnType>;
  showNoResultsCTA?: boolean;
};

export const PeopleTable = ({
  data,
  columns,
  showNoResultsCTA = false,
}: TableProps): JSX.Element => {
  return (
    <Container>
      <PeopleTableWrapper>
        <thead>
          <TableHeaderContainer>
            {columns.map(({ Header, hidebreakpoint }, index) => (
              <TableHeader hidebreakpoint={hidebreakpoint} key={index}>
                {Header}
              </TableHeader>
            ))}
          </TableHeaderContainer>
        </thead>
        <tbody>
          {data.map(({ ...data }, index, arr) => (
            <TableCellContainer
              key={index}
              hasBorderRadius={arr.length - 1 === index}
            >
              {Object.values(data).map(
                ({ hidebreakpoint, component }, i, values) => (
                  <TableCell hidebreakpoint={hidebreakpoint} key={i}>
                    {component}
                  </TableCell>
                )
              )}
            </TableCellContainer>
          ))}
        </tbody>
      </PeopleTableWrapper>
      {showNoResultsCTA && (
        <NoResultCTAWrapper>
          <NoResultsCTAContainer />
        </NoResultCTAWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const PeopleTableWrapper = styled(Table)`
  table-layout: auto;
`;

type ResponsiveType = {
  hidebreakpoint?: string;
};

const TableHeaderContainer = styled(Table.Tr)`
  width: 100%;
  @media (max-width: ${Breakpoints.LG}) {
    > th:last-of-type {
      border-right: 1px solid ${Colors.Tan30};
      border-top-right-radius: 8px;
    }
  }
`;

const TableHeader = styled(Table.Th)<ResponsiveType>`
  ${(props) =>
    props.hidebreakpoint
      ? `@media (max-width: ${props.hidebreakpoint}) {
    display: none;
  }`
      : ''}
`;

const TableCellContainer = styled(Table.Tr)<{ hasBorderRadius: boolean }>`
  @media (max-width: ${Breakpoints.LG}) {
    > td:last-of-type {
      border-right: 1px solid ${Colors.Tan30};
      border-bottom-right-radius: ${(props) =>
        props.hasBorderRadius ? '8px' : '0px'};
    }
  }
`;

const TableCell = styled(Table.Td)<ResponsiveType>`
  vertical-align: middle;

  ${(props) =>
    props.hidebreakpoint
      ? `@media (max-width: ${props.hidebreakpoint}) {
    display: none;
  }`
      : ''}
`;

const NoResultCTAWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
`;
