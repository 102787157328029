import styled from '@emotion/styled';
import { Avatar, Body06, Colors } from '@robinpowered/design-system';
import StarSolid from '@robinpowered/icons/StarSolid';
import { UserWeekDay } from '../../../../../../constants';
import { GridCell, GridCellContainer } from './GridCell';

type Props = {
  avatar?: string | null;
  name?: string | null;
  email?: string | null;
  week: UserWeekDay[];
  isCurrentUser?: boolean;
  hideWeekends?: boolean;
};

export const GridRow = ({
  avatar,
  name,
  email,
  week,
  hideWeekends,
  isCurrentUser = false,
}: Props): JSX.Element => {
  return (
    <GridRowContainer hideWeekends={hideWeekends}>
      <GridCellContainer>
        <AvatarContainer>
          <Avatar size={40} src={avatar || ''} name={name || ''} />
          {!isCurrentUser && (
            <StarContainer>
              <StarSolid color={Colors.Magenta90} height={10} width={10} />
            </StarContainer>
          )}
        </AvatarContainer>

        <Body06 ml={18} color={Colors.Gray100}>
          {name ? name : email}
        </Body06>
      </GridCellContainer>
      {week.map((day, i) => (
        <GridCell
          key={day.date.format() + name}
          day={day}
          name={name}
          avatar={avatar}
          isCurrentUser={isCurrentUser}
        />
      ))}
    </GridRowContainer>
  );
};

type GridRowContainerProps = {
  hideWeekends?: boolean;
};

const GridRowContainer = styled.div<GridRowContainerProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.hideWeekends ? `repeat(10, 1fr)` : `repeat(12, 1fr)`};
  padding: 14px 20px;
  border-bottom: 1px solid ${Colors.Gray5};

  &:last-of-type {
    border-bottom: none;
  }
`;

const AvatarContainer = styled.div`
  position: relative;
`;

const StarContainer = styled.div`
  height: 18px;
  width: 18px;
  background-color: ${Colors.White0};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: -4px;
`;
