import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_LOCATIONS } from '../graphql';
import { PEOPLE_SELECTED_LOCATION } from '../constants';
import { useAuthContext } from 'contexts';
import { useTenantLocalStorage, useBestLocation } from 'hooks';
import { GetLocationsByOrgIdQuery } from 'generated';

// Need to make this nonnull && required in order to build the
// below type OrganizationByIdQueryLocations off of it
export type OrganizationByIdQueryResult = NonNullable<
  Required<GetLocationsByOrgIdQuery['getOrganizationById']>
>;

export type OrganizationByIdQueryLocations =
  OrganizationByIdQueryResult['locations'];

export type OrganizationByIdQueryLocation = OrganizationByIdQueryLocations[0];

export type OrganizationByIdQueryOfficeAccess =
  NonNullable<OrganizationByIdQueryLocation>['officeAccess'];

type LocationsContextType = {
  locations: OrganizationByIdQueryLocations;
  preferredLocation?: OrganizationByIdQueryLocation;
  loading: boolean;
  error?: ApolloError;
  selectedLocation?: OrganizationByIdQueryLocation;
  selectedLocationId?: string;
  setSelectedLocationId: Dispatch<SetStateAction<string | undefined>>;
  searchText?: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  refetch: () => void;
};

export const locationsContext = createContext<LocationsContextType>({
  locations: [],
  loading: true,
  setSelectedLocationId: () => null,
  setSearchText: () => null,
  refetch: () => null,
});

export const LocationsProvider: FC = ({ children }) => {
  const {
    currentOrg,
    currentUser,
    loading: orgLoading,
    error: orgError,
    refetch: orgRefetch,
  } = useAuthContext();

  const [searchText, setSearchText] = useState('');

  const now = moment().format('YYYY-MM-DD HH:mm');

  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
    called: locationsCalled,
    refetch: locationRefetch,
  } = useQuery<GetLocationsByOrgIdQuery>(GET_LOCATIONS, {
    skip: orgLoading || !!orgError || !currentOrg?.id || !currentUser?.id,
    // Need to cast the id to string since TS not
    // smart enough to know it will skip the query if undefined
    variables: {
      id: currentOrg?.id as string,
      userId: currentUser?.id as string,
      date: now,
    },
    context: {
      headers: {
        'Tenant-Id': currentOrg?.id,
      },
    },
  });
  const locations = useMemo(
    () => locationsData?.getOrganizationById?.locations ?? [],
    [locationsData]
  );

  const sortedLocations = useMemo(() => {
    return locations.slice().sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }, [locations]);

  const preferredLocation = useBestLocation(sortedLocations);

  const [persistedSelectedLocationId, setSelectedLocationId] =
    useTenantLocalStorage<string | undefined>(
      PEOPLE_SELECTED_LOCATION,
      undefined
    );

  useEffect(() => {
    if (preferredLocation && !persistedSelectedLocationId) {
      setSelectedLocationId(preferredLocation.id);
    }
  }, [preferredLocation, persistedSelectedLocationId, setSelectedLocationId]);

  const selectedLocation = useMemo(() => {
    return locations.find(
      (location) => location.id === persistedSelectedLocationId
    );
  }, [locations, persistedSelectedLocationId]);

  const filteredLocations = useMemo(() => {
    return sortedLocations.filter((location) =>
      location.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [sortedLocations, searchText]);

  // Consider loading to be true if the getLocations query hasn't been executed
  // yet. This prevents thrashing of the loading state on the initial load.
  const loading = orgLoading || !locationsCalled || locationsLoading;

  const refetchAll = useCallback(() => {
    if (orgRefetch) {
      orgRefetch();
    }
    if (locationRefetch) {
      locationRefetch();
    }
  }, [orgRefetch, locationRefetch]);

  return (
    <locationsContext.Provider
      value={{
        locations: filteredLocations,
        preferredLocation,
        selectedLocation,
        setSelectedLocationId,
        selectedLocationId: persistedSelectedLocationId,
        loading,
        refetch: refetchAll,
        error: orgError ?? locationsError,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </locationsContext.Provider>
  );
};

export const useLocationsContext = (): LocationsContextType => {
  return useContext(locationsContext);
};
