var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { i18n } from '../i18n';
export var GET_LANGUAGE_TAG_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetMyLanguageTag {\n    getMyLanguageTag {\n      languageTag\n    }\n  }\n"], ["\n  query GetMyLanguageTag {\n    getMyLanguageTag {\n      languageTag\n    }\n  }\n"])));
export var useUserLanguage = function () {
    var _a, _b;
    var result = useQuery(GET_LANGUAGE_TAG_QUERY, {
        nextFetchPolicy: 'cache-only',
    });
    var lang = (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.getMyLanguageTag.languageTag) !== null && _b !== void 0 ? _b : 'en';
    var loading = lang !== i18n.language;
    useEffect(function () {
        if (lang) {
            void i18n.changeLanguage(lang);
        }
    }, [lang]);
    return { loading: loading };
};
var templateObject_1;
