var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useUserPermissions } from '../hooks';
export var FEATURE_FLAG_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query FeatureFlagQuery($flagType: FeatureFlagType) {\n    isFeatureFlagEnabledForOrgOrMe(flagType: $flagType)\n  }\n"], ["\n  query FeatureFlagQuery($flagType: FeatureFlagType) {\n    isFeatureFlagEnabledForOrgOrMe(flagType: $flagType)\n  }\n"])));
var AUTH_CONTEXT_QUERY = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query AuthContextQuery {\n    getCurrentUserAndOrgs {\n      userId\n      user {\n        id\n        name\n        timeZone\n        defaultLocation\n        avatar\n        primaryEmail {\n          email\n        }\n      }\n      organizations {\n        id\n        name\n        slug\n        avatar\n        logo\n        features {\n          maps\n          workweek\n          peopleDirectory\n        }\n      }\n    }\n  }\n"], ["\n  query AuthContextQuery {\n    getCurrentUserAndOrgs {\n      userId\n      user {\n        id\n        name\n        timeZone\n        defaultLocation\n        avatar\n        primaryEmail {\n          email\n        }\n      }\n      organizations {\n        id\n        name\n        slug\n        avatar\n        logo\n        features {\n          maps\n          workweek\n          peopleDirectory\n        }\n      }\n    }\n  }\n"])));
var initialContextState = {
    activeOrg: null,
    currentUser: null,
    isLoading: true,
    userOrganizations: null,
    hasTenant: true,
    userPermissions: {
        canManageAmenities: false,
        canViewAnalytics: false,
        canViewBilling: false,
        canViewCheckpoints: false,
        canManageDevices: false,
        canViewDevices: false,
        canManageOfficeAccess: false,
        canViewOfficeAccess: false,
        canManageGroups: false,
        canManagePlaces: false,
        canManageRoles: false,
        canApproveSpaceRequests: false,
        canListUsers: false,
        canManageUsers: false,
        canViewVisitTab: false,
        canViewWorkweek: false,
        canManageWorkplaceServices: false,
        canManageWorkPolicies: false,
        arePersonalCalendarsEnabled: false,
        isInsightsFeatureEnabled: false,
        totalPendingNotifications: 0,
        isMapUploadOnboardingComplete: true,
    },
    amplitudeEventHandler: function () { },
};
export var currentOrgContext = createContext(initialContextState);
export var HeaderContextProvider = function (_a) {
    var _b, _c;
    var activeOrgSlug = _a.activeOrgSlug, children = _a.children, hasTenant = _a.hasTenant, amplitudeEventHandler = _a.amplitudeEventHandler;
    var _d = useQuery(AUTH_CONTEXT_QUERY, {
        skip: !hasTenant,
        nextFetchPolicy: 'cache-first',
    }), data = _d.data, loading = _d.loading, error = _d.error;
    var userOrganizations = useMemo(function () {
        var _a, _b;
        return (_b = (_a = data === null || data === void 0 ? void 0 : data.getCurrentUserAndOrgs) === null || _a === void 0 ? void 0 : _a.organizations.slice().sort(function (a, b) {
            if (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            return 0;
        })) !== null && _b !== void 0 ? _b : [];
    }, [data]);
    var activeOrg = useMemo(function () { var _a; return (_a = userOrganizations === null || userOrganizations === void 0 ? void 0 : userOrganizations.find(function (o) { return o.slug === activeOrgSlug; })) !== null && _a !== void 0 ? _a : null; }, [userOrganizations, activeOrgSlug]);
    var _e = useUserPermissions(activeOrg, hasTenant), permissionsError = _e.error, permissionsLoading = _e.isLoading, userPermissions = __rest(_e, ["error", "isLoading"]);
    var value = {
        hasTenant: hasTenant,
        activeOrg: activeOrg,
        currentUser: (_c = (_b = data === null || data === void 0 ? void 0 : data.getCurrentUserAndOrgs) === null || _b === void 0 ? void 0 : _b.user) !== null && _c !== void 0 ? _c : null,
        error: error || permissionsError,
        isLoading: loading || permissionsLoading,
        userOrganizations: userOrganizations,
        userPermissions: userPermissions,
        amplitudeEventHandler: amplitudeEventHandler,
    };
    return (_jsx(currentOrgContext.Provider, __assign({ value: value }, { children: children })));
};
export var useHeaderContext = function () {
    return useContext(currentOrgContext);
};
export var useAmplitude = function () {
    var trackEvent = useContext(currentOrgContext).amplitudeEventHandler;
    return { trackEvent: trackEvent };
};
var templateObject_1, templateObject_2;
