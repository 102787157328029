import common from './common.json';
import error from './error.json';
import peopleTable from './peopleTable.json';
import cta from './cta.json';
import compareSchedules from './compareSchedules.json';

export const ja = {
  common,
  error,
  peopleTable,
  cta,
  compareSchedules,
};
