import styled from '@emotion/styled';
import { Body06, Button, Heading03 } from '@robinpowered/design-system';

type Props = {
  header: string;
  body: string;
  cta: string;
  onClick: () => void;
};

export const NoResultsCTA = ({
  header,
  body,
  cta,
  onClick,
}: Props): JSX.Element => {
  return (
    <Container>
      <Heading03>{header}</Heading03>
      <Body06>{body}</Body06>
      <Button variant="primary" onClick={onClick}>
        {cta}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0;
  gap: 13px;
  text-align: center;
`;
