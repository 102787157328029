var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { HeaderMenu, NotificationMenuItem, OrganizationDropdown, UserProfileMenu, } from './components';
import { DownloadMobileAppModal } from './components/DownloadMobileAppModal';
import { HeaderContextProvider } from './contexts';
import { LocalizationContext } from './contexts/LocalizationContext';
import './i18n';
import { PresenceNotifierContainer } from './components/PresenceNotifierContainer';
export var Header = function (_a) {
    var activeOrgSlug = _a.activeOrgSlug, _b = _a.hasTenant, hasTenant = _b === void 0 ? true : _b, amplitudeEventHandler = _a.amplitudeEventHandler;
    var _c = __read(useState(/Android|(iPhone|iPod)/i.test(navigator.userAgent)), 2), showDownloadRobinAppModal = _c[0], setShowDownloadRobinAppModal = _c[1];
    return (_jsx(HeaderContextProvider, __assign({ activeOrgSlug: activeOrgSlug, hasTenant: hasTenant, amplitudeEventHandler: amplitudeEventHandler }, { children: _jsxs(_Fragment, { children: [_jsx(PresenceNotifierContainer, {}), _jsxs(LocalizationContext, { children: [showDownloadRobinAppModal && (_jsx(DownloadMobileAppModal, { setShowDownloadRobinAppModal: setShowDownloadRobinAppModal })), _jsxs(HeaderWrapper, __assign({ "aria-label": "Top Navigation" }, { children: [_jsx(OrganizationDropdown, {}), _jsx(HeaderMenu, {}), _jsx(NotificationMenuItem, {}), _jsx(UserProfileMenu, {})] }))] })] }) })));
};
var HeaderWrapper = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex: 0 1 auto;\n  height: 56px;\n  background-color: ", ";\n  padding: 0 2rem;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);\n  z-index: 10;\n  position: relative;\n\n  // Have the header 'break' instead of the entire app at\n  // smaller screen sizes until we have an actual responsive header\n  width: 100%;\n\n  @media (max-width: 500px) {\n    padding: 0 0.5rem;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  flex: 0 1 auto;\n  height: 56px;\n  background-color: ", ";\n  padding: 0 2rem;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);\n  z-index: 10;\n  position: relative;\n\n  // Have the header 'break' instead of the entire app at\n  // smaller screen sizes until we have an actual responsive header\n  width: 100%;\n\n  @media (max-width: 500px) {\n    padding: 0 0.5rem;\n  }\n"])), Colors.White0);
var templateObject_1;
