import styled from '@emotion/styled';
import {
  Box,
  Heading,
  Input,
  UtilityButton,
} from '@robinpowered/design-system';
import { Modal } from 'components';
import { useLocationsContext } from 'contexts';
import Close from '@robinpowered/icons/Close';
import { useTranslation } from 'react-i18next';
import { LocationRow } from './LocationRow';
import SearchOutline from '@robinpowered/icons/SearchOutline';
import { ChangeEvent } from 'react';

const modalStyles = {
  width: '426px',
  padding: '20px 0 0 0',
};

type Props = {
  onClose: () => void;
};

export const LocationPickerModal = ({ onClose }: Props): JSX.Element => {
  const { t } = useTranslation('compareSchedules');
  const { locations, setSearchText } = useLocationsContext();
  const handleClose = () => {
    onClose();
    setSearchText('');
  };

  return (
    <Modal onClose={handleClose} style={modalStyles}>
      <ModalInnerContainer>
        <ModalHeader>
          <Heading.Large>
            {t('location_picker.selected_location')}
          </Heading.Large>
          <UtilityButton
            icon={Close}
            onClick={handleClose}
            aria-label={t('location_picker.close_label')}
            autoFocus
          />
        </ModalHeader>
        <Input
          icon={<SearchOutline size={20} />}
          placeholder={t('location_picker.search')}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setSearchText(target.value);
          }}
          mb={4}
          pl={'8px'}
          nonce={''}
        />
        <ModalBody>
          {locations.map((location) => {
            return (
              <LocationRow
                key={location.id}
                location={location}
                onClick={handleClose}
              />
            );
          })}
        </ModalBody>
      </ModalInnerContainer>
    </Modal>
  );
};

const ModalInnerContainer = styled(Box)`
  padding: 0 20px;
  margin-bottom: 20px;
`;

const ModalHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const ModalBody = styled(Box)`
  height: 100%;
  overflow-y: auto;
  max-height: 380px;
`;
