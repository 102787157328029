export const createQueryString = (
  reservationStartTime: string,
  levelId?: string,
  reservationId?: string,
  orgSlug?: string
) => {
  return `/${orgSlug}/office?levels=${levelId}&start=${encodeURIComponent(
    reservationStartTime
  )}&reservation=${reservationId}`;
};
