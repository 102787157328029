import React from 'react';
import StarOutline from '@robinpowered/icons/StarOutline';
import StarSolid from '@robinpowered/icons/StarSolid';
import { Colors, Heading06 } from '@robinpowered/design-system';
import styled from '@emotion/styled';

type Props = {
  addCopy: string;
  removeCopy: string;
  onClick: () => void;
  isFavorite: boolean;
};

export const FavoriteCell = ({
  addCopy,
  removeCopy,
  onClick,
  isFavorite,
}: Props): JSX.Element => {
  return (
    <IconContainer>
      <IconWrap
        onClick={() => {
          onClick();
        }}
        aria-label={isFavorite ? removeCopy : addCopy}
      >
        {isFavorite ? (
          <StarSolid size={15} color={Colors.Magenta100} />
        ) : (
          <StarOutline size={15} color={Colors.Gray90} />
        )}
      </IconWrap>
      <Tooltip>
        <Heading06 color={Colors.Tan30}>
          {isFavorite ? removeCopy : addCopy}
        </Heading06>
      </Tooltip>
    </IconContainer>
  );
};

const IconContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconWrap = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  background: transparent;

  &:focus-visible {
    border-radius: 6px;
    outline: 2px solid ${Colors.Yellow100};
    outline-offset: 2px;
  }
  &:hover,
  &:focus-visible {
    background-color: ${Colors.Tan30};

    + div {
      visibility: visible;
    }
  }
`;

const Tooltip = styled.div`
  position: absolute;
  white-space: nowrap;
  background: ${Colors.Gray100};
  padding: 8px;
  border-radius: 8px;
  width: fit-content;
  bottom: 42px;
  visibility: hidden;
`;
