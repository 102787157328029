var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import moment from 'moment';
// Builds a date range to be an input for the GraphQL query `getWhosInDataByLocationByDay`
export var buildDatesArray = function (weekStartDate, dayCount, timezone, hideWeekends) {
    if (dayCount === void 0) { dayCount = 7; }
    if (timezone === void 0) { timezone = moment.tz.guess(); }
    var today = moment().tz(timezone).startOf('day');
    return __spreadArray([], __read(Array(dayCount)), false).map(function (d, i) {
        // If today is Sunday AND user start date is Monday, hiding past
        // and weekends can result in an empty array
        if (hideWeekends &&
            (today.isoWeekday() === 7 || today.isoWeekday() === 6)) {
            return weekStartDate
                .clone()
                .add(dayCount + i, 'day')
                .startOf('day');
        }
        return weekStartDate.clone().add(i, 'day').startOf('day');
    })
        .filter(function (day) {
        return (hideWeekends && !(day.isoWeekday() === 6 || day.isoWeekday() === 7)) ||
            !hideWeekends;
    });
};
