import * as React from 'react';

export const AnonymousOrg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" fill="none">
      <rect width="54" height="54" fill="#F0E4EB" rx="8" />
      <path
        stroke="#A81F6B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        // eslint-disable-next-line max-len
        d="M26 39a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM33.5 24a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM18.5 24a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM18.5 24v1a2.988 2.988 0 0 0 3 3h9a2.986 2.986 0 0 0 3-3v-1M26 28v4"
      />
    </svg>
  );
};
