import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { useTableContext } from 'contexts';
import { usePageViewEvent } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoints } from 'styles';
import { SidebarNav } from './components';
import {
  PeopleTableContainer,
  ScheduleViewContainer,
  TableHeader,
  TablePagination,
} from './components';
import { LocationPickerModal } from './components/LocationPickerModal';
import { useEscape } from 'hooks/useEscape';

export const PeoplePage = (): JSX.Element => {
  usePageViewEvent();
  const { t } = useTranslation('peopleTable');
  const { tableView } = useTableContext();
  const [isLocationPickerOpen, setIsLocationPickerOpen] = useState(false);
  useEscape(() => setIsLocationPickerOpen(false));

  return (
    <PeoplePageContainer>
      <SidebarNav />
      <PeoplePageArticle>
        <TableSection
          shrink={tableView === 'COMPARE-SCHEDULE'}
          aria-label={t('aria.table')}
        >
          <TableHeader
            handleOpenLocationPicker={() => setIsLocationPickerOpen(true)}
          />
          {tableView === 'COMPARE-SCHEDULE' ? (
            <ScheduleViewContainer />
          ) : (
            <PeopleTableContainer />
          )}
          <TablePaginationWrapper />
        </TableSection>
        {isLocationPickerOpen && (
          <LocationPickerModal onClose={() => setIsLocationPickerOpen(false)} />
        )}
      </PeoplePageArticle>
    </PeoplePageContainer>
  );
};

const PeoplePageContainer = styled.div`
  display: flex;
  background-color: ${Colors.Tan30};
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${Breakpoints.MD}) {
    flex-direction: row;
  }
  @media (min-width: ${Breakpoints.LG}) {
    max-width: 1360px;
    flex-direction: row;
  }
`;

const PeoplePageArticle = styled.article`
  flex: 1;
  margin: 24px;
  @media (max-width: ${Breakpoints.SM}) {
    margin: 10px;
  }
`;

type TableSectionProps = {
  shrink?: boolean;
};

const TableSection = styled.section<TableSectionProps>`
  background-color: ${Colors.White0};
  padding: 24px;
  max-width: ${(props) => (props.shrink ? `756px` : `1360px`)};
  margin: auto;
  border-radius: 12px;
  border: 1px solid ${Colors.Tan70};

  @media (max-width: ${Breakpoints.SM}) {
    border: none;
    padding: 20px;
  }
`;

const TablePaginationWrapper = styled(TablePagination)`
  margin-top: 16px;
`;
