import styled from '@emotion/styled';
import { Avatar, Body05, Body06, Colors } from '@robinpowered/design-system';
import BuildingSolid from '@robinpowered/icons/BuildingSolid';
import CalendarSolid from '@robinpowered/icons/CalendarSolid';
import ClockSolid from '@robinpowered/icons/ClockSolid';
import DeskAltSolid from '@robinpowered/icons/DeskAltSolid';
import { useLocalizationContext, useLocationsContext } from 'contexts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Ellipsis } from 'styles';
import {
  isAssignedReservation,
  UserWeekDay,
} from '../../../../../../../../constants';

const ICON_SIZE = 15;

type Props = {
  avatar?: string | null;
  name?: string | null;
  day: UserWeekDay;
};

export const DayInfo = ({ avatar, name, day }: Props) => {
  const { t } = useTranslation('common');
  const { timeFormat } = useLocalizationContext();
  const { selectedLocation } = useLocationsContext();
  const reservation = day.reservation?.reservation;
  const visit = day.visit;

  const timeString =
    reservation && isAssignedReservation(reservation)
      ? t('assigned')
      : `${moment(reservation?.startTime).format(timeFormat)} - ${moment(
          reservation?.endTime
        ).format(timeFormat)}`;

  const seatString = `${reservation?.seat.zone?.name}, ${reservation?.seat.name}`;

  if (!reservation && !visit) {
    return null;
  }

  return (
    <>
      {avatar && (
        <Row marginBottom="12px">
          <Avatar size={28} src={avatar || ''} name={name || ''} />
          <OverflowBody marginLeft={'8px'}>{name}</OverflowBody>
        </Row>
      )}
      <Row marginBottom="4px">
        <IconWrapper>
          <CalendarSolid size={ICON_SIZE} color={Colors.Gray60} />
        </IconWrapper>
        <OverflowBody>
          {reservation
            ? day.reservation?.date.format('ddd, MMM D')
            : visit && moment(visit.startTime).format('ddd, MMM D')}
        </OverflowBody>
      </Row>
      {reservation && (
        <Row marginBottom="4px">
          <IconWrapper>
            <ClockSolid size={ICON_SIZE} color={Colors.Gray60} />
          </IconWrapper>
          <Body06>{timeString}</Body06>
        </Row>
      )}
      {visit && (
        <Row marginBottom="4px">
          <IconWrapper>
            <BuildingSolid size={ICON_SIZE} color={Colors.Gray60} />
          </IconWrapper>
          {/* @TODO: adjust this if multiple locations are added to the page */}
          {selectedLocation && (
            <OverflowBody>{selectedLocation.name}</OverflowBody>
          )}
        </Row>
      )}
      <Row>
        <IconWrapper>
          <DeskAltSolid size={ICON_SIZE} color={Colors.Gray60} />
        </IconWrapper>
        <OverflowBody>{reservation ? seatString : t('no_desk')}</OverflowBody>
      </Row>
    </>
  );
};

const Row = styled.div<{ marginBottom?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom};
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const OverflowBody = styled(Body05)<{ marginLeft?: string }>`
  margin-left: ${(props) => props.marginLeft};
  ${Ellipsis}
`;
