// NOTE: Modifications or additions to this enum need to be reflected
//       in the insights-warehouse dim_confirming_clients table.
// @see https://github.com/robinpowered/insights-warehouse/blob/master/insights_etl/data/dim_confirming_clients.csv
export var ClientHeadersByApp;
(function (ClientHeadersByApp) {
    ClientHeadersByApp["DELIVERIES"] = "robin/dashboard/apps/deliveries";
    ClientHeadersByApp["INSIGHTS"] = "robin/dashboard/apps/insights";
    ClientHeadersByApp["PEOPLE"] = "robin/dashboard/apps/people";
    ClientHeadersByApp["SCHEDULE"] = "robin/dashboard/apps/schedule";
    ClientHeadersByApp["SETUP"] = "robin/dashboard/apps/setup";
    ClientHeadersByApp["TICKETS"] = "robin/dashboard/apps/tickets";
    ClientHeadersByApp["USER_UPLOAD"] = "robin/dashboard/apps/user-upload";
    ClientHeadersByApp["VISITS"] = "robin/dashboard/apps/visits";
    ClientHeadersByApp["OFFICE"] = "robin/dashboard/apps/office";
})(ClientHeadersByApp || (ClientHeadersByApp = {}));
/**
 * Generates the headers added to each GraphQL request sent to the federated gateway.
 * The headers include the access token, tenant ID, and the app name.
 *
 *  !!! WARNING !!!
 *
 *  Changes to this function __MUST__ be preceded by a corresponding addition
 *  to the `allowed_headers` list owned by the GraphQL Hive router. If a new
 *  header is added here without being added to the `allowed_headers` list,
 *  browsers will generate a CORS error for ALL GraphQL API requests.
 *
 *  @see https://github.com/robinpowered/robin-graphql-federation-gateway/blob/master/services/hive-router/router.yaml#L31
 *
 *  !!! WARNING !!!
 *
 * @param accessToken The access token to be used for the request.
 * @param tenantId The tenant ID to be used for the request.
 * @param app The app name to be used for the request's X-Requested-For HTTP header.
 * @returns The headers to be added to each request.
 */
export var generateGraphQLHeaders = function (accessToken, tenantId, app) {
    // !!! WARNING !!! Please read the warning above before modifying any key or value.
    var headers = {
        authorization: "Access-Token ".concat(accessToken),
        'x-requested-with': app,
    };
    if (tenantId) {
        headers['tenant-id'] = tenantId;
    }
    return headers;
};
