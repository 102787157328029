import React from 'react';
import {
  Avatar,
  Body06,
  Heading06,
  Link,
  Skeleton,
} from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { AccountUser } from 'generated';
import { Breakpoints } from 'styles';
import { useAuthContext, useTableContext } from 'contexts';

type Props = {
  user: AccountUser;
};

export const UserCell = ({ user }: Props): JSX.Element => {
  const { orgSlug } = useAuthContext();
  const { tableLoading, showNoResultsCTA } = useTableContext();

  if (tableLoading || showNoResultsCTA) {
    return (
      <SkeletonWrapper>
        <Skeleton
          width="48px"
          height="40px"
          paddingRight={20}
          marginRight={20}
          style={{ borderRadius: '50%' }}
          hasAnimation={!showNoResultsCTA}
        />
        <Skeleton width="100%" height="16px" hasAnimation={!showNoResultsCTA} />
      </SkeletonWrapper>
    );
  }

  return (
    <UserCol>
      <Avatar src={user?.avatar || ''} name={user?.name || ''} />
      <UserInfo>
        {orgSlug ? (
          user?.name ? (
            <>
              <UserLink as="a" href={`/${orgSlug}/people/${user.id}`}>
                <UserName>{user.name}</UserName>
              </UserLink>
              {user.primaryEmail?.email && (
                <UserEmail>{user?.primaryEmail?.email}</UserEmail>
              )}
            </>
          ) : (
            <UserLink as="a" href={`/${orgSlug}/people/${user.id}`}>
              {user.primaryEmail?.email && (
                <UserEmail>{user?.primaryEmail?.email}</UserEmail>
              )}
            </UserLink>
          )
        ) : (
          <UserName>{user?.name}</UserName>
        )}
      </UserInfo>
    </UserCol>
  );
};

const UserCol = styled.div`
  display: flex;
  align-items: center;
`;

const UserInfo = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
`;

const OverflowStyles = css`
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  @media (max-width: ${Breakpoints.MD}) {
    max-width: 220px;
  }

  @media (max-width: ${Breakpoints.SM}) {
    max-width: 150px;
  }
`;

const UserName = styled(Heading06)`
  ${OverflowStyles}
`;

const UserEmail = styled(Body06)`
  ${OverflowStyles}
`;

const UserLink = styled(Link)`
  width: max-content;
`;

const SkeletonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
