import styled from '@emotion/styled';
import { ButtonV4, Colors, SpinnerLoader } from '@robinpowered/design-system';
import BuildingSolid from '@robinpowered/icons/BuildingSolid';
import { useLocationsContext } from 'contexts';
import { Ellipsis } from 'styles';

type Props = {
  onClick: () => void;
};

export const LocationPickerButton = ({ onClick }: Props): JSX.Element => {
  const { selectedLocation, loading } = useLocationsContext();
  return (
    <LocationButton
      variant="secondary"
      size="small"
      label={selectedLocation ? selectedLocation.name : ''}
      iconLeft={() =>
        loading ? (
          <SpinnerLoader />
        ) : (
          <BuildingIcon color={Colors.Gray60} height={16} width={16} />
        )
      }
      onClick={onClick}
    />
  );
};

const BuildingIcon = styled(BuildingSolid)`
  padding-right: 2px;
`;

const LocationButton = styled(ButtonV4)`
  > span {
    max-width: 300px;
    ${Ellipsis}
  }
`;
