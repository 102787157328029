import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

type UsePopover = {
  hasPopover: boolean;
  setHasPopover: (hasPopover: boolean) => void;
  popoverRef: React.MutableRefObject<null>;
};

export const usePopover = (): UsePopover => {
  const [hasPopover, setHasPopover] = useState(false);
  const popoverRef = useRef(null);

  useClickAway(popoverRef, (): void => {
    setHasPopover(false);
  });

  return { hasPopover, setHasPopover, popoverRef };
};
