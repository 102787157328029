import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';

export const Popover = styled.div<{ bottomAdjustment?: boolean }>`
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${Colors.Gray10};
  min-width: 260px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  background-color: ${Colors.White0};
  position: absolute;
  display: block;
  bottom: 45px;
  left: -110px;
`;
