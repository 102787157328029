import React, { useMemo } from 'react';
import { PeopleTable } from './PeopleTable';
import { useTranslation } from 'react-i18next';
import {
  DetailColumn,
  UserCell,
  TableError,
  DeskCellContainer,
  TextCellContainer,
  FavoriteCellContainer,
} from './components';
import { useTableContext } from 'contexts';
import { AccountUser } from 'generated';
import { TableEmpty } from './components/TableEmpty';
import { ColumnResponsiveSettings } from 'constants/breakpoints';

export const PeopleTableContainer = (): JSX.Element => {
  const { t } = useTranslation('peopleTable');
  const {
    usePagedUsersRequest,
    setSearchValue,
    tableLoading,
    tableError,
    showNoResultsCTA,
  } = useTableContext();
  const { DEPARTMENT, LOCATION, DESK } = ColumnResponsiveSettings;

  const columns = [
    { Header: t('columns.name'), accessor: 'user', disableSortBy: true },
    {
      Header: (
        <DetailColumn
          copy={t('columns.department')}
          tooltip={t('info.department.tooltip')}
          tooltipLink={t('info.department.link.link')}
          tooltipLinkCopy={t('info.department.link.copy')}
        />
      ),
      accessor: 'department',
      disableSortBy: true,
      hidebreakpoint: DEPARTMENT,
    },
    {
      Header: (
        <DetailColumn
          copy={t('columns.preferred')}
          tooltip={t('info.preferred.tooltip')}
          tooltipLink={`/account`}
          tooltipLinkCopy={t('info.preferred.link.copy')}
        />
      ),
      accessor: 'preferredoffice',
      disableSortBy: true,
      hidebreakpoint: LOCATION,
    },
    {
      Header: t('columns.desk'),
      accessor: 'desk',
      disableSortBy: true,
      hidebreakpoint: DESK,
    },
    {
      Header: t('columns.favorite'),
      accessor: 'favorite',
      disableSortBy: true,
    },
  ];

  const tableData = useMemo(() => {
    // If users have not loaded OR they are viewing the favorites table
    // with no favorites, default to a 10 row array
    // for the skeleton loading states and CTAs
    const userList =
      tableLoading || !usePagedUsersRequest?.data || showNoResultsCTA
        ? ([...Array(showNoResultsCTA ? 5 : 10)] as AccountUser[])
        : (usePagedUsersRequest?.data?.getPagedUsers
            .accountUsers as AccountUser[]);

    return userList.map((user) => {
      return {
        user: {
          component: <UserCell user={user} />,
        },
        department: {
          component: <TextCellContainer copy={user?.department} />,
          hidebreakpoint: DEPARTMENT,
        },
        preferredoffice: {
          component: (
            <TextCellContainer
              copy={
                user?.defaultLocation
                  ? user.defaultLocation.name
                  : t('empty.location')
              }
              noResult={!user?.defaultLocation}
            />
          ),
          hidebreakpoint: LOCATION,
        },
        desk: {
          component: <DeskCellContainer userId={user?.id} />,
          hidebreakpoint: DESK,
        },
        favorite: {
          component: <FavoriteCellContainer userId={user?.id} />,
        },
      };
    });
  }, [
    tableLoading,
    usePagedUsersRequest?.data,
    showNoResultsCTA,
    t,
    DEPARTMENT,
    LOCATION,
    DESK,
  ]);

  return (
    <>
      {tableError ? (
        <TableError />
      ) : usePagedUsersRequest?.data?.getPagedUsers.accountUsers.length === 0 &&
        !showNoResultsCTA ? (
        // eslint-disable-next-line react/jsx-indent
        <TableEmpty onClick={() => setSearchValue('')} />
      ) : (
        <PeopleTable
          data={tableData}
          columns={columns}
          showNoResultsCTA={showNoResultsCTA}
        />
      )}
    </>
  );
};
