const environments = ['production', 'staging', 'development', 'test'] as const;
type Environment = (typeof environments)[number];

declare global {
  interface Window {
    NODE_ENV: 'production';
    REACT_APP_ROBIN_ENV: Environment;
    REACT_APP_DASHBOARD_URL: string;
    REACT_APP_GQL_URL: string;
    REACT_APP_DASHBOARD_API_URL: string;
    REACT_APP_SENTRY_DSN: string;
    REACT_APP_AMPLITUDE_API_KEY: string;
    REACT_APP_COOKIE_NAME: string;
    REACT_APP_AUTH_COOKIE_NAME: string;
    REACT_APP_NAME: string;
    REACT_APP_VERSION: string;
    [key: string]: string;
  }
}

type Config = {
  env: string;
  robinEnv: string;
  dashboardUrl: string;
  gqlUrl: string;
  dashboardApiUrl: string;
  sentryDsn: string;
  amplitudeApiKey: string;
  sessionCookieName: string;
  authSessionCookieName: string;
  appName: string;
  appVersion: string;
};

export const config: Config = {
  env: window.NODE_ENV,
  robinEnv: window.REACT_APP_ROBIN_ENV,
  dashboardUrl: window.REACT_APP_DASHBOARD_URL,
  gqlUrl: window.REACT_APP_GQL_URL,
  dashboardApiUrl: window.REACT_APP_DASHBOARD_API_URL,
  sentryDsn: window.REACT_APP_SENTRY_DSN,
  amplitudeApiKey: window.REACT_APP_AMPLITUDE_API_KEY,
  sessionCookieName: window.REACT_APP_COOKIE_NAME,
  authSessionCookieName: window.REACT_APP_AUTH_COOKIE_NAME,
  appName: window.REACT_APP_NAME,
  appVersion: window.REACT_APP_VERSION,
};

[
  'NODE_ENV',
  'REACT_APP_DASHBOARD_URL',
  'REACT_APP_ROBIN_ENV',
  'REACT_APP_GQL_URL',
  'REACT_APP_DASHBOARD_API_URL',
  'REACT_APP_SENTRY_DSN',
  'REACT_APP_AMPLITUDE_API_KEY',
  'REACT_APP_COOKIE_NAME',
  'REACT_APP_AUTH_COOKIE_NAME',
  'REACT_APP_NAME',
  'REACT_APP_VERSION',
].forEach((key) => {
  if (window[key] === undefined) {
    throw new Error(`Missing ${key} on 'window'.`);
  }
});

/* eslint-disable no-console */
if (config.env === 'development') {
  console.groupCollapsed('Started app with config');
  console.table(config);
  console.groupEnd();
}
/* eslint-enable no-console */
