var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useQuery } from '@apollo/client';
var GET_USER_SETTING = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getUserSetting($userId: ID!, $settingSlug: String!) {\n    getUserSetting(userId: $userId, settingSlug: $settingSlug) {\n      value\n    }\n  }\n"], ["\n  query getUserSetting($userId: ID!, $settingSlug: String!) {\n    getUserSetting(userId: $userId, settingSlug: $settingSlug) {\n      value\n    }\n  }\n"])));
export var useGetUserSetting = function (userId, tenantId) {
    return useQuery(GET_USER_SETTING, {
        skip: !userId || !tenantId,
        variables: {
            userId: userId,
            settingSlug: 'week-starting-day',
        },
        fetchPolicy: 'cache-and-network',
    });
};
var templateObject_1;
