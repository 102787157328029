import styled from '@emotion/styled';
import { ButtonV4, Input } from '@robinpowered/design-system';
import SearchOutline from '@robinpowered/icons/SearchOutline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  onSearch: (e: string) => void;
};

export const SearchBar = ({ value, onSearch }: Props): JSX.Element => {
  const { t } = useTranslation('peopleTable');
  const [searchValue, setSearchValue] = useState(value);

  return (
    <SearchBarContainer>
      <Input
        onKeyPress={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            onSearch(searchValue);
          }
        }}
        onChange={(e: KeyboardEvent) => {
          const target = e.target as HTMLInputElement;
          setSearchValue(target.value);
          if (target.value === '') {
            onSearch(target.value);
          }
        }}
        value={searchValue}
        placeholder={t('search.placeholder')}
        icon={<SearchOutline size={16} />}
        marginBottom={'24px'}
        nonce={''}
      />
      <SearchButton
        label={t('search.button_text')}
        onClick={() => onSearch(searchValue)}
        variant="primary"
      />
    </SearchBarContainer>
  );
};

const SearchBarContainer = styled.div`
  display: flex;
`;

const SearchButton = styled(ButtonV4)`
  margin-left: 4px;
`;
