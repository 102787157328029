var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { MenuListItem } from './MenuListItem';
export var StyledMenu = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 100;\n  top: ", ";\n  right: ", ";\n  min-width: 175px;\n  border-radius: 8px;\n  background-color: ", ";\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 12px rgba(0, 0, 0, 0.06);\n  border-radius: 8px;\n  margin: 0;\n  padding: 8px 0;\n"], ["\n  position: absolute;\n  z-index: 100;\n  top: ", ";\n  right: ", ";\n  min-width: 175px;\n  border-radius: 8px;\n  background-color: ", ";\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 12px rgba(0, 0, 0, 0.06);\n  border-radius: 8px;\n  margin: 0;\n  padding: 8px 0;\n"])), function (props) { return (props.isSubMenu ? '-8px' : '42px'); }, function (props) { return (props.isSubMenu ? 'calc(100% - 4px)' : '0px'); }, Colors.White0);
export var MenuSelect = function (_a) {
    var isOpen = _a.isOpen, isSubMenu = _a.isSubMenu, style = _a.style, items = _a.items, onItemClick = _a.onItemClick;
    var _b = __read(useState(null), 2), selectedSubMenuItem = _b[0], setSelectedSubMenuItem = _b[1];
    useEffect(function () {
        if (!isOpen) {
            setSelectedSubMenuItem(null);
        }
    }, [isOpen]);
    if (!isOpen) {
        return null;
    }
    return (_jsx(StyledMenu, __assign({ style: style, isSubMenu: isSubMenu }, { children: items.map(function (item) {
            return (_jsx(MenuListItem, __assign({ item: item, closeSubMenu: function () {
                    setSelectedSubMenuItem(null);
                }, toggleSubMenu: function () {
                    if (selectedSubMenuItem === item) {
                        setSelectedSubMenuItem(null);
                    }
                    else {
                        setSelectedSubMenuItem(item);
                    }
                }, subMenuIsOpen: item === selectedSubMenuItem, renderSubmenu: function (_a) {
                    var isOpenProp = _a.isOpen, subItems = _a.items;
                    return (_jsx(MenuSelect, { isOpen: isOpenProp, isSubMenu: true, items: subItems, onItemClick: function (submenuItem) {
                            setSelectedSubMenuItem(submenuItem);
                            onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick(submenuItem);
                        } }));
                }, onClick: onItemClick }, { children: item.label }), item.label));
        }) })));
};
var templateObject_1;
