import { useMemo } from 'react';
import { useQueryParams } from 'hooks';
import {
  OrganizationByIdQueryLocation,
  OrganizationByIdQueryLocations,
  useAuthContext,
} from 'contexts';

function findLocation(
  passedId: string,
  locationList: OrganizationByIdQueryLocations
) {
  return locationList.find((l) => l.id === passedId);
}

/**
 * Returns
 * a. the location from the url
 * b. the default location returned on user settings
 * c. the first location in the list of user locations
 * d. null
 */
export function useBestLocation(
  locations: OrganizationByIdQueryLocations
): OrganizationByIdQueryLocation {
  const query = useQueryParams();
  const { currentUser } = useAuthContext();
  const defaultLocationId = useMemo(
    () => currentUser?.defaultLocation,
    [currentUser]
  );
  const bestLocation = useMemo(() => {
    const locationIdFromQuery = query.get('location');
    if (locationIdFromQuery) {
      const locationFromQuery = findLocation(locationIdFromQuery, locations);
      if (locationFromQuery) {
        return locationFromQuery;
      }
    }

    if (defaultLocationId) {
      const defaultLocation = findLocation(defaultLocationId, locations);
      if (defaultLocation) {
        return defaultLocation;
      }
    }

    return locations?.[0];
  }, [locations, query, defaultLocationId]);

  return bestLocation;
}
