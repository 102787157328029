import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ArrivalDisplayRegistrationKey: any;
  BigInt: any;
  Date: any;
  DocumentBody: any;
  IANATimezone: any;
  IPAddress: any;
  IPAddressCIDR: any;
  ISO8601Duration: any;
  ISODateTime: any;
  Json: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  TODO: any;
  UtcTime: any;
  Void: any;
  WorkflowEmail: any;
};

export type AccessPassResolvedPlaces = Campus | Level | Location | Organization | Space;

export type AccessPlace = {
  __typename?: 'AccessPlace';
  id: Scalars['ID'];
  type: AccessPlaceType;
};

export enum AccessPlaceType {
  Campus = 'CAMPUS',
  Level = 'LEVEL',
  Location = 'LOCATION',
  Organization = 'ORGANIZATION',
  Space = 'SPACE'
}

export type AccountReservee = {
  __typename?: 'AccountReservee';
  email: Scalars['String'];
  participationStatus?: Maybe<ParticipationStatus>;
  user?: Maybe<AccountUser>;
  visitorId?: Maybe<Scalars['ID']>;
};

export type AccountUser = {
  __typename?: 'AccountUser';
  avatar?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  defaultLocation?: Maybe<Location>;
  department?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isMyFavorite?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Email>;
  slug?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  workingHours?: Maybe<Array<Maybe<UserWorkingHour>>>;
};

export type ActivitiesByLocation = {
  __typename?: 'ActivitiesByLocation';
  activities: Array<ActivityForCurrentUser>;
  locationId?: Maybe<Scalars['String']>;
};

export type ActivitiesForDateByLocationIdsInput = {
  date: Scalars['LocalDate'];
  joinedLimit?: InputMaybe<Scalars['Int']>;
  locationIds: Array<Scalars['String']>;
  timezone: Scalars['String'];
};

export type ActivitiesForTimeRangeByLocationIdsInput = {
  end: Scalars['ISODateTime'];
  joinedLimit?: InputMaybe<Scalars['Int']>;
  locationIds: Array<Scalars['String']>;
  start: Scalars['ISODateTime'];
  timezone: Scalars['String'];
};

export type ActivitiesForTimeRangeByLocationIdsPagingInput = {
  activityLimitPerDay?: InputMaybe<Scalars['Int']>;
};

export type ActivityCoverImagesObject = {
  __typename?: 'ActivityCoverImagesObject';
  birthday: Scalars['String'];
  celebration: Scalars['String'];
  drinks: Scalars['String'];
  funAndGames: Scalars['String'];
  funAndMusic: Scalars['String'];
  gathering: Scalars['String'];
  holiday: Scalars['String'];
  lunch: Scalars['String'];
};

export type ActivityForCurrentUser = {
  __typename?: 'ActivityForCurrentUser';
  activityLocation?: Maybe<Scalars['String']>;
  audienceLocationId: Scalars['ID'];
  canceledAt?: Maybe<Scalars['String']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  creator: User;
  description?: Maybe<Scalars['String']>;
  end: Scalars['ISODateTime'];
  id: Scalars['ID'];
  joined: ActivityJoinersList;
  start: Scalars['ISODateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  userRelationship: ActivityUserRelationship;
  virtualLocation?: Maybe<Scalars['String']>;
};

export type ActivityJoinersList = {
  __typename?: 'ActivityJoinersList';
  joiners: Array<User>;
  totalCount: Scalars['Int'];
};

export type ActivityUserRelationship = {
  __typename?: 'ActivityUserRelationship';
  canManage: Scalars['Boolean'];
  isAttending: Scalars['Boolean'];
};

export type AddLevelToLocationResponse = {
  __typename?: 'AddLevelToLocationResponse';
  id: Scalars['Int'];
};

export type AddLevelsToLocationResponse = {
  __typename?: 'AddLevelsToLocationResponse';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AddLocationToOrganizationResponse = {
  __typename?: 'AddLocationToOrganizationResponse';
  id: Scalars['Int'];
};

export type AddOrganizationResponse = {
  __typename?: 'AddOrganizationResponse';
  id: Scalars['Int'];
};

export type AddSpaceToEventRequest = {
  endTime: Scalars['Date'];
  eventId: Scalars['String'];
  spaceId: Scalars['String'];
  startTime: Scalars['Date'];
};

export type AllocateStickersToOrgByDisplayCodeRequest = {
  displayCodes: Array<Scalars['Int']>;
  orgId: Scalars['ID'];
};

export type AllocateStickersToOrgRequest = {
  orgId: Scalars['ID'];
  stickerIds: Array<Scalars['ID']>;
};

export type Amenity = {
  __typename?: 'Amenity';
  /** If the account ID is null, this means it's a global amenity and available to all orgs */
  accountId?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerTypes: Array<OwnerType>;
  updatedAt: Scalars['Date'];
};

export type AnalyticsAuthorizationResponse = {
  __typename?: 'AnalyticsAuthorizationResponse';
  token: Scalars['String'];
};

/**
 * An Announcement is an office message created for given location(s) in an organization.
 * It can be acknowledged by each user in a given org/location, and updated/archived by an admin(?).
 */
export type Announcement = {
  __typename?: 'Announcement';
  /** Datetime of when annoucnement was instantly archived */
  archivedAt?: Maybe<Scalars['Date']>;
  /** User that created the announcement */
  author: User;
  /** Current content of the announcement */
  content: Scalars['String'];
  /** Datetime of creation */
  createdAt: Scalars['Date'];
  /** Datetime of end of announcement */
  endAt?: Maybe<Scalars['Date']>;
  /** Unique identifier */
  id: Scalars['ID'];
  /** List of locations for which the announcement has been published */
  locations: Array<Maybe<Location>>;
  /** Display name */
  name: Scalars['String'];
  /** Standard or priority announcement */
  priorityStatus: AnnouncementPriorityStatus;
  /** Datetime of start of announcement */
  startAt: Scalars['Date'];
  /** Current status of the announcement */
  status: AnnouncementStatus;
};

/** Acknowledgement of an announcement for a user at a location. */
export type AnnouncementAcknowledgement = {
  __typename?: 'AnnouncementAcknowledgement';
  announcementId: Scalars['ID'];
  date: Scalars['ISODateTime'];
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AnnouncementCreateInput = {
  content: Scalars['String'];
  distributions?: InputMaybe<Array<DistributionInput>>;
  endAt?: InputMaybe<Scalars['ISODateTime']>;
  locations: Array<Scalars['ID']>;
  name: Scalars['String'];
  priorityStatus?: InputMaybe<AnnouncementPriorityStatus>;
  shouldSendPushNotification?: InputMaybe<Scalars['Boolean']>;
  startAt?: InputMaybe<Scalars['ISODateTime']>;
};

/** Each announcement distribution will represent a service the announcement is cross posted to along with its recipients. */
export type AnnouncementDistribution = {
  __typename?: 'AnnouncementDistribution';
  distributionService: DistributionService;
  id: Scalars['ID'];
  recipients: Array<DistributionRecipient>;
  sentAt?: Maybe<Scalars['Date']>;
};

/** Each announcement distribution for location will represent a service the announcement is cross posted to along with its recipients and the announcement with its location. */
export type AnnouncementDistributionForLocation = {
  __typename?: 'AnnouncementDistributionForLocation';
  announcement?: Maybe<AnnouncementForLocation>;
  distributionService: DistributionService;
  id: Scalars['ID'];
  recipients: Array<DistributionRecipient>;
  sentAt?: Maybe<Scalars['Date']>;
};

/** An Announcement within a context of a specific location. */
export type AnnouncementForLocation = {
  __typename?: 'AnnouncementForLocation';
  /** Datetime of when annoucnement was instantly archived */
  archivedAt?: Maybe<Scalars['Date']>;
  /** User that created the announcement */
  author: User;
  /** Current content of the announcement */
  content: Scalars['String'];
  /** Datetime of creation */
  createdAt: Scalars['Date'];
  /** Datetime of end of announcement */
  endAt?: Maybe<Scalars['Date']>;
  /** Unique identifier */
  id: Scalars['ID'];
  /** List of locations for which the announcement has been published */
  locations: Array<Maybe<Location>>;
  /** Display name */
  name: Scalars['String'];
  /** Standard or priority announcement */
  priorityStatus: AnnouncementPriorityStatus;
  /** Datetime of start of announcement */
  startAt: Scalars['Date'];
  /** Current status of the announcement */
  status: AnnouncementStatus;
  /** When the requesting user has first viewed the announcement at this location */
  viewedAt?: Maybe<Scalars['String']>;
};

export enum AnnouncementPriorityStatus {
  Priority = 'PRIORITY',
  Standard = 'STANDARD'
}

export enum AnnouncementStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Scheduled = 'SCHEDULED'
}

export type AnnouncementUpdateInput = {
  content: Scalars['String'];
  endAt?: InputMaybe<Scalars['ISODateTime']>;
  locations: Array<Scalars['ID']>;
  name: Scalars['String'];
  startAt?: InputMaybe<Scalars['ISODateTime']>;
};

/** View of an announcement for a user at a location. */
export type AnnouncementView = {
  __typename?: 'AnnouncementView';
  announcementId: Scalars['ID'];
  date: Scalars['ISODateTime'];
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AnnouncementViewAcknowledgeCount = {
  __typename?: 'AnnouncementViewAcknowledgeCount';
  acknowledgeCount: Scalars['Int'];
  viewCount: Scalars['Int'];
};

export type AnnouncementViewAcknowledgeCountWithId = {
  __typename?: 'AnnouncementViewAcknowledgeCountWithId';
  acknowledgeCount: Scalars['Int'];
  id: Scalars['ID'];
  viewCount: Scalars['Int'];
};

export type ApiOrganizationExternalAccount = {
  __typename?: 'ApiOrganizationExternalAccount';
  accessToken?: Maybe<Scalars['String']>;
  accountId: Scalars['ID'];
  domain?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ArrivalDisplay = {
  __typename?: 'ArrivalDisplay';
  /** The date this arrival display was created */
  createdAt: Scalars['ISODateTime'];
  id: Scalars['ID'];
  isGuestSelfRegistrationEnabled: Scalars['Boolean'];
  /** location where display resides */
  location: Location;
  /** Name of this arrival display */
  name: Scalars['String'];
  /** True if the registration key has been consumed */
  registrationConsumed: Scalars['Boolean'];
  /** ISO datetime representing when the registration key was consumed */
  registrationConsumedAt?: Maybe<Scalars['ISODateTime']>;
  /** ISO datetime encoded expiration of the active device registration key */
  registrationExpiresAt?: Maybe<Scalars['ISODateTime']>;
  /** One-time registration key used to connect arrival display for first authentication, nullable */
  registrationKey?: Maybe<Scalars['ArrivalDisplayRegistrationKey']>;
};

export type ArrivalDisplayRegistrationResponse = {
  __typename?: 'ArrivalDisplayRegistrationResponse';
  authorization: DeviceAuthorizationResponse;
  certKey: Scalars['String'];
  device: ArrivalDisplay;
  id: Scalars['ID'];
};

export type ArrivalDisplayRescueResponse = {
  __typename?: 'ArrivalDisplayRescueResponse';
  authorization?: Maybe<DeviceAuthorizationResponse>;
  id: Scalars['ID'];
  isPaired: Scalars['Boolean'];
};

export type AssignStickerRequest = {
  assignments: Array<StickerAssignmentInput>;
};

export type AssignStickerResponse = {
  __typename?: 'AssignStickerResponse';
  assignments: Array<StickerAssignment>;
};

export type AssignStickerToDeskByShortCodeRequest = {
  deskId: Scalars['Int'];
  stickerShortCode: Scalars['String'];
};

export type AssignStickerToDeskByShortCodeResponse = {
  __typename?: 'AssignStickerToDeskByShortCodeResponse';
  deskId: Scalars['Int'];
  stickerShortCode: Scalars['String'];
};

export type AuthorizationResponse = {
  __typename?: 'AuthorizationResponse';
  bearerToken: Scalars['String'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  refreshToken: Scalars['String'];
  userId: Scalars['ID'];
};

export type AutoAllocateStickersToOrgRequest = {
  orgId: Scalars['ID'];
  quantity: Scalars['Int'];
  startingDisplayCode: Scalars['Int'];
};

export enum Availability {
  /** Currently available */
  Available = 'AVAILABLE',
  /** Resource has been booked */
  Booked = 'BOOKED',
  /** Resource marked as unavailable for booking */
  Unavailable = 'UNAVAILABLE',
  /** Resource marked as unknown due to desk inexistence */
  Unknown = 'UNKNOWN'
}

export type BookedSchedulingAggregateOfDesks = {
  __typename?: 'BookedSchedulingAggregateOfDesks';
  /** Total number of desks of type Assigned that are booked */
  deskAssignedBookedTotal: Scalars['Int'];
  /** Total number of booked desks */
  deskBookedTotal: Scalars['Int'];
  /** Total number of desks of type Hot that are booked */
  deskHotBookedTotal: Scalars['Int'];
  /** Total number of desks of type Hotel that are booked */
  deskHotelBookedTotal: Scalars['Int'];
  /** Represents the end time window for assessed desk booking status */
  endTime: Scalars['Date'];
  /** Represents the start time window for assessed desk booking status */
  startTime: Scalars['Date'];
};

export enum BookingPolicyType {
  /** Indicate the desk may be indefinitely assigned to a particular user. */
  Assigned = 'ASSIGNED',
  /** Indicate that the desk is available to be booked for short term use. */
  Hot = 'HOT',
  /** Indicate that the desk may be reserved for a defined period duration. */
  Hoteled = 'HOTELED',
  /** Indicate that the desk may be reverse hoteled on an excluded assigned reservation. */
  ReverseHotel = 'REVERSE_HOTEL'
}

export type Calendar = {
  __typename?: 'Calendar';
  calendarId: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  remoteType: Scalars['String'];
  spaceId: Scalars['ID'];
  spaceResourceEmail?: Maybe<Scalars['String']>;
  spaceResourceId?: Maybe<Scalars['String']>;
  subscriberExpiresAt?: Maybe<Scalars['Date']>;
  subscriberId?: Maybe<Scalars['String']>;
  syncToken?: Maybe<Scalars['String']>;
};

export type Campus = {
  __typename?: 'Campus';
  /** Total desks on the floor - excluding inactive. */
  activeDeskCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  /** Total desks within the campus that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  id: Scalars['ID'];
  locations: Array<Location>;
  name: Scalars['String'];
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  id: Scalars['ID'];
  message: Scalars['String'];
  sentAt: Scalars['ISODateTime'];
  sentByUser: UserWithDefault;
};

export type CheckOrganizationNameAvailabilityResponse = {
  __typename?: 'CheckOrganizationNameAvailabilityResponse';
  available: Scalars['Boolean'];
};

export enum ConfirmationMethod {
  Local = 'LOCAL',
  Remote = 'REMOTE'
}

export type ConnectRipplingAccountForOrganizationAuthDataInput = {
  access_token: Scalars['String'];
  account_id: Scalars['String'];
  expires_in: Scalars['Int'];
  id_token: Scalars['String'];
  refresh_token: Scalars['String'];
  scope: Scalars['String'];
  token_type: Scalars['String'];
  uid: Scalars['String'];
};

export type ConnectRipplingAccountForOrganizationInput = {
  authData: ConnectRipplingAccountForOrganizationAuthDataInput;
  organizationId: Scalars['ID'];
};

export type ConnectRipplingAccountForOrganizationResponse = {
  __typename?: 'ConnectRipplingAccountForOrganizationResponse';
  id: Scalars['ID'];
};

export type CreateActivityInput = {
  activityLocation?: InputMaybe<Scalars['String']>;
  audienceLocationId: Scalars['ID'];
  /** Should be a URL */
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end: Scalars['ISODateTime'];
  externalEventId?: InputMaybe<Scalars['String']>;
  start: Scalars['ISODateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  /** Should be a URL */
  virtualLocation?: InputMaybe<Scalars['String']>;
};

export type CreateActivityReturnType = {
  __typename?: 'CreateActivityReturnType';
  id: Scalars['String'];
};

export type CreateAndMapBuildingsFromRipplingBuildingsInput = {
  address: Scalars['String'];
  name: Scalars['String'];
  ripplingBuildingId?: InputMaybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type CreateAndMapBuildingsFromRipplingInput = {
  buildings: Array<CreateAndMapBuildingsFromRipplingBuildingsInput>;
  organizationId: Scalars['ID'];
};

export type CreateAndMapBuildingsFromRipplingResponse = {
  __typename?: 'CreateAndMapBuildingsFromRipplingResponse';
  id: Scalars['ID'];
};

export type CreateGenericHealthCheckpointResponse = {
  __typename?: 'CreateGenericHealthCheckpointResponse';
  id: Scalars['String'];
};

export type CreateRobinOrganizationFromRipplingOAuthDataInput = {
  code: Scalars['String'];
};

export type CreateRobinOrganizationFromRipplingOAuthDataResponse = {
  __typename?: 'CreateRobinOrganizationFromRipplingOAuthDataResponse';
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateWorkAreaInput = {
  deskIds: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type CurrentUserAndOrgs = {
  __typename?: 'CurrentUserAndOrgs';
  /** If true, user can see approval notifications for the organization specified in the tenant-id header. */
  canSeeApprovalNotificationsForCurrentTenant: Scalars['Boolean'];
  hasActivitiesCreateForCurrentTenant: Scalars['Boolean'];
  hasActivitiesManageForCurrentTenant: Scalars['Boolean'];
  hasAmenitiesManageForCurrentTenant: Scalars['Boolean'];
  hasAnalyticsPermissionForCurrentTenant: Scalars['Boolean'];
  hasAnnouncementsManageForCurrentTenant: Scalars['Boolean'];
  hasBillingPermissionForCurrentTenant: Scalars['Boolean'];
  hasCheckpointsViewPermissionForCurrentTenant: Scalars['Boolean'];
  hasDevicesManageForCurrentTenant: Scalars['Boolean'];
  hasDevicesViewForCurrentTenant: Scalars['Boolean'];
  hasGroupsManageForCurrentTenant: Scalars['Boolean'];
  hasListUserPermissionForCurrentTenant: Scalars['Boolean'];
  hasManageUserPermissionForCurrentTenant: Scalars['Boolean'];
  hasOfficeAccessManagePermissionForCurrentTenant: Scalars['Boolean'];
  hasOfficeAccessViewPermissionForCurrentTenant: Scalars['Boolean'];
  hasPlacesManageForCurrentTenant: Scalars['Boolean'];
  hasRolesManagePermissionForCurrentTenant: Scalars['Boolean'];
  hasSeatsCoworkingReserveForCurrentTenant: Scalars['Boolean'];
  hasVisitsManageForCurrentTenant: Scalars['Boolean'];
  hasWorkPoliciesManagePermissionForCurrentTenant: Scalars['Boolean'];
  hasWorkplaceServicesManagePermissionForCurrentTenant: Scalars['Boolean'];
  hasWorkweekViewForCurrentTenant: Scalars['Boolean'];
  organizations: Array<Organization>;
  totalPendingApprovalNotificationsForOrganization: Scalars['Int'];
  user: User;
  userId: Scalars['ID'];
};

export type CustomWorkingHours = DateSpecificWorkingHours & {
  __typename?: 'CustomWorkingHours';
  date: Scalars['Date'];
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  timeFrames: Array<TimeFrame>;
};

export type CustomWorkingHoursBulkUpdateInput = {
  date: Scalars['LocalDate'];
  end?: InputMaybe<Scalars['LocalEndTime']>;
  id?: InputMaybe<Scalars['ID']>;
  reason?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['LocalEndTime']>;
};

/**
 * `date` is formated as YYYY-MM-DD while `start` and `end` are formated
 * as HH:MM from 00:00 -> 24:00. `start` is inclusive and `end` is
 * exclusive in the time range
 * If `start` and `end` is `null`, that denotes an all day closure
 */
export type CustomWorkingHoursInput = {
  date: Scalars['LocalDate'];
  end?: InputMaybe<Scalars['LocalEndTime']>;
  locationId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['LocalEndTime']>;
};

/**
 * `date` is formated as YYYY-MM-DD while `start` and `end` are formated
 * as HH:MM from 00:00 -> 24:00. `start` is inclusive and `end` is
 * exclusive in the time range
 * If `start` and `end` is `null`, that denotes an all day closure
 */
export type CustomWorkingHoursUpdateInput = {
  date: Scalars['LocalDate'];
  end?: InputMaybe<Scalars['LocalEndTime']>;
  reason?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['LocalEndTime']>;
};

export type Customizations = {
  __typename?: 'Customizations';
  failBody?: Maybe<Scalars['String']>;
  failHeadline?: Maybe<Scalars['String']>;
  failureEmails: Array<Scalars['String']>;
  passBody?: Maybe<Scalars['String']>;
  passHeadline?: Maybe<Scalars['String']>;
};

export enum Device_Type {
  Unknown = 'UNKNOWN',
  VisitorArrival = 'VISITOR_ARRIVAL'
}

export type DailyActivitiesSummary = {
  __typename?: 'DailyActivitiesSummary';
  activities: Array<ActivityForCurrentUser>;
  date: Scalars['Date'];
  totalActivities: Scalars['Int'];
};

export type DateFilterInput = {
  endTimeInclusive?: InputMaybe<Scalars['ISODateTime']>;
  startTimeExclusive?: InputMaybe<Scalars['ISODateTime']>;
};

export type DateFilterMandatoryInput = {
  endInclusivity?: InputMaybe<TimeInclusivityType>;
  endTime?: InputMaybe<Scalars['ISODateTime']>;
  startInclusivity?: InputMaybe<TimeInclusivityType>;
  startTime?: InputMaybe<Scalars['ISODateTime']>;
};

export type DateGroupedUserReservations = {
  __typename?: 'DateGroupedUserReservations';
  reservationsByDate: Array<UserDeskReservationByDate>;
  users: Array<Reservee>;
};

export type DateRangeInput = {
  endTime: Scalars['ISODateTime'];
  startTime: Scalars['ISODateTime'];
  timeZone?: InputMaybe<Scalars['String']>;
};

export type DateSpecificWorkingHours = {
  date: Scalars['Date'];
  timeFrames: Array<TimeFrame>;
};

export type DateWithTimezone = {
  dateTime: Scalars['String'];
  timeZone: Scalars['String'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type Desk = {
  __typename?: 'Desk';
  amenities: Array<DeskAmenity>;
  availableAt?: Maybe<Availability>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  instantAvailabilityAt: InstantAvailability;
  isDisabled?: Maybe<Scalars['Boolean']>;
  /** Indicate whether the desk has been explicitly marked as not available. */
  isReservable: Scalars['Boolean'];
  level?: Maybe<Level>;
  location: Location;
  name: Scalars['String'];
  permissions: Array<Permission>;
  reservationPolicies?: Maybe<DeskReservationPolicies>;
  space: Space;
  state: DeskState;
  stickers: Array<Sticker>;
  /** Specify the type of booking supported for this desk (Assigned, Hot, Hoteled). */
  type: BookingPolicyType;
  updatedAt: Scalars['Date'];
  windowedAvailability: Array<WindowAvailability>;
  zone?: Maybe<Zone>;
};


export type DeskAvailableAtArgs = {
  time?: InputMaybe<Scalars['Date']>;
};


export type DeskInstantAvailabilityAtArgs = {
  time?: InputMaybe<Scalars['Date']>;
};


export type DeskStateArgs = {
  endTime: Scalars['Date'];
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
  userId?: InputMaybe<Scalars['Int']>;
};


export type DeskWindowedAvailabilityArgs = {
  intervalInMinutes: Scalars['Int'];
  numberOfWindows: Scalars['Int'];
  startTime?: InputMaybe<Scalars['Date']>;
};

export type DeskAmenity = {
  __typename?: 'DeskAmenity';
  amenityId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type DeskAvailability = {
  __typename?: 'DeskAvailability';
  id: Scalars['ID'];
  nextAvailableMeta: NextAvailableMeta;
  status: Availability;
  unbookableReasons: Array<DeskunbookableReasons>;
};

export type DeskAvailabilityByLevelId = {
  __typename?: 'DeskAvailabilityByLevelId';
  availableDeskIds: Array<Scalars['Int']>;
  busyDeskIds: Array<Scalars['Int']>;
  forbiddenDeskIds: Array<Scalars['Int']>;
};

export type DeskAvailabilityLocalizedDate = {
  __typename?: 'DeskAvailabilityLocalizedDate';
  availability: Availability;
  dates: Array<Scalars['LocalDate']>;
  deskId: Scalars['ID'];
  timezone: Scalars['IANATimezone'];
};

export type DeskConfirmation = {
  __typename?: 'DeskConfirmation';
  confirmationWindowEnd?: Maybe<Scalars['Date']>;
  confirmationWindowStart?: Maybe<Scalars['Date']>;
  confirmedAt?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['ID']>;
  method?: Maybe<ConfirmationMethod>;
  seatReservationId: Scalars['ID'];
  status: DeskConfirmationStatus;
  unconfirmableReasons: Array<UnconfirmableReason>;
  user?: Maybe<User>;
};

export enum DeskConfirmationStatus {
  /** Desk reservation cannot be confirmed */
  CannotConfirm = 'CANNOT_CONFIRM',
  /** Desk reservation has been confirmed */
  Confirmed = 'CONFIRMED',
  /** Desk reservation has been declined */
  Declined = 'DECLINED',
  /** Desk reservation has not been confirmed */
  Unconfirmed = 'UNCONFIRMED'
}

export enum DeskConfirmationType {
  Compass = 'Compass',
  Dashboard = 'Dashboard',
  Email = 'Email',
  Ip = 'IP',
  Nfc = 'NFC',
  Qr = 'QR',
  Slack = 'Slack',
  Teams = 'Teams'
}

export enum DeskFilters {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type DeskReservationCheckinPolicies = {
  __typename?: 'DeskReservationCheckinPolicies';
  assignedCheckInEnabled?: Maybe<Scalars['Boolean']>;
  checkInWindow?: Maybe<Scalars['String']>;
  /** there are other checkin policies available, but we only expose this one */
  enforceLocalCheckinOnly: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type DeskReservationPolicies = {
  __typename?: 'DeskReservationPolicies';
  allowExclusions?: Maybe<Scalars['Boolean']>;
  allowOverlappingReservations?: Maybe<Scalars['Boolean']>;
  /** If this is null, checkin policies are not enabled for the desk */
  checkinPolicies?: Maybe<DeskReservationCheckinPolicies>;
  id: Scalars['ID'];
  seatReservationAdvancedBookingThreshold?: Maybe<Scalars['String']>;
  seatReservationMaxLength?: Maybe<Scalars['String']>;
  seatReservationTypesAllowed?: Maybe<Scalars['String']>;
  seatReservationVisibilityEnabled?: Maybe<Scalars['Boolean']>;
};

export enum DeskReservationVisibility {
  Everyone = 'EVERYONE',
  JustMe = 'JUST_ME'
}

export type DeskState = {
  __typename?: 'DeskState';
  availability: DeskAvailability;
  exclusions: Array<Exclusion>;
  id: Scalars['ID'];
  primaryReservation?: Maybe<Reservation>;
  reservations: Array<Reservation>;
};

export enum DeskType {
  Assigned = 'assigned',
  Hot = 'hot',
  Hoteled = 'hoteled',
  Shared = 'shared'
}

export enum DeskUnbookableReasonType {
  LackingPermissions = 'LACKING_PERMISSIONS',
  MaxCapacityReached = 'MAX_CAPACITY_REACHED',
  NoAccess = 'NO_ACCESS',
  ScheduleViolated = 'SCHEDULE_VIOLATED',
  SeatReservationAdvancedBookingThreshold = 'SEAT_RESERVATION_ADVANCED_BOOKING_THRESHOLD',
  SeatReservationMaxLength = 'SEAT_RESERVATION_MAX_LENGTH',
  SeatReservationRecurrenceAllowed = 'SEAT_RESERVATION_RECURRENCE_ALLOWED',
  SeatReservationWorkingHoursRestricted = 'SEAT_RESERVATION_WORKING_HOURS_RESTRICTED',
  Unknown = 'UNKNOWN',
  ViolateHotDeskPolicy = 'VIOLATE_HOT_DESK_POLICY'
}

export type DeskunbookableReasons = {
  __typename?: 'DeskunbookableReasons';
  reason: DeskUnbookableReasonType;
  value?: Maybe<Scalars['String']>;
};

export type DeviceAuthorizationResponse = {
  __typename?: 'DeviceAuthorizationResponse';
  bearerToken: Scalars['String'];
  deviceName: Scalars['String'];
  deviceType: Device_Type;
  /** device ID */
  id: Scalars['ID'];
  location: Location;
  organizationId: Scalars['ID'];
  refreshToken: Scalars['String'];
};

export enum DeviceFeatureFlagType {
  /** Language selection feature flag for a location global */
  LocationLanguageSelectionEnabled = 'LOCATION_LANGUAGE_SELECTION_ENABLED'
}

export type DistributionInput = {
  distributionService: DistributionService;
  id?: InputMaybe<Scalars['ID']>;
  recipients: Array<DistributionRecipientInput>;
};

/**
 * display -> what is shown to user (ie slack channel name)
 * value -> identifier data used to cross post (ie slack channel conversationId)
 */
export type DistributionRecipient = {
  __typename?: 'DistributionRecipient';
  display?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type DistributionRecipientInput = {
  display?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  value: Scalars['String'];
};

export enum DistributionService {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Teams = 'TEAMS'
}

export type Email = {
  __typename?: 'Email';
  email: Scalars['String'];
  isVerified: Scalars['Boolean'];
};

export type EmployeeVisit = {
  __typename?: 'EmployeeVisit';
  confirmation: EmployeeVisitConfirmation;
  endTime: Scalars['ISODateTime'];
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  userId: Scalars['ID'];
};

export type EmployeeVisitConfirmation = {
  __typename?: 'EmployeeVisitConfirmation';
  confirmed_at?: Maybe<Scalars['ISODateTime']>;
  employeeVisitId: Scalars['ID'];
  status: EmployeeVisitConfirmationStatus;
};

export enum EmployeeVisitConfirmationStatus {
  Canceled = 'CANCELED',
  Confirmed = 'CONFIRMED',
  Unknown = 'UNKNOWN'
}

export type EmployeeVisitMutations = {
  __typename?: 'EmployeeVisitMutations';
  setWeeklySchedule?: Maybe<Scalars['ID']>;
};


export type EmployeeVisitMutationsSetWeeklyScheduleArgs = {
  input: SetWeeklyScheduleInput;
};

export type EmployeeVisitQueries = {
  __typename?: 'EmployeeVisitQueries';
  getWeeklyScheduleForUser?: Maybe<WeeklyScheduleResult>;
};


export type EmployeeVisitQueriesGetWeeklyScheduleForUserArgs = {
  locationId: Scalars['String'];
  userId: Scalars['String'];
};

export type EmployeeVisitSchedule = {
  __typename?: 'EmployeeVisitSchedule';
  id?: Maybe<Scalars['String']>;
};

export type EmployeeVisitsByDate = {
  __typename?: 'EmployeeVisitsByDate';
  date: Scalars['Date'];
  employeeVisits: Array<EmployeeVisit>;
};

export type EndOrCancelDelegatedReservationResponse = {
  __typename?: 'EndOrCancelDelegatedReservationResponse';
  reservationId: Scalars['ID'];
};

export type EndOrCancelReservationResponse = {
  __typename?: 'EndOrCancelReservationResponse';
  reservationId: Scalars['ID'];
};

export type Evaluations = {
  workingHours?: InputMaybe<Scalars['Boolean']>;
};

export type Event = {
  __typename?: 'Event';
  creationType: EventCreationType;
  creatorEmail?: Maybe<Scalars['String']>;
  creatorId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  /** Event Id */
  id: Scalars['ID'];
  invitees: Array<EventInvitee>;
  isAllDay: Scalars['Boolean'];
  organizerEmail?: Maybe<Scalars['String']>;
  organizerId?: Maybe<Scalars['ID']>;
  recurrence?: Maybe<Scalars['String']>;
  seriesId?: Maybe<Scalars['String']>;
  spaces: Array<Space>;
  startTime?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['String']>;
  whosInData: WhosInData;
};


export type EventWhosInDataArgs = {
  locationIds: Array<Scalars['ID']>;
};

export enum EventCreationType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  Proxied = 'PROXIED',
  Synced = 'SYNCED',
  Unknown = 'UNKNOWN'
}

export type EventInvitee = {
  __typename?: 'EventInvitee';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  eventId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  isOrganizer: Scalars['Boolean'];
  isResource: Scalars['Boolean'];
  participationRole: EventParticipationRole;
  responseStatus: EventResponseStatus;
  spaceId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
};

export enum EventParticipationRole {
  Chair = 'CHAIR',
  NonParticipant = 'NON_PARTICIPANT',
  Optional = 'OPTIONAL',
  Required = 'REQUIRED',
  Unknown = 'UNKNOWN'
}

export enum EventResponseStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Delegated = 'DELEGATED',
  NotResponded = 'NOT_RESPONDED',
  Tentative = 'TENTATIVE',
  Unknown = 'UNKNOWN'
}

export enum EventUpdateFailReasons {
  LackingEventUpdatePermissions = 'LACKING_EVENT_UPDATE_PERMISSIONS',
  SpaceDeclined = 'SPACE_DECLINED',
  SpaceUnavailable = 'SPACE_UNAVAILABLE',
  Unknown = 'UNKNOWN'
}

export type EventUpdatePermission = {
  __typename?: 'EventUpdatePermission';
  eventId: Scalars['String'];
  isPermitted: Scalars['Boolean'];
};

export type Exclusion = {
  __typename?: 'Exclusion';
  endTime?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated temporally broken because API currently provides series master id instead of the instance id */
  reservation?: Maybe<Reservation>;
  startTime: Scalars['String'];
};

export type ExternalEvent = {
  __typename?: 'ExternalEvent';
  description: Scalars['String'];
  endDate: Scalars['ISODateTime'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  sourceUrl: Scalars['String'];
  startDate: Scalars['ISODateTime'];
  ticketUrl?: Maybe<Scalars['String']>;
  venue: ExternalEventVenue;
};

export type ExternalEventVenue = {
  __typename?: 'ExternalEventVenue';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export type ExternalIntegration = {
  __typename?: 'ExternalIntegration';
  archivedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  enabled?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationJson?: Maybe<Scalars['Json']>;
  name?: Maybe<Scalars['String']>;
  tenantId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum FeatureFlagType {
  /** Enables add a space modal on web */
  AddSpaceModalWeb = 'ADD_SPACE_MODAL_WEB',
  /** New booking flow */
  ConsolidatedBookingFlow = 'CONSOLIDATED_BOOKING_FLOW',
  /** Enables the create org functionality in the dashboard apps project */
  DashboardAppsCreateOrganization = 'DASHBOARD_APPS_CREATE_ORGANIZATION',
  /** Enables the new schedule tab in the dashboard apps project */
  DashboardAppsScheduleTab = 'DASHBOARD_APPS_SCHEDULE_TAB',
  /** Enables desk issue reporting */
  DeskIssueReporting = 'DESK_ISSUE_REPORTING',
  /** Enables the employee visits feature set */
  EmployeeVisits = 'EMPLOYEE_VISITS',
  /** Enables external event sync from OccasionGenius */
  ExternalOccasionGeniusEventSync = 'EXTERNAL_OCCASION_GENIUS_EVENT_SYNC',
  /** Enables issue reporting V2 - report issues with spaces */
  IssueReportingV2 = 'ISSUE_REPORTING_V2',
  /** Enables passive check in */
  PassiveCheckIn = 'PASSIVE_CHECK_IN',
  /** Optimizes people search */
  PeopleSearchOptimization = 'PEOPLE_SEARCH_OPTIMIZATION',
  /** Sharptooth team feature flag for development */
  SharptoothDevelopment = 'SHARPTOOTH_DEVELOPMENT',
  /** Enables space recommendations */
  SuggestedSpaces = 'SUGGESTED_SPACES',
  /** Reveals features for work area insights */
  WorkAreaInsights = 'WORK_AREA_INSIGHTS'
}

export enum FeatureType {
  ActivatedUsers = 'ACTIVATED_USERS',
  ActiveUsers = 'ACTIVE_USERS',
  BookableSeats = 'BOOKABLE_SEATS',
  BookableSpaces = 'BOOKABLE_SPACES',
  ExportableDataDays = 'EXPORTABLE_DATA_DAYS',
  Levels = 'LEVELS',
  Locations = 'LOCATIONS',
  Users = 'USERS',
  ViewableDataDays = 'VIEWABLE_DATA_DAYS'
}

export type GenerateStickerBatchRequest = {
  count?: InputMaybe<Scalars['Int']>;
};

export type GenerateStickerBatchResponse = {
  __typename?: 'GenerateStickerBatchResponse';
  batch: StickerBatch;
};

export enum GenericHealthCheckpointEntityType {
  GuestInvite = 'GUEST_INVITE'
}

export type GenericHealthCheckpointResponse = {
  __typename?: 'GenericHealthCheckpointResponse';
  id: Scalars['ID'];
  status: GenericHealthCheckpointStatus;
};

export enum GenericHealthCheckpointStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  Passed = 'PASSED',
  Unknown = 'UNKNOWN'
}

export type GetDeskAvailabilityByLevelIdFiltersInput = {
  deskAmenityIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetDeskAvailabilityByLevelIdScopeInput = {
  levelId: Scalars['ID'];
};

export type GetDeskAvailabilityByLevelIdViewOptionsInput = {
  endTime: Scalars['ISODateTime'];
  startTime: Scalars['ISODateTime'];
};

export type GetSuggestedSpaceForEventsEventInput = {
  endTime: Scalars['ISODateTime'];
  id: Scalars['ID'];
  /** Represents the number of attendees that are in the office for the filtered location and also accepted the event */
  preferredSpaceCapacity?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['ISODateTime'];
};

/** How to decide which spaces to suggest - for A/B testing */
export enum GetSuggestedSpacesForEventsAlgorithm {
  FrecencyV1 = 'FRECENCY_V1',
  FrecencyV2 = 'FRECENCY_V2'
}

export type GetSuggestedSpacesForEventsInput = {
  algorithm?: InputMaybe<GetSuggestedSpacesForEventsAlgorithm>;
  events: Array<GetSuggestedSpaceForEventsEventInput>;
  spaceFilters: GetSuggestedSpacesForEventsSpaceFiltersInput;
  /** Loose preferences for spaces - attempt to fit criteria but no guarantees */
  spacePreferences?: InputMaybe<GetSuggestedSpacesForEventsSpacePreferencesInput>;
};

export type GetSuggestedSpacesForEventsResponse = {
  __typename?: 'GetSuggestedSpacesForEventsResponse';
  /** Nullable to force resilience in the face of changing enum values */
  algorithm?: Maybe<GetSuggestedSpacesForEventsAlgorithm>;
  suggestedSpaces: Array<SuggestedSpaceForEvent>;
};

export type GetSuggestedSpacesForEventsSpaceFiltersInput = {
  locationIds: Array<Scalars['ID']>;
};

export type GetSuggestedSpacesForEventsSpacePreferencesInput = {
  levelIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** How to decide which spaces to suggest - for A/B testing */
export enum GetSuggestedSpacesForTimeFrameAlgorithm {
  FrecencyV1 = 'FRECENCY_V1',
  FrecencyV2 = 'FRECENCY_V2'
}

export type GetSuggestedSpacesForTimeFrameFiltersInput = {
  locationIds: Array<Scalars['ID']>;
};

export type GetSuggestedSpacesForTimeFrameInput = {
  algorithm?: InputMaybe<GetSuggestedSpacesForEventsAlgorithm>;
  endTime: Scalars['ISODateTime'];
  filters: GetSuggestedSpacesForTimeFrameFiltersInput;
  /** Loose preferences for spaces - attempt to fit criteria but no guarantees */
  spacePreferences?: InputMaybe<GetSuggestedSpacesForTimeFramePreferencesInput>;
  startTime: Scalars['ISODateTime'];
};

export type GetSuggestedSpacesForTimeFramePreferencesInput = {
  levelIds?: InputMaybe<Array<Scalars['ID']>>;
  preferredSpaceCapacity?: InputMaybe<Scalars['Int']>;
};

export type GetSuggestedSpacesForTimeFrameResponse = {
  __typename?: 'GetSuggestedSpacesForTimeFrameResponse';
  /** Nullable to force resilience in the face of changing enum values */
  algorithm?: Maybe<GetSuggestedSpacesForTimeFrameAlgorithm>;
  suggestedSpaces: Array<Space>;
};

/** Filters for getPaginatedSurveyResponses */
export type GetSurveyResponsesFilters = {
  /** FIlter results by one or more building(s) */
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** End of search window */
  inOfficeAtEnd: Scalars['ISODateTime'];
  /** Beginning of search window */
  inOfficeAtStart: Scalars['ISODateTime'];
  /** Include pending, expired, and dismissed survey responses */
  includeIncomplete?: InputMaybe<Scalars['Boolean']>;
  /** Filter results by one or more rating(s) */
  ratings?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Filter resuts by a user ID */
  userId?: InputMaybe<Scalars['String']>;
};

/** Input for sorting survey responses by different keys */
export type GetSurveyResponsesSort = {
  /** The key to sort responses by */
  key: SurveyResponseSortKey;
  /** The order to sort that key */
  order: SortOrder;
};

export type GetTimeZonesForRipplingAddressesInput = {
  addresses: Array<Scalars['String']>;
};

export type GetTimeZonesForRipplingAddressesResponse = {
  __typename?: 'GetTimeZonesForRipplingAddressesResponse';
  timezones: Array<GetTimeZonesForRipplingAddressesResponseTimeZone>;
};

export type GetTimeZonesForRipplingAddressesResponseTimeZone = {
  __typename?: 'GetTimeZonesForRipplingAddressesResponseTimeZone';
  address: Scalars['String'];
  zone?: Maybe<Scalars['String']>;
};

export type GetUpdatePermissionsForEventsResponse = {
  __typename?: 'GetUpdatePermissionsForEventsResponse';
  events: Array<Maybe<EventUpdatePermission>>;
};

export type GoogleWorkingLocationSyncSetting = {
  __typename?: 'GoogleWorkingLocationSyncSetting';
  organizationId: Scalars['ID'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  members: Array<User>;
  name: Scalars['String'];
};

/**
 * A Guest is someone who was invited to a Visit.
 * A single guest may be invited to multiple Visits, via a GuestInvite that's unique for each visit.
 */
export type Guest = {
  __typename?: 'Guest';
  /** Email address of the guest */
  guestEmail: Scalars['String'];
  /** Name of the guest, ex Jeff Jefferson */
  guestName: Scalars['String'];
  /** Guest unique identifier */
  id: Scalars['ID'];
  /** Guest personal identity information has been obfuscated */
  isObfuscated: Scalars['Boolean'];
  /** If this guest has been PII obfuscated, it occurred on this date and time */
  obfuscatedOn?: Maybe<Scalars['ISODateTime']>;
};

export type GuestInputType = {
  /** Email address of the guest */
  guestEmail: Scalars['String'];
  /** An optional, client-generated ID for this guest's invite record */
  guestInviteId?: InputMaybe<Scalars['ID']>;
  /** Name of the guest, ex Jeff Jefferson */
  guestName: Scalars['String'];
};

/**
 * A GuestInvite is the pairing of a Guest with a particular visit. It contains details about that guest's visit.
 * GuestInvite is unique to the Visit, but a Guest can be associated with multiple Visits as a part of different GuestInvites.
 */
export type GuestInvite = {
  __typename?: 'GuestInvite';
  /** Visit canceled by robin user */
  canceledBy?: Maybe<UserWithDefault>;
  /** Date and time of cancelation */
  canceledOn?: Maybe<Scalars['ISODateTime']>;
  /** Why the visit was canceled, if specified */
  canceledReason?: Maybe<GuestInviteCanceledReason>;
  /** Date and time of the check-in, null if never checked-in */
  checkinOn?: Maybe<Scalars['String']>;
  /** Date and time of the checkout, null if never checked-out */
  checkoutOn?: Maybe<Scalars['String']>;
  /** Invited Guest */
  guest: Guest;
  /** The health checkpoint survey response associated with the visit */
  healthCheckpointSurveyResponse?: Maybe<GenericHealthCheckpointResponse>;
  /** Guest Invite unique identifier */
  id: Scalars['ID'];
  /** The guest's magic link */
  magicLink: Scalars['String'];
  /** Status of guest invitation */
  status: GuestInviteStatus;
};

export enum GuestInviteCanceledReason {
  FailedHealthCheckpoint = 'FAILED_HEALTH_CHECKPOINT'
}

export enum GuestInviteStatus {
  Canceled = 'CANCELED',
  CheckedIn = 'CHECKED_IN',
  CheckedOut = 'CHECKED_OUT',
  CompletedRequirements = 'COMPLETED_REQUIREMENTS',
  Expected = 'EXPECTED',
  Invited = 'INVITED',
  PendingInvite = 'PENDING_INVITE'
}

export type Guideline = {
  __typename?: 'Guideline';
  id: Scalars['ID'];
  interval: GuidelineInterval;
  message?: Maybe<Scalars['String']>;
  tooltipMessage?: Maybe<Scalars['String']>;
};

export type GuidelineInput = {
  interval: GuidelineIntervalInput;
  message?: InputMaybe<Scalars['String']>;
  tooltipMessage?: InputMaybe<Scalars['String']>;
};

export type GuidelineInterval = {
  __typename?: 'GuidelineInterval';
  frequency: Scalars['Int'];
  intervalType: GuidelineIntervalType;
};

export type GuidelineIntervalInput = {
  frequency: Scalars['Int'];
  intervalType: GuidelineIntervalType;
};

export enum GuidelineIntervalType {
  Weekly = 'WEEKLY'
}

export type GuidelineWithProgress = {
  __typename?: 'GuidelineWithProgress';
  id: Scalars['ID'];
  interval: GuidelineInterval;
  message?: Maybe<Scalars['String']>;
  progress: Scalars['Int'];
  tooltipMessage?: Maybe<Scalars['String']>;
};

export type HealthCheckpoint = {
  __typename?: 'HealthCheckpoint';
  customizations?: Maybe<Customizations>;
  status: HealthCheckpointStatus;
  surveyId?: Maybe<Scalars['String']>;
  surveyUrl?: Maybe<Scalars['String']>;
};

export enum HealthCheckpointStatus {
  Complete = 'COMPLETE',
  Disabled = 'DISABLED',
  Exempt = 'EXEMPT',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  NotAvailable = 'NOT_AVAILABLE',
  Passed = 'PASSED'
}

export enum HomepageType {
  Office = 'OFFICE',
  Schedule = 'SCHEDULE'
}

export type InOfficeData = {
  __typename?: 'InOfficeData';
  peopleInTheOffice: Array<User>;
  totalNumberOfPeopleInTheOffice: Scalars['Int'];
};


export type InOfficeDataPeopleInTheOfficeArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  preferredUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type InOfficeDataByDay = {
  __typename?: 'InOfficeDataByDay';
  date: Scalars['ISODateTime'];
  inOfficeData: InOfficeData;
};

export type InitiateFullRipplingUserSyncInput = {
  organizationId: Scalars['ID'];
};

export type InitiateFullRipplingUserSyncResponse = {
  __typename?: 'InitiateFullRipplingUserSyncResponse';
  id: Scalars['ID'];
};

export type InitiateRipplingBuildingSyncInput = {
  id: Scalars['ID'];
};

export type InitiateRipplingBuildingSyncResponse = {
  __typename?: 'InitiateRipplingBuildingSyncResponse';
  id: Scalars['ID'];
};

export type InitiateRipplingConnectionAndSyncInput = {
  organizationId: Scalars['ID'];
};

export type InitiateRipplingConnectionAndSyncResponse = {
  __typename?: 'InitiateRipplingConnectionAndSyncResponse';
  buildingSyncId: Scalars['ID'];
};

export type InstantAvailability = {
  __typename?: 'InstantAvailability';
  availability: Availability;
  reservation?: Maybe<Reservation>;
};

export type InvalidCidrError = {
  __typename?: 'InvalidCidrError';
  cidr: Scalars['IPAddressCIDR'];
  message: Scalars['String'];
};

export type IsPassiveCheckInFeatureEnabledForBuildingInput = {
  buildingId: Scalars['ID'];
};

export type Level = {
  __typename?: 'Level';
  /** Total desks on the floor - excluding inactive. */
  activeDeskCount: Scalars['Int'];
  availableDeskAmenities: Array<MinimizedAmenityViewModel>;
  availableSpaceAmenities: Array<MinimizedAmenityViewModel>;
  campusId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  /** Total desks on the floor - including inactive. */
  deskCount: Scalars['Int'];
  /** Total desks within the level that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  desks: Array<Desk>;
  filteredDesksByAmenityIds: Array<Desk>;
  filteredSpacesByAmenityIds: Array<Space>;
  id: Scalars['ID'];
  levelNumber?: Maybe<Scalars['Int']>;
  locationId: Scalars['ID'];
  /** check whether the level has a map or not */
  mapIsAvailable?: Maybe<Scalars['Boolean']>;
  mapUploadStatus?: Maybe<MapUploadStatus>;
  maxCapacity: Scalars['Int'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  spaces: Array<Space>;
  state: LevelState;
  timezone?: Maybe<Scalars['String']>;
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int'];
  /** total spaces will be contextual to the filter */
  totalSpaces: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Date']>;
  workAreas: Array<WorkArea>;
  /**
   * Total desks within the location where booked counts are bound to
   * the current workday and the configured hours of operation for the location
   */
  workplaceDeskSchedulingAggregates: BookedSchedulingAggregateOfDesks;
  zones: Array<Zone>;
};


export type LevelDesksArgs = {
  filters?: InputMaybe<Array<DeskFilters>>;
};


export type LevelFilteredDesksByAmenityIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type LevelFilteredSpacesByAmenityIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type LevelSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};


export type LevelStateArgs = {
  endTime: Scalars['Date'];
  evaluations?: InputMaybe<Evaluations>;
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
};


export type LevelTotalSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};

export type LevelDeskReservation = {
  __typename?: 'LevelDeskReservation';
  levelId: Scalars['ID'];
  reservations: Array<Reservation>;
};

export type LevelState = {
  __typename?: 'LevelState';
  availability: PlaceAvailability;
  freeCapacity: Scalars['Int'];
};

export type ListBuildingCidrsInput = {
  buildingId: Scalars['ID'];
};

export type ListBuildingCidrsResponse = {
  __typename?: 'ListBuildingCidrsResponse';
  cidrs: Array<Scalars['IPAddressCIDR']>;
};

export type Location = {
  __typename?: 'Location';
  /** Total desks in the building - excluding inactive. */
  activeDeskCount?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  campus?: Maybe<Campus>;
  campusId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  /**
   * customWorkingHours are limited to 100 entries
   * and will only grab custom working hours from the
   * current date of the location and upcoming custom working hours
   * It paginates via optional `startFromDateTime` parameter
   * This will grab custom working hours that has a date time
   * greater than `startFromDateTime` provided
   * Format expected is `YYYY-MM-DD HH:mm`
   */
  customWorkingHours: Array<CustomWorkingHours>;
  description?: Maybe<Scalars['String']>;
  /** Total desks in the building - including inactive. */
  deskCount: Scalars['Int'];
  /** Total desks within the location that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  /**
   * Expects a `startDate` and `endDate` formated as YYYY-MM-DD
   * `startDate` and `endDate` are inclusive
   */
  hoursForTimeRange: Array<DateSpecificWorkingHours>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  isHealthCheckpointRequired: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  levels: Array<Level>;
  longitude?: Maybe<Scalars['Float']>;
  maxCapacity: Scalars['Int'];
  name: Scalars['String'];
  officeAccess: Array<OfficeAccess>;
  organizationId: Scalars['ID'];
  preferredLanguageTag?: Maybe<Scalars['String']>;
  remoteGoogleId?: Maybe<Scalars['ID']>;
  spaces: Array<Space>;
  state: LocationState;
  stateByDay: Array<LocationStateByDay>;
  timezone?: Maybe<Scalars['String']>;
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int'];
  totalLevels: Scalars['Int'];
  /** total spaces will be contextual to the filter */
  totalSpaces: Scalars['Int'];
  updatedAt: Scalars['Date'];
  workingHours: Array<WorkingHours>;
  /**
   * Total desks within the location where booked counts are bound to
   * the current workday and the configured hours of operation for the location
   */
  workplaceDeskSchedulingAggregates: BookedSchedulingAggregateOfDesks;
};


export type LocationCustomWorkingHoursArgs = {
  startFromDateTime?: InputMaybe<Scalars['Date']>;
};


export type LocationHoursForTimeRangeArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type LocationOfficeAccessArgs = {
  userId: Scalars['ID'];
};


export type LocationSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};


export type LocationStateArgs = {
  endTime: Scalars['Date'];
  evaluations?: InputMaybe<Evaluations>;
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
  userId: Scalars['ID'];
};


export type LocationStateByDayArgs = {
  endTime: Scalars['Date'];
  evaluations?: InputMaybe<Evaluations>;
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
};


export type LocationTotalSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};

export type LocationDeskReservation = {
  __typename?: 'LocationDeskReservation';
  locationId: Scalars['ID'];
  reservations: Array<Reservation>;
};

export type LocationState = {
  __typename?: 'LocationState';
  availability: PlaceAvailability;
  freeCapacity: Scalars['Int'];
  isOpen?: Maybe<Scalars['Boolean']>;
};

export type LocationStateByDay = {
  __typename?: 'LocationStateByDay';
  availability: PlaceAvailability;
  day: Scalars['Date'];
};

export type LogSuggestedSpaceBookedInput = {
  suggestedSpaceRecordId: Scalars['ID'];
};

export type LogSuggestedSpaceDismissedInput = {
  suggestedSpaceRecordId: Scalars['ID'];
};

export type LogSuggestedSpaceViewedInput = {
  suggestedSpaceRecordId: Scalars['ID'];
};

export type MapBuildingToRipplingWorkLocationInput = {
  buildingId: Scalars['ID'];
  organizationId: Scalars['ID'];
  ripplingWorkLocationId: Scalars['ID'];
};

export type MapBuildingToRipplingWorkLocationResponse = {
  __typename?: 'MapBuildingToRipplingWorkLocationResponse';
  id: Scalars['ID'];
};

export type MapBuildingsToRipplingWorkLocationsInput = {
  mappings: Array<MapBuildingsToRipplingWorkLocationsMappingsInput>;
  organizationId: Scalars['ID'];
};

export type MapBuildingsToRipplingWorkLocationsMappingsInput = {
  buildingId: Scalars['ID'];
  ripplingWorkLocationId: Scalars['ID'];
};

export type MapBuildingsToRipplingWorkLocationsResponse = {
  __typename?: 'MapBuildingsToRipplingWorkLocationsResponse';
  id: Scalars['ID'];
};

export enum MapUploadStatus {
  AwaitingUpload = 'AWAITING_UPLOAD',
  Unknown = 'UNKNOWN',
  Uploaded = 'UPLOADED'
}

export type MeOrganization = {
  __typename?: 'MeOrganization';
  avatar: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type MeteredFeature = {
  __typename?: 'MeteredFeature';
  billableTotal?: Maybe<Scalars['Int']>;
  feature: FeatureType;
  limit?: Maybe<Scalars['Int']>;
  overProvisioningLimit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type MinimizedAmenityViewModel = {
  __typename?: 'MinimizedAmenityViewModel';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MultiPlacePassInput = {
  groups: Array<Scalars['ID']>;
  healthCheckpointRequired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  places: Array<PassPlaceInput>;
  schedule: Array<Scalars['String']>;
  userChanges?: InputMaybe<PassUsersInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Requesting user acknowledges a given announcement for a given location */
  acknowledgeAnnouncement?: Maybe<Scalars['Void']>;
  acknowledgeAnnouncementForMultipleLocations?: Maybe<Scalars['Void']>;
  /** Acknowledge the handling of visit events to prevent duplication of event driven side-effects */
  acknowledgeVisitEventHandled?: Maybe<Scalars['Void']>;
  addCustomWorkingHours?: Maybe<Scalars['Void']>;
  addLevelToLocation?: Maybe<AddLevelToLocationResponse>;
  addLevelsToLocation?: Maybe<Array<Maybe<AddLevelsToLocationResponse>>>;
  addLocationToOrganization?: Maybe<AddLocationToOrganizationResponse>;
  addOfficeAdmin?: Maybe<Scalars['Void']>;
  addOrganization?: Maybe<AddOrganizationResponse>;
  addPinnedUserSpaces?: Maybe<Scalars['Void']>;
  addSpaceToEvent: UpdateEventResponse;
  addUserToFavorites?: Maybe<Scalars['Void']>;
  addUserToRole?: Maybe<Scalars['Void']>;
  /** Created a org_visit_type entry matching the given visitTypeName. If visit_type exists, simply enable it for given org. */
  addVisitTypeForOrg?: Maybe<Scalars['Void']>;
  /** Allows a robin administrator to register a guest for a visit at some location */
  administratorRegisterGuest?: Maybe<Scalars['Void']>;
  /** @deprecated Use the `autoAllocateStickersToOrg` operation instead */
  allocateStickersToOrg?: Maybe<Scalars['Void']>;
  /** @deprecated Use the `autoAllocateStickersToOrg` operation instead */
  allocateStickersToOrgByDisplayCode?: Maybe<Scalars['Void']>;
  /**
   * Archive an announcement by ID.
   * Changes the `status` to ARCHIVED.
   */
  archiveAnnouncement?: Maybe<Scalars['Void']>;
  /**
   * Archive a SLACK announcement by ID.
   * Changes the `status` to ARCHIVED.
   */
  archiveAnnouncementForSlack?: Maybe<Scalars['Void']>;
  /** Archives an integration by id */
  archiveIntegrationById?: Maybe<Scalars['Void']>;
  /** Recover your arrival display using a client signed certificate of authority */
  arrivalDisplayCertRecovery: ArrivalDisplayRescueResponse;
  /** Provided the expressed name and email, either find or create a guest record. Returns guest ID in all cases. */
  assertGuestIdentity: Scalars['ID'];
  /** Creates a new assigned desk reservation for a given user on your behalf. */
  assignDelegatedDesk: Reservation;
  /** Creates a new assigned desk reservation for the current user. */
  assignDeskToMe: Reservation;
  assignStickerToDeskByShortCode?: Maybe<AssignStickerToDeskByShortCodeResponse>;
  assignStickers: AssignStickerResponse;
  /** Associate a health checkpoint response record to a guest invite */
  associateHealthCheckpointWithVisit?: Maybe<Scalars['Void']>;
  /** Generate an authentication bearer token in exchange for a magic link token */
  authenticateVisitorWithMagicLink: VisitMagicLinkAuthResponse;
  autoAllocateStickersToMultipleOrgs?: Maybe<Scalars['Void']>;
  autoAllocateStickersToOrg?: Maybe<Scalars['Void']>;
  /**
   * Archive multiple announcements by IDs, modifying it's status to ARCHIVED.
   * If one announcement is unable to be processed, it fails the entire operation
   */
  bulkArchiveAnnouncements?: Maybe<Scalars['Void']>;
  /**
   * Bulk updates custom working hours for a single location
   * by overriding all the custom working hours to the custom working
   * hour objects provided in the payload. There are three scenarios:
   * If a custom working hour object in the payload has an ID, it updates
   * that object in the db with the corresponding ID
   * If no ID is provided with a custom working hour object, a new object
   * is created in the db
   * If there are objects in the db that is not defined in the payload,
   * they are deleted
   *
   * If there is a failure for one of the objects, the entire operation fails
   * and reverts to custom working hours before the request was made.
   */
  bulkUpdateCustomWorkingHours?: Maybe<Scalars['Void']>;
  cancelActivity?: Maybe<Scalars['Void']>;
  /** Cancel an existing employee visit */
  cancelEmployeeVisit?: Maybe<Scalars['Void']>;
  cancelVisit?: Maybe<Scalars['Void']>;
  /** check into a visit that was self-registered from the arrival display */
  checkIntoSelfRegisteredVisitFromArrivalDisplay?: Maybe<Scalars['Void']>;
  /**
   * check into a visit using a guest's magic link
   * @deprecated checkIntoVisit is deprecated. Use checkIntoVisitWithMagicLink instead.
   */
  checkIntoVisit?: Maybe<Scalars['Void']>;
  /** check into a visit for a location that does not require HCP from the arrival display */
  checkIntoVisitFromArrivalDisplay?: Maybe<Scalars['Void']>;
  /**
   * check into a visit for a location that requires HCP from the arrival display
   * @deprecated Use checkIntoSelfRegisteredVisitFromArrivalDisplay instead
   */
  checkIntoVisitRequiringHCPFromArrivalDisplay?: Maybe<Scalars['Void']>;
  /** check into a visit using a guest's magic link */
  checkIntoVisitWithMagicLink?: Maybe<Scalars['Void']>;
  /** check out a guest from the arrival display */
  checkOutGuestFromArrivalDisplay?: Maybe<Scalars['Void']>;
  /** check out a guest from the visit admin dashboard */
  checkOutGuestFromDashboard?: Maybe<Scalars['Void']>;
  /** Complete a a survey response */
  completeSurveyResponse?: Maybe<SurveyResponse>;
  confirmDeskReservation?: Maybe<Scalars['Void']>;
  /**
   * Confirm the desk reservation for the given user.
   * Requires a token with elevated scopes.
   */
  confirmDeskReservationDelegated?: Maybe<Scalars['Void']>;
  connectRipplingAccountForOrganization: ConnectRipplingAccountForOrganizationResponse;
  /** Converts an existing API token to a JWT claims token and refresh token pair */
  convertApiTokenToJwtAuth: AuthorizationResponse;
  createActivity: CreateActivityReturnType;
  createAndMapBuildingsFromRippling: CreateAndMapBuildingsFromRipplingResponse;
  /**
   * Create a new announcement for given locations.
   * `status` is ACTIVE by default.
   * - Note: only user actors are allowed to create announcements
   */
  createAnnouncement?: Maybe<Scalars['Void']>;
  /**
   * Create a new announcement to be consumed by SLACK.
   * `status` is ACTIVE by default.
   */
  createAnnouncementForSlack?: Maybe<Scalars['Void']>;
  /**
   * From dashboard on creation of the new arrival display.
   * Creates new device for the given tenant.
   * In event land:
   * Successful create causes event handler to generate new device authentication which is registered against this entity.
   */
  createArrivalDisplay: Scalars['ArrivalDisplayRegistrationKey'];
  createGenericHealthCheckpoint: CreateGenericHealthCheckpointResponse;
  /** Creates a new integration */
  createIntegration?: Maybe<ExternalIntegration>;
  createOfficeAdminRole?: Maybe<Scalars['Void']>;
  /** @deprecated createPass is deprecated. Use createPassForPlaces instead. */
  createPass?: Maybe<Scalars['Void']>;
  /** Creates a pass for one or more places */
  createPassForPlaces?: Maybe<Scalars['Void']>;
  createRobinOrganizationFromRipplingOAuthData: CreateRobinOrganizationFromRipplingOAuthDataResponse;
  createRole?: Maybe<Scalars['Void']>;
  /** Creates a new Sigma workbook in My Documents */
  createSigmaPersonalWorkbook?: Maybe<SigmaWorkbook>;
  /**
   * Internal method to create a new visit with zero side effects.
   * @deprecated Use createVisitWithCustomVisitType
   */
  createVisit?: Maybe<Scalars['Void']>;
  /** Internal method to create a new visit with zero side effects using a string visit type. */
  createVisitWithCustomVisitType?: Maybe<Scalars['Void']>;
  createWorkPolicy?: Maybe<Scalars['Void']>;
  /** Disable a org_visit_type entry matching the given visitTypeName. */
  deactivateVisitTypeForOrg?: Maybe<Scalars['Void']>;
  /**
   * Set a decision for a document for a visiting guest for one document within a specified workflow.
   * @deprecated Use decideOutcomeForVisitDocumentFromArrivalDisplay instead
   */
  decideOutcomeForVisitDocument?: Maybe<Scalars['Void']>;
  /** Set a decision from the Arrival Display for a document for a visiting guest for one document within a specified workflow. */
  decideOutcomeForVisitDocumentFromArrivalDisplay?: Maybe<Scalars['Void']>;
  /** Set a decision from the GuestUI for a document for a visiting guest for one document within a specified workflow. */
  decideOutcomeForVisitDocumentFromGuestUI?: Maybe<Scalars['Void']>;
  /** Ends or cancels a reservation and updates the user's participation status. Should be used to cancel actor's own reservation. */
  declineAndEndReservation: EndOrCancelReservationResponse;
  deletePass?: Maybe<Scalars['Void']>;
  deleteWorkAreaDesks?: Maybe<Scalars['Void']>;
  disableOrganizationUser?: Maybe<Scalars['Void']>;
  disablePassiveCheckInFeatureForBuilding?: Maybe<Scalars['Void']>;
  /** Dismiss a survey response */
  dismissSurveyResponse?: Maybe<SurveyResponse>;
  employeeVisit: EmployeeVisitMutations;
  enableOrganizationUser?: Maybe<Scalars['Void']>;
  enablePassiveCheckInFeatureForBuilding?: Maybe<Scalars['Void']>;
  /** Ends or cancels a reservation via delegation */
  endOrCancelDelegatedReservation: EndOrCancelReservationResponse;
  /** Ends or cancels a reservation. Should be used to cancel actor's own reservation. */
  endOrCancelReservation: EndOrCancelReservationResponse;
  generateStickerBatch: GenerateStickerBatchResponse;
  /** A guest invite failed their health checkpoint */
  guestHealthCheckpointFailed?: Maybe<Scalars['Void']>;
  /** Called when a guest's invitation email has been sent */
  guestInvited?: Maybe<Scalars['Void']>;
  initiateFullRipplingUserSync: InitiateFullRipplingUserSyncResponse;
  initiateRipplingBuildingSync: InitiateRipplingBuildingSyncResponse;
  initiateRipplingConnectionAndSync: InitiateRipplingConnectionAndSyncResponse;
  inviteOfficeAdminUsers?: Maybe<Scalars['Void']>;
  joinActivity?: Maybe<Scalars['Void']>;
  /**
   * Perform whichever action makes sense to allow a user to "Join" the office.
   * If there is a desk reservation reservation, confirm it if allowed.
   * Otherwise, schedule an employee visit.
   */
  joinOffice?: Maybe<Scalars['Void']>;
  logSpaceSuggestionBooked?: Maybe<Scalars['Void']>;
  logSpaceSuggestionDismissed?: Maybe<Scalars['Void']>;
  logSpaceSuggestionViewed?: Maybe<Scalars['Void']>;
  mapBuildingToRipplingWorkLocation: MapBuildingToRipplingWorkLocationResponse;
  mapBuildingsToRipplingWorkLocations: Array<MapBuildingsToRipplingWorkLocationsResponse>;
  refreshDeviceAuthorization: DeviceAuthorizationResponse;
  /** Refreshes an existing JWT claims token and refresh token pair */
  refreshJwtAuthToken: AuthorizationResponse;
  /** Register arrival display for service. Each key only works one time. */
  registerArrivalDisplay: ArrivalDisplayRegistrationResponse;
  /**
   * register a guest in a location that doesn't require HCP from the arrival display - automatically checks the visit in
   * @deprecated Use registerGuestWithoutHCPFromArrivalDisplay
   */
  registerGuestFromArrivalDisplay?: Maybe<Scalars['Void']>;
  /**
   * create a new visit from dashboard. guests listed will be upserted into the set of known guests, matched by email.
   * @deprecated This creation method will be replaced with a more open usage of visit type. Replaced by administratorRegisterGuest.
   */
  registerGuestFromDashboard?: Maybe<Scalars['Void']>;
  /**
   * register a guest in a location that requires a HCP from the arrival display
   * @deprecated Use registerGuestWithHCPFromArrivalDisplayWithCustomVisitType
   */
  registerGuestWithHCPFromArrivalDisplay?: Maybe<Scalars['Void']>;
  /** register a guest in a location that requires a HCP from the arrival display using a string visit type */
  registerGuestWithHCPFromArrivalDisplayWithCustomVisitType?: Maybe<Scalars['Void']>;
  /**
   * register a guest in a location that doesn't require HCP from the arrival display
   * @deprecated Use registerGuestWithoutHCPFromArrivalDisplayWithCustomVisitType
   */
  registerGuestWithoutHCPFromArrivalDisplay?: Maybe<Scalars['Void']>;
  /** register a guest in a location that doesn't require HCP from the arrival display using a string visit type */
  registerGuestWithoutHCPFromArrivalDisplayWithCustomVisitType?: Maybe<Scalars['Void']>;
  /** Unregister and de-authenticate an arrival display. */
  removeArrivalDisplay?: Maybe<Scalars['Void']>;
  /** Removes messages by ID from a chat on activity */
  removeChatMessagesFromActivity?: Maybe<Scalars['Void']>;
  removeCustomWorkingHours?: Maybe<Scalars['Void']>;
  removeOfficeAdmin?: Maybe<Scalars['Void']>;
  removePinnedUserSpaces?: Maybe<Scalars['Void']>;
  removeUserFromFavorites?: Maybe<Scalars['Void']>;
  removeUserFromRole?: Maybe<Scalars['Void']>;
  /** Extends the expiration period on an expired, unused arrival display registration. */
  renewArrivalDisplayRegistration?: Maybe<Scalars['Void']>;
  /** Report an issue with a desk - chair is broken, monitor is busted, etc */
  reportIssueWithDesk: ReportIssueResponse;
  /** Report an issue with a space - TV isn't working, table is wobbly, etc */
  reportIssueWithSpace: ReportIssueResponse;
  /**
   * Tells us the device's current IP address.
   * From this, we can infer that the user is present with their device at a building
   * associated with that IP address, and perform side effects like checking them in
   * to their reservations at that building.
   * Don't bother calling this if device is on a cellular data connection or a VPN.
   */
  reportUserPresenceAtIPAddress: ReportUserPresenceAtIpAddressResponse;
  /** Creates a new hot/hoteled desk reservationfor a given user on your behalf. */
  reserveDelegatedDesk: Reservation;
  /** Creates a new hot/hoteled desk reservation for the current user. */
  reserveDeskForMe: Reservation;
  /** Reserve a space */
  reserveSpace: ReserveSpaceResponse;
  /** Save a new survey configuration */
  saveSurveyConfiguration?: Maybe<Survey>;
  scaffoldDemoFloorPlan?: Maybe<Scalars['Void']>;
  /** Create a new employee visit */
  scheduleEmployeeVisitAtLocation?: Maybe<Scalars['Void']>;
  /**
   * Add a chat message to an activity
   * @deprecated Deprecated in favor of  `sendChatOnActivityWithId`
   */
  sendChatOnActivity?: Maybe<Scalars['Void']>;
  /** Add a chat message to an activity with client specified UUID identity */
  sendChatOnActivityWithId?: Maybe<Scalars['Void']>;
  /**
   * Requires places:manage permission for the given building.
   * Sets public CIDRs associated with the given building, for use with passive check-in.
   * Idempotent.  Number of CIDRs, size of subnet are both limited.
   * Rejects CIDRs that might technically be valid but are likely user error: "1", "1.2", etc.
   * Tries to reject bogons and martians: https://en.wikipedia.org/wiki/Bogon_filtering
   */
  setBuildingCidrs: SetBuildingCidrsResponse;
  setDefaultLocationForUser?: Maybe<Scalars['Void']>;
  /**
   * Sets sentAt field for distribution and resolves sendResult to SUCCESS for
   * all provided recipient ids. An error will be thrown if client tries to
   * set send data of a distribution or recipient that has already been set.
   */
  setDistributionSendData?: Maybe<Scalars['Void']>;
  setEmployeeVisitsEnabledForOrg?: Maybe<Scalars['Void']>;
  setGoogleWorkingLocationSyncSettingForOrg?: Maybe<Scalars['Void']>;
  /** Sets the sync google working locations setting user */
  setGoogleWorkingLocationSyncSettingForUser?: Maybe<Scalars['Void']>;
  /** Sets the homepage preference for current (user) actor. */
  setMyHomepage?: Maybe<Scalars['Void']>;
  /** Sets a languge tag for current (user) actor */
  setMyLanguageTag?: Maybe<Scalars['Void']>;
  /** Update the given notification preference for the current user */
  setNotificationPreferenceForCurrentUser?: Maybe<Scalars['Void']>;
  setOrganization14Day100UsersTrial?: Maybe<Scalars['Void']>;
  /**
   * Updates startAt date for recurring distribution announcements
   * and keeps the sentAt field set to null. Sent the startAt date as a
   * ISODateTime
   */
  setRecurringAnnouncementData?: Maybe<Scalars['Void']>;
  /** Sets the show weekends setting for current (user) actor */
  setShowWeekendsForMe?: Maybe<Scalars['Void']>;
  setTeamMembershipByName?: Maybe<Scalars['Void']>;
  /** Sets a user notification setting. */
  setUserNotificationSetting?: Maybe<Scalars['Void']>;
  /** Temporary migration query which generates and returns the client secret for existing arrival displays */
  tempArrivalDisplayAuthMigration: ArrivalDisplayRegistrationResponse;
  unassignSticker?: Maybe<Scalars['Void']>;
  unjoinActivity?: Maybe<Scalars['Void']>;
  /**
   * This mutation understands the input as the state of the activity,
   * therefore it will update every field according to the input
   */
  updateActivity?: Maybe<Scalars['Void']>;
  /**
   * Update a single (ACTIVE or SCHEDULED) announcement.
   * Author cannot change.
   */
  updateAnnouncement?: Maybe<Scalars['Void']>;
  /** From the Dashboard, users can edit arrival displays they've already created. */
  updateArrivalDisplay?: Maybe<Scalars['Void']>;
  updateCustomWorkingHours?: Maybe<Scalars['Void']>;
  /** Updates the default pass setting */
  updateDefaultPass?: Maybe<Scalars['Void']>;
  updateDistributionsByAnnouncementId?: Maybe<Scalars['Void']>;
  /** Updates the desk, start time, and end time for the current user's non-recurring desk reservation. */
  updateMyReservationDeskAndTimeFrame: UpdateDeskReservationResponse;
  /** @deprecated updatePass is deprecated. Use updatePassForPlaces instead. */
  updatePass?: Maybe<Scalars['Void']>;
  /** Updates a pass for one or more places */
  updatePassForPlaces?: Maybe<Scalars['Void']>;
  /** Updates the visibility of a desk reservation. */
  updateReservationVisibility?: Maybe<Scalars['Void']>;
  updateStandardWorkingHoursForLocation?: Maybe<Scalars['Void']>;
  /** Update an existing survey configuration */
  updateSurveyConfiguration?: Maybe<Survey>;
  updateWorkAreas?: Maybe<Scalars['TODO']>;
  updateWorkPolicy?: Maybe<Scalars['Void']>;
  /** Store data that user has viewed an announcement at the given location. */
  viewAnnouncement?: Maybe<Scalars['Void']>;
  viewAnnouncementForMultipleLocations?: Maybe<Scalars['Void']>;
  /** Add a new document task to visit workflows for the specified visit types and locations */
  visitAddDocumentTask?: Maybe<Scalars['Void']>;
  /** Archive an existing document. The specified document is removed from all live workflows. */
  visitArchiveDocument?: Maybe<Scalars['Void']>;
  /** Update the status of an async visit request. This mutation is only accessible using an internal service auth token. */
  visitAsyncRequestStatusReport?: Maybe<Scalars['Void']>;
  /**
   * Update a document task to apply to a new visit workflow for the specified visit types and locations
   * @deprecated Use visitChangeDocumentTaskAssociationsWithCustomVisitType instead
   */
  visitChangeDocumentTaskAssociations?: Maybe<Scalars['Void']>;
  /** Update a document task to apply to a new visit workflow for the specified visit types and locations */
  visitChangeDocumentTaskAssociationsWithCustomVisitType?: Maybe<Scalars['Void']>;
};


export type MutationAcknowledgeAnnouncementArgs = {
  announcementId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type MutationAcknowledgeAnnouncementForMultipleLocationsArgs = {
  announcementId: Scalars['ID'];
  locationIds: Array<Scalars['ID']>;
};


export type MutationAcknowledgeVisitEventHandledArgs = {
  eventType: VisitEventAcknowledgement;
  visitId: Scalars['ID'];
};


export type MutationAddCustomWorkingHoursArgs = {
  customWorkingHours: CustomWorkingHoursInput;
};


export type MutationAddLevelToLocationArgs = {
  locationId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationAddLevelsToLocationArgs = {
  locationId: Scalars['ID'];
  names: Array<Scalars['String']>;
};


export type MutationAddLocationToOrganizationArgs = {
  address: Scalars['String'];
  name: Scalars['String'];
  timeZone: Scalars['String'];
};


export type MutationAddOfficeAdminArgs = {
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddOrganizationArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
};


export type MutationAddPinnedUserSpacesArgs = {
  locationId: Scalars['String'];
  spaceIds: Array<Scalars['String']>;
};


export type MutationAddSpaceToEventArgs = {
  request?: InputMaybe<AddSpaceToEventRequest>;
};


export type MutationAddUserToFavoritesArgs = {
  userId: Scalars['String'];
};


export type MutationAddUserToRoleArgs = {
  roleSlug: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationAddVisitTypeForOrgArgs = {
  visitTypeName: Scalars['String'];
};


export type MutationAdministratorRegisterGuestArgs = {
  arrivalInstructions?: InputMaybe<Scalars['String']>;
  arrivalLocationId: Scalars['ID'];
  guests: Array<GuestInputType>;
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitType: Scalars['String'];
};


export type MutationAllocateStickersToOrgArgs = {
  input: AllocateStickersToOrgRequest;
};


export type MutationAllocateStickersToOrgByDisplayCodeArgs = {
  input: AllocateStickersToOrgByDisplayCodeRequest;
};


export type MutationArchiveAnnouncementArgs = {
  announcementId: Scalars['ID'];
};


export type MutationArchiveAnnouncementForSlackArgs = {
  announcementId: Scalars['ID'];
};


export type MutationArchiveIntegrationByIdArgs = {
  id: Scalars['ID'];
};


export type MutationAssertGuestIdentityArgs = {
  guestEmail: Scalars['String'];
  guestName: Scalars['String'];
};


export type MutationAssignDelegatedDeskArgs = {
  email: Scalars['String'];
  notify?: InputMaybe<Scalars['Boolean']>;
  seatId: Scalars['Int'];
  start: DateWithTimezone;
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationAssignDeskToMeArgs = {
  notify?: InputMaybe<Scalars['Boolean']>;
  seatId: Scalars['Int'];
  start: DateWithTimezone;
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationAssignStickerToDeskByShortCodeArgs = {
  input: AssignStickerToDeskByShortCodeRequest;
};


export type MutationAssignStickersArgs = {
  input: AssignStickerRequest;
};


export type MutationAssociateHealthCheckpointWithVisitArgs = {
  guestInviteId: Scalars['ID'];
  healthCheckpointId: Scalars['ID'];
};


export type MutationAuthenticateVisitorWithMagicLinkArgs = {
  magicLink: Scalars['String'];
};


export type MutationAutoAllocateStickersToMultipleOrgsArgs = {
  input: Array<AutoAllocateStickersToOrgRequest>;
};


export type MutationAutoAllocateStickersToOrgArgs = {
  input: AutoAllocateStickersToOrgRequest;
};


export type MutationBulkArchiveAnnouncementsArgs = {
  announcementIds: Array<Scalars['ID']>;
};


export type MutationBulkUpdateCustomWorkingHoursArgs = {
  customWorkingHoursUpdates: Array<CustomWorkingHoursBulkUpdateInput>;
  locationId: Scalars['ID'];
};


export type MutationCancelActivityArgs = {
  activityId: Scalars['ID'];
};


export type MutationCancelEmployeeVisitArgs = {
  employeeVisitId: Scalars['ID'];
};


export type MutationCancelVisitArgs = {
  id: Scalars['ID'];
  reason?: InputMaybe<GuestInviteCanceledReason>;
};


export type MutationCheckIntoSelfRegisteredVisitFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID'];
};


export type MutationCheckIntoVisitArgs = {
  magicLink: Scalars['String'];
};


export type MutationCheckIntoVisitFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID'];
};


export type MutationCheckIntoVisitRequiringHcpFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID'];
};


export type MutationCheckIntoVisitWithMagicLinkArgs = {
  magicLink: Scalars['String'];
};


export type MutationCheckOutGuestFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID'];
};


export type MutationCheckOutGuestFromDashboardArgs = {
  guestInviteId: Scalars['ID'];
};


export type MutationCompleteSurveyResponseArgs = {
  comment?: InputMaybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  rating: Scalars['Int'];
  reservationId?: InputMaybe<Scalars['String']>;
  surveyResponseId?: InputMaybe<Scalars['ID']>;
};


export type MutationConfirmDeskReservationArgs = {
  reservationId: Scalars['ID'];
  type: DeskConfirmationType;
};


export type MutationConfirmDeskReservationDelegatedArgs = {
  reservationId: Scalars['ID'];
  type: DeskConfirmationType;
  userId: Scalars['ID'];
};


export type MutationConnectRipplingAccountForOrganizationArgs = {
  input: ConnectRipplingAccountForOrganizationInput;
};


export type MutationCreateActivityArgs = {
  input: CreateActivityInput;
};


export type MutationCreateAndMapBuildingsFromRipplingArgs = {
  input: CreateAndMapBuildingsFromRipplingInput;
};


export type MutationCreateAnnouncementArgs = {
  announcement: AnnouncementCreateInput;
};


export type MutationCreateAnnouncementForSlackArgs = {
  announcement: AnnouncementCreateInput;
};


export type MutationCreateArrivalDisplayArgs = {
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  name: Scalars['String'];
  selfRegistrationEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateGenericHealthCheckpointArgs = {
  entityId: Scalars['ID'];
  entityType: GenericHealthCheckpointEntityType;
  id?: InputMaybe<Scalars['ID']>;
  locationId: Scalars['ID'];
};


export type MutationCreateIntegrationArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  externalId: Scalars['String'];
  integrationJson?: InputMaybe<Scalars['Json']>;
  name: Scalars['String'];
  tenantId: Scalars['ID'];
};


export type MutationCreateOfficeAdminRoleArgs = {
  locationId: Scalars['ID'];
};


export type MutationCreatePassArgs = {
  input?: InputMaybe<PassInput>;
};


export type MutationCreatePassForPlacesArgs = {
  input?: InputMaybe<MultiPlacePassInput>;
};


export type MutationCreateRobinOrganizationFromRipplingOAuthDataArgs = {
  input: CreateRobinOrganizationFromRipplingOAuthDataInput;
};


export type MutationCreateRoleArgs = {
  input: RoleCreationInput;
};


export type MutationCreateSigmaPersonalWorkbookArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateVisitArgs = {
  guests: Array<GuestInputType>;
  organizerId: Scalars['ID'];
  organizerType: VisitOrganizerType;
  visitDetails: VisitWithIdInputType;
};


export type MutationCreateVisitWithCustomVisitTypeArgs = {
  arrivalLocationId: Scalars['ID'];
  guests: Array<GuestInputType>;
  hostUserId: Scalars['ID'];
  id: Scalars['ID'];
  organizerId: Scalars['ID'];
  organizerType: VisitOrganizerType;
  startTime: Scalars['ISODateTime'];
  visitType: Scalars['String'];
};


export type MutationCreateWorkPolicyArgs = {
  workPolicy: WorkPolicyInput;
};


export type MutationDeactivateVisitTypeForOrgArgs = {
  visitTypeName: Scalars['String'];
};


export type MutationDecideOutcomeForVisitDocumentArgs = {
  guestId: Scalars['ID'];
  outcomeId: Scalars['ID'];
  visitId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  workflowId: Scalars['ID'];
};


export type MutationDecideOutcomeForVisitDocumentFromArrivalDisplayArgs = {
  guestId: Scalars['ID'];
  outcomeId: Scalars['ID'];
  sendCopyToGuest?: InputMaybe<Scalars['Boolean']>;
  visitId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  workflowId: Scalars['ID'];
};


export type MutationDecideOutcomeForVisitDocumentFromGuestUiArgs = {
  guestId: Scalars['ID'];
  outcomeId: Scalars['ID'];
  sendCopyToGuest?: InputMaybe<Scalars['Boolean']>;
  visitId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  workflowId: Scalars['ID'];
};


export type MutationDeclineAndEndReservationArgs = {
  reservationId: Scalars['ID'];
};


export type MutationDeletePassArgs = {
  passId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteWorkAreaDesksArgs = {
  deskIds: Array<Scalars['ID']>;
};


export type MutationDisableOrganizationUserArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDisablePassiveCheckInFeatureForBuildingArgs = {
  input: SetPassiveCheckInFeatureForBuildingInput;
};


export type MutationDismissSurveyResponseArgs = {
  reservationId?: InputMaybe<Scalars['String']>;
  surveyResponseId?: InputMaybe<Scalars['ID']>;
};


export type MutationEnableOrganizationUserArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationEnablePassiveCheckInFeatureForBuildingArgs = {
  input: SetPassiveCheckInFeatureForBuildingInput;
};


export type MutationEndOrCancelDelegatedReservationArgs = {
  notifyAssignee?: InputMaybe<Scalars['Boolean']>;
  reservationId: Scalars['ID'];
};


export type MutationEndOrCancelReservationArgs = {
  reservationId: Scalars['ID'];
};


export type MutationGenerateStickerBatchArgs = {
  input: GenerateStickerBatchRequest;
};


export type MutationGuestHealthCheckpointFailedArgs = {
  healthCheckpointResponseId: Scalars['ID'];
};


export type MutationGuestInvitedArgs = {
  magicLink: Scalars['String'];
};


export type MutationInitiateFullRipplingUserSyncArgs = {
  input: InitiateFullRipplingUserSyncInput;
};


export type MutationInitiateRipplingBuildingSyncArgs = {
  input: InitiateRipplingBuildingSyncInput;
};


export type MutationInitiateRipplingConnectionAndSyncArgs = {
  input: InitiateRipplingConnectionAndSyncInput;
};


export type MutationInviteOfficeAdminUsersArgs = {
  emailAddresses: Array<Scalars['String']>;
};


export type MutationJoinActivityArgs = {
  activityId: Scalars['ID'];
};


export type MutationJoinOfficeArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  type: DeskConfirmationType;
  userId: Scalars['ID'];
};


export type MutationLogSpaceSuggestionBookedArgs = {
  input: LogSuggestedSpaceBookedInput;
};


export type MutationLogSpaceSuggestionDismissedArgs = {
  input: LogSuggestedSpaceDismissedInput;
};


export type MutationLogSpaceSuggestionViewedArgs = {
  input: LogSuggestedSpaceViewedInput;
};


export type MutationMapBuildingToRipplingWorkLocationArgs = {
  input: MapBuildingToRipplingWorkLocationInput;
};


export type MutationMapBuildingsToRipplingWorkLocationsArgs = {
  input: MapBuildingsToRipplingWorkLocationsInput;
};


export type MutationRefreshDeviceAuthorizationArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRefreshJwtAuthTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRegisterArrivalDisplayArgs = {
  key: Scalars['ArrivalDisplayRegistrationKey'];
  organizationId: Scalars['ID'];
};


export type MutationRegisterGuestFromArrivalDisplayArgs = {
  arrivalLocationId: Scalars['ID'];
  guestEmail: Scalars['String'];
  guestName: Scalars['String'];
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitId?: InputMaybe<Scalars['ID']>;
  visitInviteId?: InputMaybe<Scalars['ID']>;
  visitType: VisitType;
};


export type MutationRegisterGuestFromDashboardArgs = {
  guests: Array<GuestInputType>;
  visitDetails: VisitInputType;
};


export type MutationRegisterGuestWithHcpFromArrivalDisplayArgs = {
  arrivalLocationId: Scalars['ID'];
  guestEmail: Scalars['String'];
  guestInviteId: Scalars['ID'];
  guestName: Scalars['String'];
  healthCheckpointId: Scalars['ID'];
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitId: Scalars['ID'];
  visitType: VisitType;
};


export type MutationRegisterGuestWithHcpFromArrivalDisplayWithCustomVisitTypeArgs = {
  arrivalLocationId: Scalars['ID'];
  guestEmail: Scalars['String'];
  guestInviteId: Scalars['ID'];
  guestName: Scalars['String'];
  healthCheckpointId: Scalars['ID'];
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitId: Scalars['ID'];
  visitType: Scalars['String'];
};


export type MutationRegisterGuestWithoutHcpFromArrivalDisplayArgs = {
  arrivalLocationId: Scalars['ID'];
  guestEmail: Scalars['String'];
  guestName: Scalars['String'];
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  visitType: VisitType;
};


export type MutationRegisterGuestWithoutHcpFromArrivalDisplayWithCustomVisitTypeArgs = {
  arrivalLocationId: Scalars['ID'];
  guestEmail: Scalars['String'];
  guestName: Scalars['String'];
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  visitType: Scalars['String'];
};


export type MutationRemoveArrivalDisplayArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveChatMessagesFromActivityArgs = {
  chatMessageIdentifiers: Array<Scalars['ID']>;
};


export type MutationRemoveCustomWorkingHoursArgs = {
  customWorkingHoursId: Scalars['ID'];
};


export type MutationRemoveOfficeAdminArgs = {
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemovePinnedUserSpacesArgs = {
  spaceIds: Array<Scalars['String']>;
};


export type MutationRemoveUserFromFavoritesArgs = {
  userId: Scalars['String'];
};


export type MutationRemoveUserFromRoleArgs = {
  roleSlug: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationRenewArrivalDisplayRegistrationArgs = {
  key: Scalars['ArrivalDisplayRegistrationKey'];
};


export type MutationReportIssueWithDeskArgs = {
  input: ReportIssueWithDeskInput;
};


export type MutationReportIssueWithSpaceArgs = {
  input: ReportIssueWithSpaceInput;
};


export type MutationReportUserPresenceAtIpAddressArgs = {
  input: ReportUserPresenceAtIpAddressInput;
};


export type MutationReserveDelegatedDeskArgs = {
  email: Scalars['String'];
  end: DateWithTimezone;
  notify?: InputMaybe<Scalars['Boolean']>;
  recurrence?: InputMaybe<Array<Scalars['String']>>;
  seatId: Scalars['Int'];
  start: DateWithTimezone;
  type: Scalars['String'];
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationReserveDeskForMeArgs = {
  end: DateWithTimezone;
  notify?: InputMaybe<Scalars['Boolean']>;
  recurrence?: InputMaybe<Array<Scalars['String']>>;
  seatId: Scalars['Int'];
  start: DateWithTimezone;
  type: Scalars['String'];
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationReserveSpaceArgs = {
  request: ReserveSpaceRequest;
};


export type MutationSaveSurveyConfigurationArgs = {
  allowAnonymous: Scalars['Boolean'];
  buildingIds: Array<Scalars['String']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};


export type MutationScaffoldDemoFloorPlanArgs = {
  locationId: Scalars['ID'];
};


export type MutationScheduleEmployeeVisitAtLocationArgs = {
  employeeId: Scalars['ID'];
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
};


export type MutationSendChatOnActivityArgs = {
  activityID: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationSendChatOnActivityWithIdArgs = {
  activityID: Scalars['ID'];
  message: Scalars['String'];
  messageID: Scalars['ID'];
};


export type MutationSetBuildingCidrsArgs = {
  input: SetBuildingCidrsInput;
};


export type MutationSetDefaultLocationForUserArgs = {
  locationId?: InputMaybe<Scalars['Int']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type MutationSetDistributionSendDataArgs = {
  distributionId: Scalars['ID'];
  recipientIds: Array<Scalars['ID']>;
};


export type MutationSetEmployeeVisitsEnabledForOrgArgs = {
  organizationId: Scalars['ID'];
  value?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetGoogleWorkingLocationSyncSettingForOrgArgs = {
  organizationId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationSetGoogleWorkingLocationSyncSettingForUserArgs = {
  organizationId: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationSetMyHomepageArgs = {
  input: SetMyHomepageInput;
};


export type MutationSetMyLanguageTagArgs = {
  input: SetMyLanguageTagInput;
};


export type MutationSetNotificationPreferenceForCurrentUserArgs = {
  isEnabled: Scalars['Boolean'];
  preferenceKey?: InputMaybe<NotificationPreferenceKey>;
};


export type MutationSetRecurringAnnouncementDataArgs = {
  announcementId: Scalars['ID'];
  startAt: Scalars['String'];
};


export type MutationSetShowWeekendsForMeArgs = {
  input: SetShowWeekendsForMeInput;
};


export type MutationSetTeamMembershipByNameArgs = {
  teamMemberUserIds: Array<Scalars['String']>;
  teamName: Scalars['String'];
};


export type MutationSetUserNotificationSettingArgs = {
  input: SetUserNotificationSettingInput;
};


export type MutationUnassignStickerArgs = {
  input: UnassignStickerRequest;
};


export type MutationUnjoinActivityArgs = {
  activityId: Scalars['ID'];
};


export type MutationUpdateActivityArgs = {
  activityId: Scalars['ID'];
  input: UpdateActivityInput;
};


export type MutationUpdateAnnouncementArgs = {
  announcementId: Scalars['ID'];
  content: AnnouncementUpdateInput;
};


export type MutationUpdateArrivalDisplayArgs = {
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  name: Scalars['String'];
  selfRegistrationEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCustomWorkingHoursArgs = {
  customWorkingHoursId: Scalars['ID'];
  customWorkingHoursUpdate: CustomWorkingHoursUpdateInput;
};


export type MutationUpdateDefaultPassArgs = {
  input: UpdateDefaultPassInput;
};


export type MutationUpdateDistributionsByAnnouncementIdArgs = {
  announcementId: Scalars['ID'];
  distributions: Array<DistributionInput>;
};


export type MutationUpdateMyReservationDeskAndTimeFrameArgs = {
  input: UpdateMyReservationDeskAndTimeFrameInput;
};


export type MutationUpdatePassArgs = {
  input?: InputMaybe<PassInput>;
};


export type MutationUpdatePassForPlacesArgs = {
  input?: InputMaybe<MultiPlacePassInput>;
};


export type MutationUpdateReservationVisibilityArgs = {
  reservationId: Scalars['ID'];
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationUpdateStandardWorkingHoursForLocationArgs = {
  locationId: Scalars['ID'];
  workingHours: Array<WorkingHoursInput>;
};


export type MutationUpdateSurveyConfigurationArgs = {
  allowAnonymous?: InputMaybe<Scalars['Boolean']>;
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  surveyId: Scalars['ID'];
};


export type MutationUpdateWorkAreasArgs = {
  input: UpdateWorkAreasInput;
  levelId: Scalars['ID'];
};


export type MutationUpdateWorkPolicyArgs = {
  workPolicy: WorkPolicyInput;
  workPolicyId: Scalars['String'];
};


export type MutationViewAnnouncementArgs = {
  announcementId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type MutationViewAnnouncementForMultipleLocationsArgs = {
  announcementId: Scalars['ID'];
  locationIds: Array<Scalars['ID']>;
};


export type MutationVisitAddDocumentTaskArgs = {
  documentBody: Scalars['DocumentBody'];
  documentName: Scalars['String'];
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  notificationEmails?: InputMaybe<Array<Scalars['WorkflowEmail']>>;
  visitTypes?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationVisitArchiveDocumentArgs = {
  documentId: Scalars['ID'];
};


export type MutationVisitAsyncRequestStatusReportArgs = {
  errorText?: InputMaybe<Array<Scalars['String']>>;
  status: VisitAsyncRequestReport;
  visitId: Scalars['ID'];
};


export type MutationVisitChangeDocumentTaskAssociationsArgs = {
  documentId: Scalars['ID'];
  locationIds: Array<Scalars['ID']>;
  notificationEmails: Array<Scalars['WorkflowEmail']>;
  visitTypes: Array<Scalars['String']>;
};


export type MutationVisitChangeDocumentTaskAssociationsWithCustomVisitTypeArgs = {
  documentId: Scalars['ID'];
  locationIds: Array<Scalars['ID']>;
  notificationEmails: Array<Scalars['WorkflowEmail']>;
  visitTypes: Array<Scalars['String']>;
};

export type MyHomepageResponse = {
  __typename?: 'MyHomepageResponse';
  homepage: HomepageType;
};

export type MyLanguageTagResponse = {
  __typename?: 'MyLanguageTagResponse';
  languageTag?: Maybe<Scalars['String']>;
};

export type MyShowWeekendsResponse = {
  __typename?: 'MyShowWeekendsResponse';
  showWeekends: Scalars['Boolean'];
};

export type NextAvailableMeta = {
  __typename?: 'NextAvailableMeta';
  end?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
};

export enum NotificationPreferenceKey {
  WhosInDigestEmail = 'WHOS_IN_DIGEST_EMAIL'
}

export type NotificationPreferences = {
  __typename?: 'NotificationPreferences';
  isSubscribedToWhosInDigestEmail: Scalars['Boolean'];
};

export enum NotificationSettingType {
  Email = 'EMAIL',
  Push = 'PUSH',
  Slack = 'SLACK',
  Teams = 'TEAMS'
}

export type OfficeAccess = {
  __typename?: 'OfficeAccess';
  /** @deprecated Deprecated. Please use `resolvedPlaces`. */
  campus?: Maybe<Campus>;
  groups: Array<Group>;
  /** @deprecated Guidelines are now policies and they're disassociated from access passes */
  guideline?: Maybe<Guideline>;
  healthCheckpointRequired: Scalars['Boolean'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  /** @deprecated Deprecated. Please use `resolvedPlaces`. */
  location?: Maybe<Location>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated. Please use `resolvedPlaces`. */
  organization?: Maybe<Organization>;
  permissions: OfficeAccessPermissions;
  /** @deprecated Deprecated in favor of places */
  place?: Maybe<AccessPlace>;
  places?: Maybe<Array<Maybe<AccessPlace>>>;
  resolvedPlaces?: Maybe<Array<Maybe<AccessPassResolvedPlaces>>>;
  schedule: Array<Scalars['String']>;
  /** @deprecated Deprecated in favor of id */
  slug?: Maybe<Scalars['String']>;
  theme: Theme;
  users: Array<User>;
};


export type OfficeAccessGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type OfficeAccessPermissions = {
  __typename?: 'OfficeAccessPermissions';
  canManage: Scalars['Boolean'];
  canView: Scalars['Boolean'];
};

export enum OperatingSystem {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Organization = {
  __typename?: 'Organization';
  /** Total desks on the floor - excluding inactive. */
  activeDeskCount?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['String']>;
  campuses: Array<Campus>;
  createdAt?: Maybe<Scalars['Date']>;
  /**
   * Total number of booked desks within the organization
   * @deprecated Deprecated in favor of deskSchedulingAggregates
   */
  deskBookedTotal: Scalars['Int'];
  /** Total desks within the organization that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  /**
   * Total number of unavailable desks within the organization
   * @deprecated Deprecated in favor of deskSchedulingAggregates
   */
  deskUnavailableTotal: Scalars['Int'];
  features?: Maybe<OrganizationFeatures>;
  id: Scalars['ID'];
  locations: Array<Location>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type OrganizationExternalAccount = {
  __typename?: 'OrganizationExternalAccount';
  accessToken?: Maybe<Scalars['String']>;
  accountId: Scalars['ID'];
  domain?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OrganizationFeatureLimits = {
  __typename?: 'OrganizationFeatureLimits';
  activatedUsers: MeteredFeature;
  activeUsers: MeteredFeature;
  bookableSeats: MeteredFeature;
  bookableSpaces: MeteredFeature;
  campuses: MeteredFeature;
  exportableDataDays: MeteredFeature;
  invitedUsers: MeteredFeature;
  levels: MeteredFeature;
  locations: MeteredFeature;
  viewableDataDays: MeteredFeature;
};

export type OrganizationFeatures = {
  __typename?: 'OrganizationFeatures';
  adminOnlyBooking: Scalars['Boolean'];
  amenities: Scalars['Boolean'];
  announcements: Scalars['Boolean'];
  billingUIV1: Scalars['Boolean'];
  /** @deprecated Use `limits` instead */
  bookableSeatLimit?: Maybe<Scalars['Int']>;
  /** @deprecated Use `limits` instead */
  bookableSpaceLimit?: Maybe<Scalars['Int']>;
  campuses: Scalars['Boolean'];
  customLogo: Scalars['Boolean'];
  customRoles: Scalars['Boolean'];
  customThemes: Scalars['Boolean'];
  documentAgreements: Scalars['Boolean'];
  /** @deprecated Use `limits` instead */
  exportableDataLimitDays?: Maybe<Scalars['Int']>;
  hotSeatReservations: Scalars['Boolean'];
  hoteledSeatReservations: Scalars['Boolean'];
  insights: Scalars['Boolean'];
  insightsExportsApi: Scalars['Boolean'];
  issueReporting: Scalars['Boolean'];
  levels: Scalars['Boolean'];
  limits: OrganizationFeatureLimits;
  maps: Scalars['Boolean'];
  meetingApprovals: Scalars['Boolean'];
  meetingServices: Scalars['Boolean'];
  meridianMapping: Scalars['Boolean'];
  noPersonalCalendars: Scalars['Boolean'];
  overProvisioning: Scalars['Boolean'];
  peopleDirectory: Scalars['Boolean'];
  premierSupport: Scalars['Boolean'];
  reverseHoteling: Scalars['Boolean'];
  ripplingIntegration: Scalars['Boolean'];
  samlAuthentication: Scalars['Boolean'];
  scim: Scalars['Boolean'];
  statusBoards: Scalars['Boolean'];
  /** @deprecated Use `limits` instead */
  userLimit?: Maybe<Scalars['Int']>;
  /** @deprecated Use `limits` instead */
  viewableDataLimitDays?: Maybe<Scalars['Int']>;
  visitorManagement: Scalars['Boolean'];
  workweek: Scalars['Boolean'];
};

export type OrganizationIdHashResponse = {
  __typename?: 'OrganizationIdHashResponse';
  hash?: Maybe<Scalars['String']>;
};

export type OrganizationLocation = {
  __typename?: 'OrganizationLocation';
  locationId?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
};

export enum OwnerType {
  Seat = 'SEAT',
  Space = 'SPACE',
  Unassigned = 'UNASSIGNED'
}

export type PagedUserFilterInput = {
  favoriteFilter?: InputMaybe<Scalars['Boolean']>;
  hasActivity?: InputMaybe<Scalars['Boolean']>;
  preferredLocationFilter?: InputMaybe<Scalars['ID']>;
  userNameFilter?: InputMaybe<Scalars['String']>;
};

export type PagedUserResult = {
  __typename?: 'PagedUserResult';
  accountUsers: Array<AccountUser>;
  accountUsersTotal: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  nextPageOffset: Scalars['Int'];
  users: Array<User>;
};

export type PagedUserResultV2 = {
  __typename?: 'PagedUserResultV2';
  accountUsers: Array<AccountUser>;
  accountUsersTotal: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  nextPageOffset: Scalars['Int'];
};

export enum ParticipationStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  NotResponded = 'NOT_RESPONDED',
  Tentative = 'TENTATIVE',
  Unknown = 'UNKNOWN'
}

export type PassInput = {
  groups: Array<Scalars['ID']>;
  healthCheckpointRequired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  place: PassPlaceInput;
  schedule: Array<Scalars['String']>;
  userChanges?: InputMaybe<PassUsersInput>;
};

export type PassPlaceInput = {
  id: Scalars['ID'];
  type: AccessPlaceType;
};

export type PassUsersInput = {
  added?: InputMaybe<Array<Scalars['ID']>>;
  removed?: InputMaybe<Array<Scalars['ID']>>;
};

export type Permission = {
  __typename?: 'Permission';
  name: Scalars['String'];
  value: Scalars['Boolean'];
};

export type PinnedUserSpaceEntity = {
  __typename?: 'PinnedUserSpaceEntity';
  locationId: Scalars['String'];
  spaceIds: Array<Scalars['String']>;
};

export type PlaceAvailability = {
  __typename?: 'PlaceAvailability';
  status: PlaceAvailabilityType;
  unavailableReasons: Array<PlaceUnavailableReason>;
};

export enum PlaceAvailabilityType {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

export enum PlaceType {
  Location = 'location'
}

export enum PlaceUnavailableReason {
  /** The location is closed according to its working hours */
  BuildingClosed = 'BUILDING_CLOSED',
  /** The maxiumum number of flexible desks have been booked in this location */
  MaxCapacityReached = 'MAX_CAPACITY_REACHED',
  /** The user doesn't have a pass */
  NoAccess = 'NO_ACCESS',
  /** The user has a pass but it doesn't grant access to the location */
  NoLocationAccess = 'NO_LOCATION_ACCESS',
  /** The user has a pass to the location, but it's not valid during the specified start and end times */
  ScheduleViolation = 'SCHEDULE_VIOLATION'
}

export type Policy = {
  __typename?: 'Policy';
  is_allowed: Scalars['Boolean'];
  object_id: Scalars['String'];
  object_type: Scalars['String'];
  permission_operation: Scalars['String'];
};

export type PublicArrivalDisplay = {
  __typename?: 'PublicArrivalDisplay';
  id: Scalars['ID'];
  location: PublicLocationOrDefault;
  name: Scalars['String'];
  organization: PublicOrganization;
};

export type PublicGuest = {
  __typename?: 'PublicGuest';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PublicLocationOrDefault = {
  __typename?: 'PublicLocationOrDefault';
  address?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isHealthCheckpointRequired: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  timezone?: Maybe<Scalars['String']>;
};

export type PublicOrganization = {
  __typename?: 'PublicOrganization';
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type PublicUserWithDefault = {
  __typename?: 'PublicUserWithDefault';
  avatar?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type PublicUserWithEmail = {
  __typename?: 'PublicUserWithEmail';
  avatar?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/**
 * PublicVisitResult represents the visit for a single GuestInvite.
 * The result is "public" because it is available to the public via Arrival Display kiosk devices.
 *
 * A PublicVisitResult is the guest (GuestInvite) along with information about their associated Visit.
 */
export type PublicVisitResult = {
  __typename?: 'PublicVisitResult';
  arrivalInstructions?: Maybe<Scalars['String']>;
  arrivalLocation: PublicLocationOrDefault;
  arrivalLocationId: Scalars['String'];
  checkinOn?: Maybe<Scalars['String']>;
  checkoutOn?: Maybe<Scalars['String']>;
  customVisitType: Scalars['String'];
  guest: PublicGuest;
  healthCheckpointSurveyResponse?: Maybe<GenericHealthCheckpointResponse>;
  host: PublicUserWithEmail;
  /** The ID of the GuestInvite */
  id: Scalars['ID'];
  organization: PublicOrganization;
  startTime: Scalars['ISODateTime'];
  status: GuestInviteStatus;
  /** The ID of the associated visit */
  visitId: Scalars['ID'];
  /** @deprecated replaced by the more flexible customVisitType */
  visitType: VisitType;
};

export enum Query_Visit_Document_Sort_By {
  AddedOn = 'ADDED_ON',
  Name = 'NAME',
  Status = 'STATUS'
}

export enum Query_Workflow_Sort_Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type Query = {
  __typename?: 'Query';
  /**
   * Allows arrival displays to self-rescue. If you know, you know.
   * Vague by design.
   */
  arrivalDisplaySelfRescue: Array<Scalars['String']>;
  checkOrganizationNameAvailability?: Maybe<CheckOrganizationNameAvailabilityResponse>;
  /** Total count of filtered visit documents */
  countVisitFilteredDocuments: Scalars['Int'];
  /** Tells you if the current user is allowed to report issues on a desk */
  deskAllowsIssueReports: ResourceAllowsIssueReportsResponse;
  doIHaveACalendar?: Maybe<Scalars['Boolean']>;
  employeeVisit: EmployeeVisitQueries;
  getAccountUsersByIds?: Maybe<Array<Maybe<AccountUser>>>;
  /**
   * Get all active announcements for a given location, within the specific location context & for current user.
   * (e.g. viewedAt - when has the current user first viewed this announcement at provided location)
   */
  getActiveAnnouncementsByLocationId: Array<AnnouncementForLocation>;
  /**
   * Get all active announcements for given locations, within the specific location context & for current user.
   * (e.g. viewedAt - when has the current user first viewed this announcement at provided location)
   */
  getActiveAnnouncementsByLocationIds: Array<AnnouncementForLocation>;
  getActiveDistributionsByAuthorId: Array<Maybe<AnnouncementDistributionForLocation>>;
  /** Get list of active visit types for org */
  getActiveVisitTypesForOrg: Array<Scalars['String']>;
  getActivitiesSummaryForTimeRangeByLocationIds: Array<DailyActivitiesSummary>;
  /**
   * Gets the latest messages for activity chat. Window from latest message ID.
   * Retrieves messages newer than the referenced message.
   */
  getActivityChatNewerMessages: Array<ChatMessage>;
  /** Returns the set of message IDs in chat that have been removed after the specified timestamp. */
  getActivityChatRemovedMessagesSince: Array<Scalars['ID']>;
  /**
   * Gets the latest messages for activity chat. Optional parameter @messagesPriorTo is the identity of the earliest
   * ChatMessage of which the client is aware. Providing @messagesPriorTo retrieves the previous page of messages. Default @pageSize is 5.
   */
  getActivityChatTail: Array<ChatMessage>;
  getActivityForCurrentUserById: ActivityForCurrentUser;
  getAllActivitiesForDateByLocationIds: Array<ActivitiesByLocation>;
  getAllJoinedUsersByActivityId: Array<Maybe<User>>;
  getAllOrganizationLocations: Array<Maybe<OrganizationLocation>>;
  /** Returns filtered view of all visit data for the organization specified in the tenant ID header */
  getAllVisits: Array<Visit>;
  getAmenitiesByIds: Array<Amenity>;
  getAmenityById?: Maybe<Amenity>;
  getAnalyticsAuthorizationToken: AnalyticsAuthorizationResponse;
  /** Get a single announcement by ID */
  getAnnouncementById?: Maybe<Announcement>;
  /** Get a single announcement as type AnnouncementForLocation by ID */
  getAnnouncementForLocationById?: Maybe<AnnouncementForLocation>;
  getAnnouncementViewAcknowledgeCount?: Maybe<AnnouncementViewAcknowledgeCount>;
  /** Get all the announcements for an organization, filterable by location id and status */
  getAnnouncements: Array<Maybe<Announcement>>;
  getAnyDeskById?: Maybe<Desk>;
  getAnyDesksByIds: Array<Desk>;
  /** Get a list of arrival displays registered to the organization of the requesting user */
  getArrivalDisplay?: Maybe<ArrivalDisplay>;
  /** Get a list of arrival displays registered to the organization of the requesting user */
  getArrivalDisplays: Array<ArrivalDisplay>;
  getAuthedTeamUsersByName: Array<User>;
  /** Get view count & acknowledge count for each given announcement ID */
  getBulkAnnouncementViewAcknowledgeCount: Array<AnnouncementViewAcknowledgeCountWithId>;
  getCampusById?: Maybe<Campus>;
  getCampusesByIds: Array<Campus>;
  /** Return a list of visits given a guest's partial email */
  getCheckedInVisitsByPartialEmail: Array<PublicVisitResult>;
  getCoverImagesForActivities: ActivityCoverImagesObject;
  getCurrentUserAndOrgs?: Maybe<CurrentUserAndOrgs>;
  /** @deprecated Default guideline is just a policy with rank 0 now */
  getDefaultGuideline?: Maybe<Guideline>;
  getDeskAvailabilityByLevelIdForMe: DeskAvailabilityByLevelId;
  getDeskById?: Maybe<Desk>;
  getDeskExclusionById?: Maybe<Exclusion>;
  getDeskExclusionsByIds: Array<Exclusion>;
  getDeskReservationById?: Maybe<Reservation>;
  getDeskReservationInstancesById: Array<Reservation>;
  getDeskReservationsByIds: Array<Reservation>;
  getDeskReservationsByLevelId: LevelDeskReservation;
  getDeskReservationsByLocationId: LocationDeskReservation;
  getDeskReservationsBySpaceIds: Array<ReservationsBySpacesForStatusBoard>;
  getDeskReservationsByUserId: UserDeskReservation;
  getDeskReservationsByUserIds: Array<UserDeskReservation>;
  getDeskReservationsByZoneId: ZoneDeskReservation;
  /**
   * Query reservations for a set of users between two moments at a specific location.
   * Results are grouped by date relative to the location ISO time offset.
   */
  getDeskReservationsForUsersAtLocationGroupedByDateAscending: DateGroupedUserReservations;
  /**
   * Query reservations for a set of users between two moments.
   * Results are grouped by date relative to the requesting users ISO time offset.
   */
  getDeskReservationsForUsersGroupedByDateAscending: DateGroupedUserReservations;
  getDesksByIds: Array<Desk>;
  getDisabledDeskById?: Maybe<Desk>;
  getDisabledDesksByIds: Array<Desk>;
  getDistributionById: AnnouncementDistribution;
  getDistributionsByAnnouncementId: Array<AnnouncementDistribution>;
  /** Get all of an employee's visits during a specific time range at a specific location */
  getEmployeeVisitsAtLocationForEmployee: Array<EmployeeVisit>;
  /** Get all of the visits during a specific time range at a specific location for several employees */
  getEmployeeVisitsForUsersAtLocationGroupedByDateAscending: Array<EmployeeVisitsByDate>;
  getEventById?: Maybe<Event>;
  getExternalEvents: Array<ExternalEvent>;
  getGoogleAuthForUser: UserGoogleAuth;
  getGoogleWorkingLocationSyncSettingForOrg: Scalars['Boolean'];
  /** Gets the sync google working locations setting user */
  getGoogleWorkingLocationSyncSettingForUser: GoogleWorkingLocationSyncSetting;
  getGroupsByKeyword: Array<Group>;
  /** Get a guest invite of a visit by its ID */
  getGuestVisitById?: Maybe<PublicVisitResult>;
  /** @deprecated In office has been migrated to Whos In */
  getInOfficeDataByLocation: InOfficeData;
  /** @deprecated In office has been migrated to Whos In */
  getInOfficeDataByLocationByDay: Array<InOfficeDataByDay>;
  /** @deprecated In office has been migrated to Whos In */
  getInOfficeDataByUserIdsAndLocation: InOfficeData;
  getInitialWorkPolicyCreatedAt?: Maybe<Scalars['ISODateTime']>;
  /** Gets a external integration by externalId */
  getIntegrationByExternalId?: Maybe<Array<Maybe<ExternalIntegration>>>;
  /** Gets a external integration by id */
  getIntegrationById?: Maybe<ExternalIntegration>;
  /** Gets a external integration by orgId */
  getIntegrationByOrgId?: Maybe<Array<Maybe<ExternalIntegration>>>;
  getLevelById?: Maybe<Level>;
  getLevelsByIds: Array<Level>;
  getLocationById?: Maybe<Location>;
  getLocationByRemoteGoogleId?: Maybe<SimpleLocation>;
  getLocationsByIds: Array<Location>;
  getMyDefaultLocationOrFallback?: Maybe<Location>;
  getMyEventsInTheRange: Array<Event>;
  /** @deprecated getMyWorkPolicyWithProgress should be used instead. */
  getMyGuideline?: Maybe<Guideline>;
  /** @deprecated getMyWorkPolicyWithProgress should be used instead. */
  getMyGuidelineWithProgress?: Maybe<GuidelineWithProgress>;
  getMyHomepage: MyHomepageResponse;
  /** Sets a language tag for current (user) actor */
  getMyLanguageTag: MyLanguageTagResponse;
  getMyTeamNames: Array<Scalars['String']>;
  /**
   * Returns the set of unacknowledged announcements for the requesting user for the given location.
   * (Only PRIORITY announcements, as they can be acknowledged)
   */
  getMyUnacknowledgedActiveAnnouncementsByLocationId: Array<Announcement>;
  /**
   * Returns the set of unacknowledged announcements for the requesting user for the given locations.
   * (Only PRIORITY announcements, as they can be acknowledged)
   */
  getMyUnacknowledgedActiveAnnouncementsByLocationIds: Array<Announcement>;
  getMyWorkPolicyWithProgress?: Maybe<WorkPolicyWithProgress>;
  getOfficeAdmins: Array<User>;
  getOrganizationById?: Maybe<Organization>;
  /** Gets a legacy integration by externalId and type */
  getOrganizationByIntegration?: Maybe<ApiOrganizationExternalAccount>;
  getOrganizationExternalAccount?: Maybe<OrganizationExternalAccount>;
  /** Gets an encoded version of an organization ID */
  getOrganizationIdHash: OrganizationIdHashResponse;
  getOrganizationsByIds: Array<Organization>;
  getOrganizationsByUserEmail: Array<Maybe<MeOrganization>>;
  getPagedUsers: PagedUserResult;
  getPagedUsersV2: PagedUserResult;
  /**
   * Get all survey responses with pagination based on the filters
   * By default returns only completed responses.
   */
  getPaginatedSurveyResponses?: Maybe<Array<Maybe<SurveyResponse>>>;
  getPaginatedSurveyResponsesTotalPageCount?: Maybe<Scalars['Int']>;
  getPassesByOrganizationId: Array<OfficeAccess>;
  /** Get the most recent pending survey response for a user */
  getPendingSurveyResponseForUser?: Maybe<SurveyResponse>;
  getPinnedUserSpacesByLocationIds: Array<PinnedUserSpaceEntity>;
  getRipplingAccountForOrganization?: Maybe<RipplingAccount>;
  getRipplingWorkLocationsAndExistingMappings?: Maybe<RipplingWorkLocationsWithMappings>;
  getRobinUserInfoBySlackId?: Maybe<RobinUserInfoForSlack>;
  getRole?: Maybe<Role>;
  getSeatIdByStickerShortCode?: Maybe<Scalars['ID']>;
  /** Constructs a Sigma embed URL */
  getSigmaEmbedUrl?: Maybe<SigmaEmbedUrl>;
  /** Lists all Sigma workbooks accessable by a user */
  getSigmaWorkbooks?: Maybe<Array<Maybe<SigmaFile>>>;
  getSpaceById?: Maybe<Space>;
  getSpacesByIds: Array<Space>;
  getStickerBatchById?: Maybe<StickerBatch>;
  getStickerById?: Maybe<Sticker>;
  getStickerByShortCode?: Maybe<Sticker>;
  getStickersByOrgId: Array<Maybe<Sticker>>;
  /**
   * Given a set of event IDs and space filter criteria,
   * return a suggested space matching that filter criteria for each event.
   * All suggested spaces are simultaneously reservable by the current user.
   *   * All returned spaces are available for their event's timeframe.
   *   * If events overlap in time, we try our best to return distinct spaces
   *     per overlapping event, but can return null if impossible
   *   * No other policies (e.g. max duration) prevent reservation
   */
  getSuggestedSpacesForEvents: GetSuggestedSpacesForEventsResponse;
  /**
   * This is a query which calls the same underlying suggested spaces algorithm as the above,
   * but doesn't consider existing events when querying for suggested spaces.
   * Every other condition is the same, and it uses the same datasource as the above query.
   * Use this one if you don't have an event for which you are querying suggested spaces.
   */
  getSuggestedSpacesForTimeFrame: GetSuggestedSpacesForTimeFrameResponse;
  /** Get all survey configurations for an organization */
  getSurveyConfigurations?: Maybe<Array<Maybe<Survey>>>;
  /** Get summary of the ratings of all survey responses with the given filters */
  getSurveyResponseRatingSummary?: Maybe<SurveyResponseRatingSummary>;
  /** Get summary of the statuses of all survey responses with the given filters */
  getSurveyResponseStatusSummary?: Maybe<SurveyResponseStatusSummary>;
  /**
   * Get all survey responses based on the criteria.
   * By default returns only completed responses.
   */
  getSurveyResponses?: Maybe<Array<Maybe<SurveyResponse>>>;
  /** @deprecated Deprecated -- use getAuthedTeamUsersByName instead to avoid getting invalid favorites */
  getTeamUsersByName: Array<User>;
  /** Return associated tenant ID for a visit given a magic link */
  getTenantIdByMagicLink: Scalars['ID'];
  getTimeZonesForRipplingAddresses: GetTimeZonesForRipplingAddressesResponse;
  /**
   * Gets publicly available information about a arrival display iff
   * that display has not yet been registered
   */
  getUnauthorizedArrivalDisplayDetails?: Maybe<PublicArrivalDisplay>;
  /**
   * Returns whether the current user has access to update the passed through events.
   * This is currently used to see whether we should allow them to add a space to these events.
   */
  getUpdatePermissionsForEvents: GetUpdatePermissionsForEventsResponse;
  /** Check if a user has acknowledged an announcement in a given location */
  getUserAcknowledgementByAnnouncementId?: Maybe<AnnouncementAcknowledgement>;
  getUserById?: Maybe<User>;
  getUserInOfficeDataByDay: Array<UserInOfficeByDay>;
  /** Get the notification preferences for the given user */
  getUserNotificationPreferences?: Maybe<NotificationPreferences>;
  getUserNotificationSettings?: Maybe<Array<Maybe<UserNotificationsSetting>>>;
  getUserSetting?: Maybe<UserSetting>;
  getUserSyncStatus?: Maybe<UserSyncStatus>;
  getUsersByEmails: Array<User>;
  getUsersByIds: Array<User>;
  getUsersByKeyword: Array<User>;
  getUsersByRole: Array<User>;
  getUsersForTeams: Array<Maybe<TeamsUserSearchResult>>;
  /** Gets the state of an asynchronous visit request */
  getVisitAsyncRequestStatus: VisitAsyncRequestStatusResponse;
  /** Get a visit by its ID */
  getVisitById?: Maybe<Visit>;
  /** Return details for a visit given a magic link */
  getVisitByMagicLink?: Maybe<UnauthenticatedVisitResult>;
  /** Returns a count of all visits matching the specified search. */
  getVisitCount: Scalars['Int'];
  /** Return a list of visits given a guest's email */
  getVisitsByEmail: Array<PublicVisitResult>;
  /** @deprecated Deprecated in favor of getWhosInDataByLocations */
  getWhosInDataByLocation: WhosInData;
  /** @deprecated Deprecated in favor of getWhosInDataByLocationsByDay */
  getWhosInDataByLocationByDay: Array<WhosInDataByDay>;
  getWhosInDataByLocationsByDay: Array<WhosInDataByDay>;
  getWhosInDataForLocations: WhosInData;
  getWorkAreaById?: Maybe<WorkArea>;
  getWorkAreasByLevel: Array<WorkArea>;
  getWorkPoliciesForOrganization: Array<WorkPolicy>;
  getWorkPolicyById?: Maybe<WorkPolicy>;
  /** Gets a single zone by its identifier */
  getZoneById?: Maybe<Zone>;
  /** Gets several zones by their identifiers */
  getZonesByIds: Array<Zone>;
  /** @deprecated Replaced with getInitialWorkPolicyCreatedAt for that check */
  hasVirtualWorkPolicy: Scalars['Boolean'];
  /**
   * Is the arrival display paired?
   * Returns false if the device has been removed.
   */
  isArrivalDisplayPaired: Scalars['Boolean'];
  isFavoritesEnabled: Scalars['Boolean'];
  /** Is a feature available for a device's organization (guestUI, Arrival Display)? */
  isFeatureFlagEnabledForDeviceOrg: Scalars['Boolean'];
  isFeatureFlagEnabledForOrgOrMe: Scalars['Boolean'];
  /** Is guest self-registration enabled for a device? */
  isGuestSelfRegistrationEnabled: Scalars['Boolean'];
  isPassiveCheckInFeatureEnabledForBuilding: Scalars['Boolean'];
  isRipplingAccountConnectedForOrganization: Scalars['Boolean'];
  /** Gets the show weekend setting for current (user) actor */
  isShowWeekendsEnabledForMe: MyShowWeekendsResponse;
  isUpgradeNeededToEnableGuidelinesFeature: Scalars['Boolean'];
  /**
   * Returns latest change date for all reservations indicating the latest reservation state change.
   * If the returned timestamp changes in subsequent requests, it indicates that either a desk or space
   * reservation has been created since last fetch.
   * NULL response when no reservations exist for the specified level
   */
  levelChangeDetectionToken?: Maybe<Scalars['ISODateTime']>;
  listBuildingCidrs: ListBuildingCidrsResponse;
  /** Checks seats for multidate reservation availability. */
  reservationsMultiDayGroupedByDate: Array<DeskAvailabilityLocalizedDate>;
  /** Tells you if the current user is allowed to report issues on a space */
  spaceAllowsIssueReports: ResourceAllowsIssueReportsResponse;
  /** Fetch visit document with agreement from user based on visit invite ID */
  visitDocumentAgreement: VisitDocumentAgreementResponse;
  /** Query for visit and document information associated with a decision ID (assumed to be document accept) */
  visitDocumentByDecisionId?: Maybe<VisitDocumentByDecisionResponse>;
  /** @deprecated Use visitDocumentByIdWithCustomVisitType instead */
  visitDocumentById?: Maybe<VisitDocumentResponse>;
  visitDocumentByIdWithCustomVisitType?: Maybe<VisitDocumentResponse>;
  /**
   * Query paged organizations documents available to workflows which can be applied to visits
   * @deprecated Use visitDocumentNameStatusAndTypesPageWithCustomVisitType instead
   */
  visitDocumentNameStatusAndTypesPage: Array<VisitDocumentNameStatusAndTypesQueryResponse>;
  /** Query paged organizations documents available to workflows which can be applied to visits */
  visitDocumentNameStatusAndTypesPageWithCustomVisitType: Array<VisitDocumentNameStatusAndTypesQueryResponse>;
  /**
   * Returns document agreement details for `documentID` or first document
   * agreement if no `documentId` is specified. If response is NULL, no documents
   * to agree with to for a visit.
   * @deprecated Use visitDocumentWithNextDocumentIdWithCustomVisitType instead
   */
  visitDocumentWithNextDocumentId?: Maybe<VisitDocumentAgreementWithNextDocumentResponse>;
  /**
   * Returns document agreement details for `documentID` or first document
   * agreement if no `documentId` is specified. If response is NULL, no documents
   * to agree with to for a visit.
   */
  visitDocumentWithNextDocumentIdWithCustomVisitType?: Maybe<VisitDocumentAgreementWithNextDocumentResponse>;
  /**
   * Query for menu overview of documents required for a key pair of visit type and location ID.
   * @deprecated Use visitOverviewOfDocumentsWithCustomVisitType instead
   */
  visitOverviewOfDocuments?: Maybe<VisitWorkflowOverview>;
  /** Query for menu overview of documents required for a key pair of visit type and location ID. */
  visitOverviewOfDocumentsWithCustomVisitType?: Maybe<VisitWorkflowOverview>;
};


export type QueryCheckOrganizationNameAvailabilityArgs = {
  name: Scalars['String'];
};


export type QueryCountVisitFilteredDocumentsArgs = {
  filters?: InputMaybe<Array<VisitDocumentNameStatusAndTypesFilter>>;
};


export type QueryDeskAllowsIssueReportsArgs = {
  deskId: Scalars['ID'];
};


export type QueryGetAccountUsersByIdsArgs = {
  filterBy?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortByField?: InputMaybe<Array<InputMaybe<UserSortBy>>>;
  sortOrder?: InputMaybe<UserSortOrder>;
};


export type QueryGetActiveAnnouncementsByLocationIdArgs = {
  locationId: Scalars['ID'];
};


export type QueryGetActiveAnnouncementsByLocationIdsArgs = {
  locationIds: Array<Scalars['ID']>;
};


export type QueryGetActiveDistributionsByAuthorIdArgs = {
  authorId: Scalars['ID'];
  service?: InputMaybe<Scalars['String']>;
};


export type QueryGetActivitiesSummaryForTimeRangeByLocationIdsArgs = {
  input: ActivitiesForTimeRangeByLocationIdsInput;
  pagingInput?: InputMaybe<ActivitiesForTimeRangeByLocationIdsPagingInput>;
};


export type QueryGetActivityChatNewerMessagesArgs = {
  activityID: Scalars['ID'];
  messagesAfter: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryGetActivityChatRemovedMessagesSinceArgs = {
  activityID: Scalars['ID'];
  since: Scalars['ISODateTime'];
};


export type QueryGetActivityChatTailArgs = {
  activityID: Scalars['ID'];
  messagesPriorTo?: InputMaybe<Scalars['ID']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryGetActivityForCurrentUserByIdArgs = {
  id: Scalars['ID'];
  joinedLimit?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllActivitiesForDateByLocationIdsArgs = {
  input?: InputMaybe<ActivitiesForDateByLocationIdsInput>;
};


export type QueryGetAllJoinedUsersByActivityIdArgs = {
  activityId: Scalars['ID'];
};


export type QueryGetAllVisitsArgs = {
  dateFilter: DateFilterMandatoryInput;
  excludeCanceledVisits?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  locationIdFilter: Array<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAmenitiesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetAmenityByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetAnalyticsAuthorizationTokenArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetAnnouncementByIdArgs = {
  announcementId: Scalars['ID'];
};


export type QueryGetAnnouncementForLocationByIdArgs = {
  announcementId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type QueryGetAnnouncementViewAcknowledgeCountArgs = {
  announcementId: Scalars['ID'];
};


export type QueryGetAnnouncementsArgs = {
  locationId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<AnnouncementStatus>;
};


export type QueryGetAnyDeskByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetAnyDesksByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetArrivalDisplayArgs = {
  id: Scalars['ID'];
};


export type QueryGetArrivalDisplaysArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  seekKey?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryGetAuthedTeamUsersByNameArgs = {
  teamName: Scalars['String'];
};


export type QueryGetBulkAnnouncementViewAcknowledgeCountArgs = {
  announcementIds: Array<Scalars['ID']>;
};


export type QueryGetCampusByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetCampusesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetCheckedInVisitsByPartialEmailArgs = {
  email: Scalars['String'];
  includeCheckedOutVisits?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  minutesAfter?: InputMaybe<Scalars['Int']>;
  minutesBefore?: InputMaybe<Scalars['Int']>;
  resultLimiter?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDeskAvailabilityByLevelIdForMeArgs = {
  filters?: InputMaybe<GetDeskAvailabilityByLevelIdFiltersInput>;
  scope: GetDeskAvailabilityByLevelIdScopeInput;
  viewOptions: GetDeskAvailabilityByLevelIdViewOptionsInput;
};


export type QueryGetDeskByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDeskExclusionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDeskExclusionsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetDeskReservationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDeskReservationInstancesByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDeskReservationsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetDeskReservationsByLevelIdArgs = {
  endTime: Scalars['Date'];
  levelId: Scalars['ID'];
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
};


export type QueryGetDeskReservationsByLocationIdArgs = {
  endTime: Scalars['Date'];
  locationId: Scalars['ID'];
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
};


export type QueryGetDeskReservationsBySpaceIdsArgs = {
  endTime: Scalars['ISODateTime'];
  spaceIds: Array<Scalars['ID']>;
  startTime: Scalars['ISODateTime'];
};


export type QueryGetDeskReservationsByUserIdArgs = {
  endTime: Scalars['Date'];
  id: Scalars['ID'];
  startTime: Scalars['Date'];
};


export type QueryGetDeskReservationsByUserIdsArgs = {
  endTime: Scalars['Date'];
  ids: Array<Scalars['ID']>;
  startTime: Scalars['Date'];
};


export type QueryGetDeskReservationsByZoneIdArgs = {
  endTime: Scalars['Date'];
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
  zoneId: Scalars['ID'];
};


export type QueryGetDeskReservationsForUsersAtLocationGroupedByDateAscendingArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  userIds: Array<Scalars['ID']>;
};


export type QueryGetDeskReservationsForUsersGroupedByDateAscendingArgs = {
  endTime: Scalars['Date'];
  ids: Array<Scalars['ID']>;
  startTime: Scalars['Date'];
};


export type QueryGetDesksByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetDisabledDeskByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDisabledDesksByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetDistributionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDistributionsByAnnouncementIdArgs = {
  announcementId: Scalars['ID'];
};


export type QueryGetEmployeeVisitsAtLocationForEmployeeArgs = {
  employeeId: Scalars['ID'];
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
};


export type QueryGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  userIds: Array<Scalars['ID']>;
};


export type QueryGetEventByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetExternalEventsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGetGoogleAuthForUserArgs = {
  userEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetGoogleWorkingLocationSyncSettingForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryGetGroupsByKeywordArgs = {
  keyword: Scalars['String'];
  organizationId: Scalars['ID'];
};


export type QueryGetGuestVisitByIdArgs = {
  guestVisitId: Scalars['ID'];
};


export type QueryGetInOfficeDataByLocationArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
};


export type QueryGetInOfficeDataByLocationByDayArgs = {
  dateRanges: Array<DateRangeInput>;
  locationId: Scalars['ID'];
};


export type QueryGetInOfficeDataByUserIdsAndLocationArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  userIds: Array<Scalars['ID']>;
};


export type QueryGetIntegrationByExternalIdArgs = {
  externalId: Scalars['ID'];
};


export type QueryGetIntegrationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetIntegrationByOrgIdArgs = {
  orgId: Scalars['ID'];
};


export type QueryGetLevelByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetLevelsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetLocationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetLocationByRemoteGoogleIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetLocationsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetMyEventsInTheRangeArgs = {
  endTime: Scalars['Date'];
  limit?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['Date'];
};


export type QueryGetMyGuidelineWithProgressArgs = {
  myTimezone: Scalars['String'];
};


export type QueryGetMyUnacknowledgedActiveAnnouncementsByLocationIdArgs = {
  locationId: Scalars['ID'];
};


export type QueryGetMyUnacknowledgedActiveAnnouncementsByLocationIdsArgs = {
  locationIds: Array<Scalars['ID']>;
};


export type QueryGetMyWorkPolicyWithProgressArgs = {
  myTimezone: Scalars['String'];
};


export type QueryGetOfficeAdminsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locationId: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetOrganizationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrganizationByIntegrationArgs = {
  externalId: Scalars['String'];
  type: Scalars['String'];
};


export type QueryGetOrganizationExternalAccountArgs = {
  externalId: Scalars['ID'];
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetOrganizationIdHashArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetOrganizationsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetOrganizationsByUserEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetPagedUsersArgs = {
  filters?: InputMaybe<PagedUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
};


export type QueryGetPagedUsersV2Args = {
  filters?: InputMaybe<PagedUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
};


export type QueryGetPaginatedSurveyResponsesArgs = {
  filters: GetSurveyResponsesFilters;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortBy?: InputMaybe<GetSurveyResponsesSort>;
  surveyId: Scalars['ID'];
};


export type QueryGetPaginatedSurveyResponsesTotalPageCountArgs = {
  filters: GetSurveyResponsesFilters;
  perPage: Scalars['Int'];
  surveyId: Scalars['ID'];
};


export type QueryGetPassesByOrganizationIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetPendingSurveyResponseForUserArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetPinnedUserSpacesByLocationIdsArgs = {
  locationIds: Array<Scalars['ID']>;
};


export type QueryGetRipplingAccountForOrganizationArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetRipplingWorkLocationsAndExistingMappingsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetRobinUserInfoBySlackIdArgs = {
  externalId: Scalars['String'];
};


export type QueryGetRoleArgs = {
  organizationId: Scalars['ID'];
  roleSlug: Scalars['String'];
};


export type QueryGetSeatIdByStickerShortCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetSigmaEmbedUrlArgs = {
  editMode?: InputMaybe<Scalars['Boolean']>;
  embedId?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<SigmaEmbedOptions>;
  organizationId: Scalars['ID'];
  tag?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
  workbookUrlId?: InputMaybe<Scalars['String']>;
};


export type QueryGetSigmaWorkbooksArgs = {
  email: Scalars['String'];
};


export type QueryGetSpaceByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetSpacesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetStickerBatchByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetStickerByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetStickerByShortCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetStickersByOrgIdArgs = {
  orgId: Scalars['ID'];
};


export type QueryGetSuggestedSpacesForEventsArgs = {
  input: GetSuggestedSpacesForEventsInput;
};


export type QueryGetSuggestedSpacesForTimeFrameArgs = {
  input: GetSuggestedSpacesForTimeFrameInput;
};


export type QueryGetSurveyResponseRatingSummaryArgs = {
  filters: GetSurveyResponsesFilters;
  surveyId: Scalars['ID'];
};


export type QueryGetSurveyResponseStatusSummaryArgs = {
  filters: GetSurveyResponsesFilters;
  surveyId: Scalars['ID'];
};


export type QueryGetSurveyResponsesArgs = {
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  inOfficeAtEnd: Scalars['ISODateTime'];
  inOfficeAtStart: Scalars['ISODateTime'];
  includeIncomplete?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sortBy?: InputMaybe<GetSurveyResponsesSort>;
  surveyId: Scalars['ID'];
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetTeamUsersByNameArgs = {
  teamName: Scalars['String'];
};


export type QueryGetTenantIdByMagicLinkArgs = {
  magicLink: Scalars['String'];
};


export type QueryGetTimeZonesForRipplingAddressesArgs = {
  input: GetTimeZonesForRipplingAddressesInput;
};


export type QueryGetUnauthorizedArrivalDisplayDetailsArgs = {
  orgId: Scalars['ID'];
  registrationCode: Scalars['ArrivalDisplayRegistrationKey'];
};


export type QueryGetUpdatePermissionsForEventsArgs = {
  eventIds: Array<Scalars['ID']>;
};


export type QueryGetUserAcknowledgementByAnnouncementIdArgs = {
  announcementId: Scalars['ID'];
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryGetUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserInOfficeDataByDayArgs = {
  dateRanges: Array<DateRangeInput>;
  id: Scalars['ID'];
};


export type QueryGetUserNotificationPreferencesArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUserNotificationSettingsArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUserSettingArgs = {
  settingSlug: Scalars['String'];
  userId: Scalars['ID'];
};


export type QueryGetUserSyncStatusArgs = {
  syncId: Scalars['ID'];
};


export type QueryGetUsersByEmailsArgs = {
  emails: Array<Scalars['String']>;
};


export type QueryGetUsersByIdsArgs = {
  filterBy?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortByField?: InputMaybe<Array<InputMaybe<UserSortBy>>>;
  sortOrder?: InputMaybe<UserSortOrder>;
};


export type QueryGetUsersByKeywordArgs = {
  keyword: Scalars['String'];
  organizationId: Scalars['ID'];
};


export type QueryGetUsersByRoleArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  roleQuery?: InputMaybe<Scalars['String']>;
  roleSlug?: InputMaybe<Scalars['String']>;
};


export type QueryGetUsersForTeamsArgs = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};


export type QueryGetVisitAsyncRequestStatusArgs = {
  visitId: Scalars['ID'];
};


export type QueryGetVisitByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetVisitByMagicLinkArgs = {
  magicLink: Scalars['String'];
};


export type QueryGetVisitCountArgs = {
  dateFilter: DateFilterMandatoryInput;
  excludeCanceledVisits?: InputMaybe<Scalars['Boolean']>;
  locationIdFilter: Array<Scalars['ID']>;
};


export type QueryGetVisitsByEmailArgs = {
  email: Scalars['String'];
  locationId?: InputMaybe<Scalars['String']>;
  minutesAfter?: InputMaybe<Scalars['Int']>;
  minutesBefore?: InputMaybe<Scalars['Int']>;
};


export type QueryGetWhosInDataByLocationArgs = {
  date?: InputMaybe<Scalars['LocalDate']>;
  locationId: Scalars['ID'];
};


export type QueryGetWhosInDataByLocationByDayArgs = {
  dates?: InputMaybe<Array<Scalars['LocalDate']>>;
  locationId: Scalars['ID'];
};


export type QueryGetWhosInDataByLocationsByDayArgs = {
  dates: Array<Scalars['LocalDate']>;
  locationIds: Array<Scalars['ID']>;
};


export type QueryGetWhosInDataForLocationsArgs = {
  date?: InputMaybe<Scalars['LocalDate']>;
  locationIds: Array<Scalars['ID']>;
};


export type QueryGetWorkAreaByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkAreasByLevelArgs = {
  levelId: Scalars['ID'];
};


export type QueryGetWorkPolicyByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetZoneByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetZonesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryIsFeatureFlagEnabledForDeviceOrgArgs = {
  flagType?: InputMaybe<DeviceFeatureFlagType>;
};


export type QueryIsFeatureFlagEnabledForOrgOrMeArgs = {
  flagType?: InputMaybe<FeatureFlagType>;
};


export type QueryIsPassiveCheckInFeatureEnabledForBuildingArgs = {
  input: IsPassiveCheckInFeatureEnabledForBuildingInput;
};


export type QueryIsRipplingAccountConnectedForOrganizationArgs = {
  organizationId: Scalars['ID'];
};


export type QueryLevelChangeDetectionTokenArgs = {
  levelId: Scalars['ID'];
};


export type QueryListBuildingCidrsArgs = {
  input: ListBuildingCidrsInput;
};


export type QueryReservationsMultiDayGroupedByDateArgs = {
  dates: Array<Scalars['LocalDate']>;
  deskIds: Array<Scalars['ID']>;
  durationInMinutes: Scalars['Int'];
  startTime: Scalars['LocalTime'];
  timezone: Scalars['IANATimezone'];
};


export type QuerySpaceAllowsIssueReportsArgs = {
  spaceId: Scalars['ID'];
};


export type QueryVisitDocumentAgreementArgs = {
  documentId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
};


export type QueryVisitDocumentByDecisionIdArgs = {
  decisionId: Scalars['ID'];
};


export type QueryVisitDocumentByIdArgs = {
  id: Scalars['ID'];
};


export type QueryVisitDocumentByIdWithCustomVisitTypeArgs = {
  id: Scalars['ID'];
};


export type QueryVisitDocumentNameStatusAndTypesPageArgs = {
  filters?: InputMaybe<Array<VisitDocumentNameStatusAndTypesFilter>>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortByField?: InputMaybe<Query_Visit_Document_Sort_By>;
  sortOrder?: InputMaybe<Query_Workflow_Sort_Order>;
};


export type QueryVisitDocumentNameStatusAndTypesPageWithCustomVisitTypeArgs = {
  filters?: InputMaybe<Array<VisitDocumentNameStatusAndTypesFilter>>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortByField?: InputMaybe<Query_Visit_Document_Sort_By>;
  sortOrder?: InputMaybe<Query_Workflow_Sort_Order>;
};


export type QueryVisitDocumentWithNextDocumentIdArgs = {
  documentId?: InputMaybe<Scalars['ID']>;
  visitInviteId: Scalars['ID'];
  visitLocationId: Scalars['ID'];
  visitType: Scalars['String'];
};


export type QueryVisitDocumentWithNextDocumentIdWithCustomVisitTypeArgs = {
  documentId?: InputMaybe<Scalars['ID']>;
  visitInviteId: Scalars['ID'];
  visitLocationId: Scalars['ID'];
  visitType: Scalars['String'];
};


export type QueryVisitOverviewOfDocumentsArgs = {
  locationId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  visitType: Scalars['String'];
};


export type QueryVisitOverviewOfDocumentsWithCustomVisitTypeArgs = {
  locationId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  visitType: Scalars['String'];
};

export type ReportIssueInput = {
  description: Scalars['String'];
  isUnusable: Scalars['Boolean'];
};

export type ReportIssueResponse = {
  __typename?: 'ReportIssueResponse';
  id: Scalars['ID'];
};

export type ReportIssueWithDeskInput = {
  deskId: Scalars['ID'];
  issue: ReportIssueInput;
};

export type ReportIssueWithSpaceInput = {
  issue: ReportIssueInput;
  spaceId: Scalars['ID'];
};

export type ReportUserPresenceAtIpAddressInput = {
  /** Version of the app in use, e.g. 'v3.28.3 (v1)' */
  appVersion?: InputMaybe<Scalars['String']>;
  /** Unique identifier associated with user's personal device */
  deviceUuid: Scalars['ID'];
  /** Purely for logging/debugging, X-Forwarded-For or source IP is the source of truth */
  ipAddressGuess?: InputMaybe<Scalars['IPAddress']>;
  operatingSystem: OperatingSystem;
  /** e.g. the '10' in Android 10, but intentionally loose like a 'User-Agent' string.  For logging/debugging. */
  operatingSystemVersion: Scalars['String'];
  /** Unique identifier for this request, for client/server log correlation */
  requestUuid: Scalars['ID'];
  /** Arbitrary string to describe what triggered this report, e.g. 'background fetch', 'background push', etc. */
  trigger?: InputMaybe<Scalars['String']>;
};

export type ReportUserPresenceAtIpAddressResponse = {
  __typename?: 'ReportUserPresenceAtIPAddressResponse';
  /**
   * Zero or more building IDs associated with this IP address.
   * IP addresses can be assigned to multiple buildings.
   */
  buildingIds: Array<Scalars['ID']>;
  /**
   * What we think the IP address is, ignoring the provided guess.
   * Could be null if we get malformed headers (rare).
   */
  ipAddress?: Maybe<Scalars['IPAddress']>;
  /** True if reported IP address matches a known IP address associated with a building */
  matchesBuilding: Scalars['Boolean'];
};

export type Reservation = {
  __typename?: 'Reservation';
  accountReservee?: Maybe<AccountReservee>;
  confirmation?: Maybe<DeskConfirmation>;
  endTime?: Maybe<Scalars['String']>;
  exclusions: Array<Exclusion>;
  healthCheckpoint?: Maybe<HealthCheckpoint>;
  /** Reservation Id */
  id: Scalars['ID'];
  recurrence?: Maybe<Scalars['String']>;
  /** @deprecated use the account reservee, it will be scoped to the reservations org */
  reservee?: Maybe<Reservee>;
  seat: Desk;
  seriesId?: Maybe<Scalars['ID']>;
  startTime: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  visibility: DeskReservationVisibility;
};

export type ReservationEdge = {
  __typename?: 'ReservationEdge';
  confirmation?: Maybe<DeskConfirmation>;
  endTime?: Maybe<Scalars['String']>;
  healthCheckpoint?: Maybe<HealthCheckpoint>;
  /** Reservation Id */
  id: Scalars['ID'];
  recurrence?: Maybe<Scalars['String']>;
  reservee?: Maybe<Reservee>;
  seat: Desk;
  seriesId?: Maybe<Scalars['ID']>;
  startTime: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Reservation invitee. One of the fields must be specified. */
export type ReservationInvitee = {
  /** Invitee email */
  email?: InputMaybe<Scalars['String']>;
  /** Invitee slug */
  slug?: InputMaybe<Scalars['String']>;
  /** Robin user ID */
  userId?: InputMaybe<Scalars['ID']>;
};

/** Visibility of reservation */
export enum ReservationVisibility {
  Confidential = 'CONFIDENTIAL',
  Default = 'DEFAULT',
  Personal = 'PERSONAL',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/**
 * The ID of the reservation and if it is visible to the current user.
 * Intended use is by the Status Board to use as a reference for which
 * reservations to hide
 */
export type ReservationsBySpacesForStatusBoard = {
  __typename?: 'ReservationsBySpacesForStatusBoard';
  id: Scalars['ID'];
  visibility: DeskReservationVisibility;
};

export type ReserveSpaceRequest = {
  /** Event end time (ISO format) */
  endTime?: InputMaybe<Scalars['Date']>;
  /** True if the event creator is invited to the event */
  eventCreatorIsInvited: Scalars['Boolean'];
  /** The description of the event. Defaults to empty string */
  eventDescription?: InputMaybe<Scalars['String']>;
  /** The title of the event. Defaults to empty string */
  eventTitle?: InputMaybe<Scalars['String']>;
  /** Set of included integration IDs */
  includedIntegrations: Array<Scalars['ID']>;
  /** Event invitees */
  invitees: Array<ReservationInvitee>;
  /** Is the event an all-day event? Default false */
  isAllDay?: InputMaybe<Scalars['Boolean']>;
  /** The name of the location where the event will take place. Defaults to empty string */
  locationName?: InputMaybe<Scalars['String']>;
  /** Is event pre-confirmed */
  preConfirm?: InputMaybe<Scalars['Boolean']>;
  /** ID of the reserved space */
  spaceId: Scalars['ID'];
  /** Event start time (ISO format) */
  startTime?: InputMaybe<Scalars['Date']>;
  /** Event visibility */
  visibility: ReservationVisibility;
};

/** Reservation response */
export type ReserveSpaceResponse = {
  __typename?: 'ReserveSpaceResponse';
  /** If the space has not been reserved, an explanation will be provided */
  reason?: Maybe<Scalars['String']>;
  /** True if space has been reserved */
  spaceReserved: Scalars['Boolean'];
};

export type Reservee = {
  __typename?: 'Reservee';
  email: Scalars['String'];
  participationStatus?: Maybe<ParticipationStatus>;
  user?: Maybe<User>;
  visitorId?: Maybe<Scalars['ID']>;
};

export type ResourceAllowsIssueReportsResponse = {
  __typename?: 'ResourceAllowsIssueReportsResponse';
  allowed: Scalars['Boolean'];
};

export type RipplingAccount = {
  __typename?: 'RipplingAccount';
  id: Scalars['ID'];
};

export type RipplingWorkLocationWithMappingToRobinBuilding = {
  __typename?: 'RipplingWorkLocationWithMappingToRobinBuilding';
  ripplingWorkLocationAddress?: Maybe<Scalars['String']>;
  ripplingWorkLocationId: Scalars['ID'];
  ripplingWorkLocationName: Scalars['String'];
  robinBuildingId?: Maybe<Scalars['ID']>;
};

export type RipplingWorkLocationsWithMappings = {
  __typename?: 'RipplingWorkLocationsWithMappings';
  locationsWithMappings: Array<RipplingWorkLocationWithMappingToRobinBuilding>;
};

export type RobinUserInfoForSlack = {
  __typename?: 'RobinUserInfoForSlack';
  token: Scalars['String'];
  userId: Scalars['ID'];
};

export type Role = {
  __typename?: 'Role';
  created_at: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  is_default: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  organization_id: Scalars['ID'];
  policies: Array<Policy>;
  /** `schedule` is used to distinguish access roles from other roles, and has value of null (which may have distinguished meaning from empty array) by default. */
  schedule?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type RoleCreationInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  policies?: InputMaybe<Array<RolePolicyInput>>;
  slug: Scalars['String'];
};

export type RolePolicyInput = {
  is_allowed: Scalars['Boolean'];
  object_id: Scalars['ID'];
  object_type?: InputMaybe<Scalars['String']>;
  permission_operation: Scalars['String'];
};

export enum ScheduleSourceType {
  User = 'user'
}

export type SchedulingAggregateOfDesks = {
  __typename?: 'SchedulingAggregateOfDesks';
  /** Total number of desks of type Assigned that are available within the parent */
  deskAssignedAvailableTotal: Scalars['Int'];
  /** Total number of desks of type Assigned that are booked within the parent */
  deskAssignedBookedTotal: Scalars['Int'];
  /** Total number of desks of type Assigned within the parent */
  deskAssignedTotal: Scalars['Int'];
  /** Total number of desks of type Assigned that are unavailable within the parent */
  deskAssignedUnavailableTotal: Scalars['Int'];
  /** Total number of available desks within the parent */
  deskAvailableTotal: Scalars['Int'];
  /** Total number of booked desks within the parent */
  deskBookedTotal: Scalars['Int'];
  /** Total number of desks of type Hot that are available within the parent */
  deskHotAvailableTotal: Scalars['Int'];
  /** Total number of desks of type Hot that are booked within the parent */
  deskHotBookedTotal: Scalars['Int'];
  /** Total number of desks of type Hot within the parent */
  deskHotTotal: Scalars['Int'];
  /** Total number of desks of type Hot that are unavailable within the parent */
  deskHotUnavailableTotal: Scalars['Int'];
  /** Total number of desks of type Hotel that are available within the parent */
  deskHotelAvailableTotal: Scalars['Int'];
  /** Total number of desks of type Hotel that are booked within the parent */
  deskHotelBookedTotal: Scalars['Int'];
  /** Total number of desks of type Hotel within the parent */
  deskHotelTotal: Scalars['Int'];
  /** Total number of desks of type Hotel that are unavailable within the parent */
  deskHotelUnavailableTotal: Scalars['Int'];
  /** Total number of unavailable desks within the parent */
  deskUnavailableTotal: Scalars['Int'];
};

export type SetBuildingCidrsInput = {
  buildingId: Scalars['ID'];
  cidrs: Array<Scalars['IPAddressCIDR']>;
};

export type SetBuildingCidrsResponse = {
  __typename?: 'SetBuildingCidrsResponse';
  buildingCidrsUpdated: Scalars['Boolean'];
  invalidCidrErrors: Array<InvalidCidrError>;
};

export type SetMyHomepageInput = {
  homepage: HomepageType;
};

export type SetMyLanguageTagInput = {
  languageTag: Scalars['String'];
};

export type SetPassiveCheckInFeatureForBuildingInput = {
  buildingId: Scalars['ID'];
};

export type SetShowWeekendsForMeInput = {
  showWeekends: Scalars['Boolean'];
};

export type SetUserNotificationSettingInput = {
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  values: Array<InputMaybe<NotificationSettingType>>;
};

export type SetWeeklyScheduleInput = {
  fri: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  instanceEndTime?: InputMaybe<Scalars['LocalEndTime']>;
  instanceStartTime?: InputMaybe<Scalars['LocalEndTime']>;
  locationId: Scalars['String'];
  mon: Scalars['Boolean'];
  recurrenceEndDate?: InputMaybe<Scalars['LocalDate']>;
  recurrenceStartDate?: InputMaybe<Scalars['LocalDate']>;
  sat: Scalars['Boolean'];
  source: ScheduleSourceType;
  sun: Scalars['Boolean'];
  thu: Scalars['Boolean'];
  tue: Scalars['Boolean'];
  userId: Scalars['String'];
  wed: Scalars['Boolean'];
};

export enum SettingDataType {
  Boolean = 'BOOLEAN',
  Integer = 'INTEGER',
  Json = 'JSON',
  String = 'STRING'
}

export enum SettingOwnerType {
  User = 'USER'
}

export type SigmaEmbedOptions = {
  /** Predefined account type to use for the given user */
  accountType?: InputMaybe<Scalars['String']>;
  /** Database connection to use for all embed queries */
  evalConnectionId?: InputMaybe<Scalars['String']>;
  /** Hides the folder navigation in the bottom left of the page */
  hideFolderNavigation?: InputMaybe<Scalars['Boolean']>;
  /** Disables the hover tooltip */
  hideTooltip?: InputMaybe<Scalars['Boolean']>;
  /** Allows the iframe to resize and emit height change events */
  responsiveHeight?: InputMaybe<Scalars['Boolean']>;
  /**
   * The number of seconds the embed URL should remain valid after the application embed URL was generated.
   * After the specified number of seconds, the Application Embed will no longer display new values.
   * The maximum session length is 2592000 seconds (30 days).
   */
  sessionLength?: InputMaybe<Scalars['Int']>;
  /** Hides the file explorer, workbook page tabs, and Save As/Edit options */
  showFooter?: InputMaybe<Scalars['Boolean']>;
  /** The team that the Sigma user should be associated with */
  userTeam?: InputMaybe<Scalars['String']>;
};

export type SigmaEmbedUrl = {
  __typename?: 'SigmaEmbedUrl';
  url?: Maybe<Scalars['String']>;
};

export type SigmaFile = {
  __typename?: 'SigmaFile';
  badge: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  parentId: Scalars['String'];
  parentUrlId: Scalars['String'];
  path: Scalars['String'];
  permission: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
  urlId: Scalars['String'];
};

export type SigmaTag = {
  __typename?: 'SigmaTag';
  name: Scalars['String'];
  versionTagId: Scalars['String'];
};

export type SigmaWorkbook = {
  __typename?: 'SigmaWorkbook';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  isArchived: Scalars['Boolean'];
  latestVersion: Scalars['Int'];
  name: Scalars['String'];
  path: Scalars['String'];
  tags?: Maybe<Array<Maybe<SigmaTag>>>;
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
  url: Scalars['String'];
  workbookId: Scalars['String'];
  workbookUrlId: Scalars['String'];
};

export type SimpleLocation = {
  __typename?: 'SimpleLocation';
  accountId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  remoteGoogleId?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
};

export enum SnapTo {
  Fifteen = 'FIFTEEN',
  Sixty = 'SIXTY',
  Thirty = 'THIRTY'
}

/** The order with which to sort */
export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type Space = {
  __typename?: 'Space';
  amenities: Array<SpaceAmenity>;
  /** Availability of space for the next half hour. GREEN: available for the next half hour. YELLOW: event scheduled within the next thirty minutes. RED: Event currently being held */
  availableAt?: Maybe<Availability>;
  behaviors: Array<Scalars['String']>;
  calendar?: Maybe<Calendar>;
  /** Capacity is unsigned 32bit integer. */
  capacity?: Maybe<Scalars['BigInt']>;
  confirmedAvailability?: Maybe<SpaceAvailability>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated because this field is no longer retrievable from API */
  disabledAt?: Maybe<Scalars['Date']>;
  discoveryRadius?: Maybe<Scalars['Float']>;
  filteredDesksByAmenityIds: Array<Desk>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  isAccessible?: Maybe<Scalars['Boolean']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  isManaged: Scalars['Boolean'];
  levelId?: Maybe<Scalars['ID']>;
  locationId: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  reservationPolicies: SpaceReservationPolicies;
  scheduleConfiguration?: Maybe<SpaceScheduleConfiguration>;
  type?: Maybe<SpaceType>;
  updatedAt?: Maybe<Scalars['Date']>;
  windowedAvailability: Array<SpaceWindowAvailability>;
  zones: Array<Zone>;
};


export type SpaceAvailableAtArgs = {
  time?: InputMaybe<Scalars['Date']>;
};


export type SpaceConfirmedAvailabilityArgs = {
  durationInMinutes?: InputMaybe<Scalars['Int']>;
  time?: InputMaybe<Scalars['ISODateTime']>;
};


export type SpaceFilteredDesksByAmenityIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type SpaceWindowedAvailabilityArgs = {
  firstWindowSplit?: InputMaybe<SnapTo>;
  intervalInMinutes?: InputMaybe<Scalars['Int']>;
  meetingDurationInMinutes?: InputMaybe<Scalars['Int']>;
  numberOfWindows?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['Date']>;
};

export type SpaceAmenity = {
  __typename?: 'SpaceAmenity';
  amenityId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export enum SpaceAvailability {
  /** Currently available */
  Available = 'AVAILABLE',
  /** Resource has been booked and confirmed */
  BookedConfirmed = 'BOOKED_CONFIRMED',
  /** Resource has been booked and not confirmed */
  BookedUnconfirmed = 'BOOKED_UNCONFIRMED',
  /** Resource marked as unavailable for booking */
  Unavailable = 'UNAVAILABLE',
  /** Resource schedule not found */
  Unknown = 'UNKNOWN'
}

export enum SpaceFilters {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Scheduling = 'SCHEDULING'
}

export type SpaceReservationPolicies = {
  __typename?: 'SpaceReservationPolicies';
  /** Automatically remove ghost meetings from the room's calendar if nobody checks in. */
  abandonedMeetingProtection: Scalars['Boolean'];
  /** Time to wait before an unconfirmed meeting is canceled. */
  abandonedThreshold: Scalars['ISO8601Duration'];
  /** Restrict how far in advance this space can be booked. */
  advanceBookingThreshold?: Maybe<Scalars['ISO8601Duration']>;
  allowRecurringEvents: Scalars['Boolean'];
  id: Scalars['ID'];
  maxReservationLength?: Maybe<Scalars['ISO8601Duration']>;
  /** Show a check-in button for each meeting */
  meetingCheckins: Scalars['Boolean'];
  restrictBookingToWorkingHours: Scalars['Boolean'];
  /** The number of consecutive abandoned meetings before the space is permanently removed from the series. */
  strikesForRecurringEvents?: Maybe<Scalars['Int']>;
};

export enum SpaceScheduleConfiguration {
  OnDemand = 'ON_DEMAND',
  RequestOnly = 'REQUEST_ONLY',
  Scheduled = 'SCHEDULED'
}

export enum SpaceType {
  Breakout = 'BREAKOUT',
  BreakRoom = 'BREAK_ROOM',
  Cafe = 'CAFE',
  Cafeteria = 'CAFETERIA',
  Call = 'CALL',
  Classroom = 'CLASSROOM',
  Conference = 'CONFERENCE',
  Event = 'EVENT',
  Fitness = 'FITNESS',
  Huddle = 'HUDDLE',
  Interview = 'INTERVIEW',
  Kitchen = 'KITCHEN',
  Lab = 'LAB',
  Lactation = 'LACTATION',
  Listening = 'LISTENING',
  Lobby = 'LOBBY',
  Lounge = 'LOUNGE',
  Meeting = 'MEETING',
  Office = 'OFFICE',
  Other = 'OTHER',
  Parking = 'PARKING',
  Project = 'PROJECT',
  Restroom = 'RESTROOM',
  RestroomFemale = 'RESTROOM_FEMALE',
  RestroomMale = 'RESTROOM_MALE',
  Studio = 'STUDIO',
  StudyRoom = 'STUDY_ROOM',
  Theater = 'THEATER',
  Utility = 'UTILITY',
  Work = 'WORK'
}

export type SpaceWindowAvailability = {
  __typename?: 'SpaceWindowAvailability';
  end?: Maybe<Scalars['Date']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated because the end becomes representing the pillEnd */
  pillEnd?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
  status?: Maybe<Availability>;
  unbookableReasons: Array<Maybe<UnbookableReasonType>>;
};

export type StandardWorkingHours = DateSpecificWorkingHours & {
  __typename?: 'StandardWorkingHours';
  date: Scalars['Date'];
  timeFrames: Array<TimeFrame>;
};

export type Sticker = {
  __typename?: 'Sticker';
  associatedDeskId?: Maybe<Scalars['ID']>;
  batchId: Scalars['ID'];
  /** @deprecated Deprecated due to the potential circular queries that can be made via the fields on this type. Please use the `associatedDeskId` field and make a second query for desk information. */
  desk?: Maybe<Desk>;
  displayCode: Scalars['Int'];
  id: Scalars['ID'];
  shortCode: Scalars['String'];
  shortUrl: Scalars['String'];
};

export type StickerAssignment = {
  __typename?: 'StickerAssignment';
  seatId: Scalars['Int'];
  stickerId: Scalars['ID'];
};

export type StickerAssignmentInput = {
  seatId: Scalars['Int'];
  stickerId: Scalars['ID'];
};

export type StickerBatch = {
  __typename?: 'StickerBatch';
  id: Scalars['ID'];
  stickers: Array<Sticker>;
};

export type SuggestedSpaceForEvent = {
  __typename?: 'SuggestedSpaceForEvent';
  eventId: Scalars['ID'];
  space?: Maybe<Space>;
  suggestedSpaceRecordId?: Maybe<Scalars['String']>;
};

/** Survey configuration */
export type Survey = {
  __typename?: 'Survey';
  /** Boolean value indicating if the survey allows anonymous responses */
  allowAnonymous: Scalars['Boolean'];
  /** List of buildings associated with the survey */
  buildings?: Maybe<Array<Maybe<Location>>>;
  /** Date the survey was created */
  createdAt: Scalars['ISODateTime'];
  /** User that created the survey */
  createdBy: User;
  /** Unique identifier */
  id: Scalars['ID'];
  /** Boolean value indicating if the survey is active */
  isActive: Scalars['Boolean'];
  /** Survey name */
  name: Scalars['String'];
  /** Organization associated with the survey */
  organization: Organization;
  /** Date the survey was last updated */
  updatedAt: Scalars['ISODateTime'];
  /** User that last updated the survey */
  updatedBy: User;
};

/** Survey configuration without building links */
export type SurveyEdge = {
  __typename?: 'SurveyEdge';
  /** Boolean value indicating if the survey allows anonymous responses */
  allowAnonymous: Scalars['Boolean'];
  /** Date the survey was created */
  createdAt: Scalars['ISODateTime'];
  /** User that created the survey */
  createdBy: User;
  /** Unique identifier */
  id: Scalars['ID'];
  /** Boolean value indicating if the survey is active */
  isActive: Scalars['Boolean'];
  /** Survey name */
  name: Scalars['String'];
  /** Organization associated with the survey */
  organization: Organization;
  /** Date the survey was last updated */
  updatedAt: Scalars['ISODateTime'];
  /** User that last updated the survey */
  updatedBy: User;
};

/** Response to a sentiment survey */
export type SurveyResponse = {
  __typename?: 'SurveyResponse';
  /** Building of the reservation */
  building?: Maybe<Location>;
  /** Response comment */
  comment?: Maybe<Scalars['String']>;
  /** Date the response was created */
  createdAt: Scalars['ISODateTime'];
  /** Unique identifier */
  id: Scalars['ID'];
  /** Date the user was in the office */
  inOfficeAt: Scalars['ISODateTime'];
  /** Boolean that indicates if the response was anonymous */
  isAnonymous: Scalars['Boolean'];
  /** Organization associated with the survey response */
  organization: Organization;
  /** Sentiment rating */
  rating?: Maybe<Scalars['Int']>;
  /** Reservation associated with the survey response */
  reservation?: Maybe<Reservation>;
  /** Status of the survey response */
  status: SurveyResponseStatus;
  /** Date the response was submitted */
  submittedAt?: Maybe<Scalars['ISODateTime']>;
  /** Survey that was responded to */
  survey: SurveyEdge;
  /** Date the resopnse was last updated */
  updatedAt: Scalars['ISODateTime'];
  /** User that responded */
  user?: Maybe<User>;
};

/** Survey response rating summary */
export type SurveyResponseRatingSummary = {
  __typename?: 'SurveyResponseRatingSummary';
  /** Total number of negative responses */
  negativeCount: Scalars['Int'];
  /** Total number of neutral responses */
  neutralCount: Scalars['Int'];
  /** Total number of positive responses */
  positiveCount: Scalars['Int'];
  /** Total number of completed responses */
  totalCount: Scalars['Int'];
};

/** The key to sort survey responses by */
export enum SurveyResponseSortKey {
  InOfficeAt = 'IN_OFFICE_AT',
  Rating = 'RATING'
}

/** Status of the survey response */
export enum SurveyResponseStatus {
  Completed = 'COMPLETED',
  Dismissed = 'DISMISSED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

/** Survey response status summary */
export type SurveyResponseStatusSummary = {
  __typename?: 'SurveyResponseStatusSummary';
  /** Total number of COMPLETED responses */
  completedCount: Scalars['Int'];
  /** Total number of DISMISSED responses */
  dismissedCount: Scalars['Int'];
  /** Total number of EXPIRED responses */
  expiredCount: Scalars['Int'];
  /** Total number of PENDING responses */
  pendingCount: Scalars['Int'];
  /** Total number of responses */
  totalCount: Scalars['Int'];
};

export enum SyncState {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  NeverHappened = 'NEVER_HAPPENED'
}

export type TeamsUserSearchResult = {
  __typename?: 'TeamsUserSearchResult';
  avatar?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Theme = {
  __typename?: 'Theme';
  color?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

export type TimeFrame = {
  __typename?: 'TimeFrame';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type TimeFrameInput = {
  end: Scalars['LocalEndTime'];
  start: Scalars['LocalEndTime'];
};

export enum TimeInclusivityType {
  Exclusive = 'EXCLUSIVE',
  Inclusive = 'INCLUSIVE'
}

export type UnassignStickerRequest = {
  stickerId: Scalars['ID'];
};

export type UnauthenticatedGuest = {
  __typename?: 'UnauthenticatedGuest';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/**
 * UnauthenticatedVisitResult represents the visit for a single GuestInvite.
 * The result is "unauthenticated" because it is available to unauthenticated queries.
 *
 * A UnauthenticatedVisitResult is the guest (GuestInvite) along with information about their associated Visit.
 */
export type UnauthenticatedVisitResult = {
  __typename?: 'UnauthenticatedVisitResult';
  arrivalInstructions?: Maybe<Scalars['String']>;
  arrivalLocation: PublicLocationOrDefault;
  arrivalLocationId: Scalars['String'];
  checkinOn?: Maybe<Scalars['String']>;
  checkoutOn?: Maybe<Scalars['String']>;
  customVisitType: Scalars['String'];
  guest: UnauthenticatedGuest;
  healthCheckpointSurveyResponse?: Maybe<GenericHealthCheckpointResponse>;
  host: PublicUserWithEmail;
  /** The ID of the GuestInvite */
  id: Scalars['ID'];
  organization: PublicOrganization;
  startTime: Scalars['ISODateTime'];
  status: GuestInviteStatus;
  /** The ID of the associated visit */
  visitId: Scalars['ID'];
  /** @deprecated replaced by the more flexible customVisitType */
  visitType: VisitType;
};

export enum UnbookableReasonType {
  AdminOnlyBooking = 'ADMIN_ONLY_BOOKING',
  ConflictWithAnotherMeeting = 'CONFLICT_WITH_ANOTHER_MEETING',
  LackingSpaceBookingPermissions = 'LACKING_SPACE_BOOKING_PERMISSIONS',
  OutsideWorkingHours = 'OUTSIDE_WORKING_HOURS',
  OverMaxLength = 'OVER_MAX_LENGTH',
  RecurrenceNotAllowed = 'RECURRENCE_NOT_ALLOWED',
  TooFarInFuture = 'TOO_FAR_IN_FUTURE'
}

export type UnconfirmableReason = {
  __typename?: 'UnconfirmableReason';
  reason: UnconfirmableReasonType;
  value?: Maybe<Scalars['String']>;
};

export enum UnconfirmableReasonType {
  CannotConfirmSeriesMaster = 'CANNOT_CONFIRM_SERIES_MASTER',
  CannotConfirmSharedDesk = 'CANNOT_CONFIRM_SHARED_DESK',
  CheckinDisabled = 'CHECKIN_DISABLED',
  MissingReservationEndTime = 'MISSING_RESERVATION_END_TIME',
  OutsideOfCheckInWindow = 'OUTSIDE_OF_CHECK_IN_WINDOW'
}

export type UpdateActivityInput = {
  activityLocation?: InputMaybe<Scalars['String']>;
  /** Should be a URL */
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end: Scalars['ISODateTime'];
  start: Scalars['ISODateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  virtualLocation?: InputMaybe<Scalars['String']>;
};

export type UpdateDefaultPassInput = {
  isDefaultAccessPassEnabled: Scalars['Boolean'];
};

export type UpdateDeskReservationResponse = {
  __typename?: 'UpdateDeskReservationResponse';
  updatedReservations: Array<UpdatedReservation>;
};

export type UpdateEventError = {
  __typename?: 'UpdateEventError';
  message?: Maybe<Scalars['String']>;
  reason?: Maybe<EventUpdateFailReasons>;
};

/** Reservation response */
export type UpdateEventResponse = {
  __typename?: 'UpdateEventResponse';
  /** If the event has not been updated, an explanation will be provided */
  error?: Maybe<UpdateEventError>;
  /** True if event has been updated */
  eventUpdated: Scalars['Boolean'];
  /** Updated event model */
  updatedEvent?: Maybe<Event>;
};

/** Set a field to null to avoid changing it on the reservation. */
export type UpdateMyReservationDeskAndTimeFrameInput = {
  end?: InputMaybe<DateWithTimezone>;
  reservationId: Scalars['ID'];
  seatId?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<DateWithTimezone>;
};

export type UpdateWorkAreaInput = {
  deskIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateWorkAreasInput = {
  workAreasToCreate: Array<CreateWorkAreaInput>;
  workAreasToDelete: Array<Scalars['ID']>;
  workAreasToUpdate: Array<UpdateWorkAreaInput>;
};

export type UpdatedReservation = {
  __typename?: 'UpdatedReservation';
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  defaultLocation?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of primary_email */
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locationState: LocationState;
  name?: Maybe<Scalars['String']>;
  notificationPreferences: NotificationPreferences;
  officeAccess: Array<OfficeAccess>;
  primaryEmail?: Maybe<Email>;
  reservations: Array<Reservation>;
  reservationsAtLocationGroupedByDateAscending: Array<UserDeskReservationByDate>;
  reservationsGroupedByDate: Array<UserDeskReservationByDate>;
  slug?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  workingHours?: Maybe<Array<Maybe<UserWorkingHour>>>;
};


export type UserLocationStateArgs = {
  campusId?: InputMaybe<Scalars['ID']>;
  endTime: Scalars['Date'];
  locationId: Scalars['ID'];
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
};


export type UserReservationsArgs = {
  endTime: Scalars['Date'];
  startTime: Scalars['Date'];
};


export type UserReservationsAtLocationGroupedByDateAscendingArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
};


export type UserReservationsGroupedByDateArgs = {
  endTime: Scalars['Date'];
  startTime: Scalars['Date'];
};

export type UserDeskReservation = {
  __typename?: 'UserDeskReservation';
  reservations: Array<Reservation>;
  userId: Scalars['ID'];
};

export type UserDeskReservationByDate = {
  __typename?: 'UserDeskReservationByDate';
  date: Scalars['Date'];
  reservations: Array<UserTruncatedReservation>;
};

export type UserGoogleAuth = {
  __typename?: 'UserGoogleAuth';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  userEmail: Scalars['String'];
  userId: Scalars['String'];
};

export type UserInOffice = {
  __typename?: 'UserInOffice';
  deskReservations: Array<Reservation>;
  employeeVisits: Array<EmployeeVisit>;
  userId: Scalars['ID'];
};

export type UserInOfficeByDay = {
  __typename?: 'UserInOfficeByDay';
  date: Scalars['String'];
  userInOffice?: Maybe<UserInOffice>;
};

export type UserNotificationsSetting = {
  __typename?: 'UserNotificationsSetting';
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  values: Array<Maybe<NotificationSettingType>>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  dataType: SettingDataType;
  ownerId: Scalars['ID'];
  ownerType: SettingOwnerType;
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export enum UserSortBy {
  Favorite = 'FAVORITE',
  Name = 'NAME'
}

export enum UserSortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type UserSyncStatus = {
  __typename?: 'UserSyncStatus';
  state: SyncState;
  userCount: Scalars['Int'];
};

export type UserTimeFrame = {
  __typename?: 'UserTimeFrame';
  end?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
};

export type UserTruncatedDeskConfirmation = {
  __typename?: 'UserTruncatedDeskConfirmation';
  confirmedAt?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['ID']>;
  seatReservationId: Scalars['ID'];
};

export type UserTruncatedReservation = {
  __typename?: 'UserTruncatedReservation';
  /** @deprecated Broken, use `truncatedConfirmation` */
  confirmation?: Maybe<DeskConfirmation>;
  endTime?: Maybe<Scalars['String']>;
  healthCheckpointStatus?: Maybe<HealthCheckpointStatus>;
  /** Reservation Id */
  id: Scalars['ID'];
  recurrence?: Maybe<Scalars['String']>;
  reservee: UserTruncatedReservee;
  seat: Desk;
  seriesId?: Maybe<Scalars['ID']>;
  startTime: Scalars['String'];
  truncatedConfirmation?: Maybe<UserTruncatedDeskConfirmation>;
  type: Scalars['String'];
  visibility: DeskReservationVisibility;
};

export type UserTruncatedReservee = {
  __typename?: 'UserTruncatedReservee';
  email: Scalars['String'];
  participationStatus?: Maybe<ParticipationStatus>;
  userId?: Maybe<Scalars['ID']>;
  visitorId?: Maybe<Scalars['ID']>;
};

export type UserWithDefault = {
  __typename?: 'UserWithDefault';
  avatar?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  defaultLocation?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** If true, no user by this ID can be found in the user system */
  isDefault: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Email>;
  slug?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  workingHours?: Maybe<Array<Maybe<UserWorkingHour>>>;
};

export type UserWorkingHour = {
  __typename?: 'UserWorkingHour';
  day?: Maybe<Scalars['Int']>;
  timeFrames: Array<Maybe<UserTimeFrame>>;
};

export enum Visit_Document_Status {
  Archived = 'ARCHIVED',
  Inactive = 'INACTIVE',
  Live = 'LIVE'
}

export type Visit = {
  __typename?: 'Visit';
  /** What does the guest need to do when they arrive?. Default to null */
  arrivalInstructions?: Maybe<Scalars['String']>;
  /** Arrival location */
  arrivalLocation: Location;
  /** ID of the location where the guests should arrive and check-in */
  arrivalLocationId: Scalars['ID'];
  /** Custom reason for this visit */
  customVisitType?: Maybe<Scalars['String']>;
  /** Guest invitations */
  guestInvites: Array<GuestInvite>;
  /** Guests for this visit */
  guests: Array<Guest>;
  /** Host robin user */
  host: UserWithDefault;
  /** ID of user acting as host for this visit */
  hostUserId: Scalars['ID'];
  /** Visit unique identifier */
  id: Scalars['ID'];
  /** Organization */
  organization: Organization;
  /** How was the visit created? */
  organizer: VisitOrganizer;
  /** Start time of visit represented by ISO 8601 date-time */
  startTime: Scalars['ISODateTime'];
  /**
   * Reason for this visit
   * @deprecated replaced by the more flexible customVisitType
   */
  visitType: VisitType;
};

export enum VisitAsyncRequestReport {
  Done = 'DONE',
  Error = 'ERROR',
  HcpCreated = 'HCP_CREATED',
  VisitCreated = 'VISIT_CREATED'
}

export enum VisitAsyncRequestStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  RequestReceived = 'REQUEST_RECEIVED'
}

export type VisitAsyncRequestStatusResponse = {
  __typename?: 'VisitAsyncRequestStatusResponse';
  errorText: Array<Scalars['String']>;
  status?: Maybe<VisitAsyncRequestStatus>;
};

export type VisitDocumentAgreementOutcome = {
  __typename?: 'VisitDocumentAgreementOutcome';
  id: Scalars['ID'];
  outcome: VisitDocumentAgreementStatus;
};

export type VisitDocumentAgreementResponse = {
  __typename?: 'VisitDocumentAgreementResponse';
  completionStatus: VisitDocumentCompletionStatus;
  documentBody: Scalars['DocumentBody'];
  documentName: Scalars['String'];
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  possibleOutcomes: Array<VisitDocumentAgreementOutcome>;
  selectedOutcome?: Maybe<VisitDocumentAgreementOutcome>;
};

export enum VisitDocumentAgreementStatus {
  Accept = 'ACCEPT'
}

export type VisitDocumentAgreementWithNextDocumentResponse = {
  __typename?: 'VisitDocumentAgreementWithNextDocumentResponse';
  completionStatus: VisitDocumentCompletionStatus;
  documentBody: Scalars['DocumentBody'];
  documentName: Scalars['String'];
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  /** If NULL, there is no next document */
  nextDocumentId?: Maybe<Scalars['ID']>;
  possibleOutcomes: Array<VisitDocumentAgreementOutcome>;
  selectedOutcome?: Maybe<VisitDocumentAgreementOutcome>;
  workflowId: Scalars['ID'];
};

export type VisitDocumentByDecisionResponse = {
  __typename?: 'VisitDocumentByDecisionResponse';
  /** Date and time the document was accepted for specified decision */
  agreedOn: Scalars['ISODateTime'];
  /** Document body */
  body: Scalars['DocumentBody'];
  /** ID of document associated with descition */
  id: Scalars['ID'];
  /** Document name */
  name: Scalars['String'];
  /** Email addresses of individuals notified via email */
  notificationEmails: Array<Scalars['String']>;
  /** Associated visit model */
  visit: VisitOrDefault;
};

export enum VisitDocumentCompletionStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export enum VisitDocumentNameStatusAndTypesField {
  LocationId = 'LOCATION_ID',
  Status = 'STATUS',
  VisitType = 'VISIT_TYPE'
}

export type VisitDocumentNameStatusAndTypesFilter = {
  filterOn: VisitDocumentNameStatusAndTypesField;
  filterValues: Array<Scalars['String']>;
};

export type VisitDocumentNameStatusAndTypesQueryResponse = {
  __typename?: 'VisitDocumentNameStatusAndTypesQueryResponse';
  /** ISO encoded timestamp representing date/time when this document was added */
  addedOn: Scalars['ISODateTime'];
  id: Scalars['ID'];
  /** Name of the document */
  name: Scalars['String'];
  /** Association status of this document to active or archived workflows */
  status: Visit_Document_Status;
  /** The types of visits, if any, which are actively associated with this document */
  visitTypes: Array<Scalars['String']>;
};

export type VisitDocumentResponse = {
  __typename?: 'VisitDocumentResponse';
  /** The full body text of the document. */
  documentBody: Scalars['String'];
  /** The unique name of the document */
  documentName: Scalars['String'];
  id: Scalars['ID'];
  /** The locations which are associated with this document */
  locationIds: Array<Scalars['String']>;
  /** Notification emails associated with document */
  notificationEmails: Array<Scalars['String']>;
  /** Association status of this document to active or archived workflows */
  status: Visit_Document_Status;
  /** The types of visits which are associated with this document */
  visitTypes: Array<Scalars['String']>;
};

export enum VisitEventAcknowledgement {
  VisitStartingSoon = 'VISIT_STARTING_SOON'
}

export type VisitInputType = {
  /** What does the guest need to do when they arrive?. Default to null */
  arrivalInstructions?: InputMaybe<Scalars['String']>;
  /** ID of the location where the guests should arrive and check-in */
  arrivalLocationId: Scalars['ID'];
  /** ID of user acting as host for this visit */
  hostUserId: Scalars['ID'];
  /** Start time of visit represented by ISO 8601 date-time */
  startTime: Scalars['ISODateTime'];
  /** Reason for this visit */
  visitType: VisitType;
};

export type VisitMagicLinkAuthResponse = {
  __typename?: 'VisitMagicLinkAuthResponse';
  id: Scalars['ID'];
  tenantId: Scalars['String'];
  token: Scalars['String'];
  type: Scalars['String'];
};

export type VisitOrDefault = {
  __typename?: 'VisitOrDefault';
  /** What does the guest need to do when they arrive?. Default to null */
  arrivalInstructions?: Maybe<Scalars['String']>;
  /** Arrival location */
  arrivalLocation?: Maybe<Location>;
  /** Visit unique identifier */
  arrivalLocationId?: Maybe<Scalars['ID']>;
  /** Guest invitations */
  guestInvites?: Maybe<Array<GuestInvite>>;
  /** Guests for this visit */
  guests?: Maybe<Array<Guest>>;
  /** Host robin user */
  host?: Maybe<UserWithDefault>;
  /** ID of user acting as host for this visit */
  hostUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  /** Organization */
  organization?: Maybe<Organization>;
  /** How was the visit created? */
  organizer?: Maybe<VisitOrganizer>;
  /** Start time of visit represented by ISO 8601 date-time */
  startTime?: Maybe<Scalars['ISODateTime']>;
  /** Reason for this visit */
  visitType?: Maybe<VisitType>;
};

export type VisitOrganizer = {
  __typename?: 'VisitOrganizer';
  /** The arrival display that the visit was created on, if exists */
  arrivalDisplay?: Maybe<PublicArrivalDisplay>;
  /** The ID of the entity from where the visit was created */
  id: Scalars['ID'];
  /** How the visit was created */
  type: VisitOrganizerType;
};

export enum VisitOrganizerType {
  ArrivalDisplay = 'ARRIVAL_DISPLAY',
  User = 'USER'
}

export enum VisitType {
  CustomerVisit = 'CUSTOMER_VISIT',
  Event = 'EVENT',
  JobInterview = 'JOB_INTERVIEW',
  Maintenance = 'MAINTENANCE',
  Other = 'OTHER',
  VipVisit = 'VIP_VISIT'
}

export type VisitWithIdInputType = {
  /** ID of the location where the guests should arrive and check-in */
  arrivalLocationId: Scalars['ID'];
  /** ID of user acting as host for this visit */
  hostUserId: Scalars['ID'];
  /** ID of the visit */
  id: Scalars['ID'];
  /** Start time of visit represented by ISO 8601 date-time */
  startTime: Scalars['ISODateTime'];
  /** Reason for this visit */
  visitType: VisitType;
};

export type VisitWorkflowOverview = {
  __typename?: 'VisitWorkflowOverview';
  /** Workflow documents */
  documents: Array<WorkflowDocumentOverviewForVisitor>;
  /** Workflow ID */
  id: Scalars['ID'];
};

export type WeeklyScheduleResult = {
  __typename?: 'WeeklyScheduleResult';
  fri: Scalars['Boolean'];
  id: Scalars['String'];
  instanceEndTime: Scalars['UtcTime'];
  instanceStartTime: Scalars['UtcTime'];
  mon: Scalars['Boolean'];
  sat: Scalars['Boolean'];
  sun: Scalars['Boolean'];
  thu: Scalars['Boolean'];
  tue: Scalars['Boolean'];
  wed: Scalars['Boolean'];
};

export type WhosInData = {
  __typename?: 'WhosInData';
  byLocation?: Maybe<Array<WhosInDataByLocation>>;
  totalNumberOfWhosIn: Scalars['Int'];
  whosIn?: Maybe<Array<WhosInUser>>;
};


export type WhosInDataTotalNumberOfWhosInArgs = {
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type WhosInDataWhosInArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  preferredUserIds?: InputMaybe<Array<Scalars['ID']>>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type WhosInDataByDay = {
  __typename?: 'WhosInDataByDay';
  date: Scalars['ISODateTime'];
  whosInData?: Maybe<WhosInData>;
  /** @deprecated Deprecated in favor of whosInData.byLocation */
  whosInDataByLocation?: Maybe<Array<WhosInDataByLocation>>;
};

export type WhosInDataByLocation = {
  __typename?: 'WhosInDataByLocation';
  locationId: Scalars['ID'];
  totalNumberOfWhosIn: Scalars['Int'];
  whosIn?: Maybe<Array<WhosInUser>>;
  /** @deprecated Deprecated in favor of totalNumberOfWhosIn and whosIn */
  whosInData: WhosInData;
};


export type WhosInDataByLocationWhosInArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  preferredUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type WhosInUser = {
  __typename?: 'WhosInUser';
  deskReservationIds: Array<Scalars['String']>;
  employeeVisitId?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  locationId: Scalars['ID'];
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type WindowAvailability = {
  __typename?: 'WindowAvailability';
  end?: Maybe<Scalars['Date']>;
  /** @deprecated Deprecated because the end becomes representing the pillEnd */
  pillEnd?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['Date']>;
  status?: Maybe<Availability>;
};

export type WorkArea = {
  __typename?: 'WorkArea';
  /** Total desks within the level that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  desks: Array<Desk>;
  id: Scalars['ID'];
  level: Level;
  name: Scalars['String'];
};

export type WorkPolicy = {
  __typename?: 'WorkPolicy';
  createdAt?: Maybe<Scalars['ISODateTime']>;
  id: Scalars['ID'];
  interval: WorkPolicyInterval;
  isDefault: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rank?: Maybe<Scalars['Int']>;
  status: WorkPolicyStatus;
  tooltipMessage?: Maybe<Scalars['String']>;
};

export type WorkPolicyInput = {
  interval: WorkPolicyIntervalInput;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status: WorkPolicyStatus;
  tooltipMessage?: InputMaybe<Scalars['String']>;
};

export type WorkPolicyInterval = {
  __typename?: 'WorkPolicyInterval';
  frequency?: Maybe<Scalars['Int']>;
  intervalType: WorkPolicyIntervalType;
  specificDays?: Maybe<Array<Scalars['Int']>>;
};

export type WorkPolicyIntervalInput = {
  frequency?: InputMaybe<Scalars['Int']>;
  intervalType: WorkPolicyIntervalType;
  specificDays?: InputMaybe<Array<Scalars['Int']>>;
};

export enum WorkPolicyIntervalType {
  Monthly = 'MONTHLY',
  SpecificDays = 'SPECIFIC_DAYS',
  Weekly = 'WEEKLY'
}

export enum WorkPolicyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Scheduled = 'SCHEDULED'
}

export type WorkPolicyWithProgress = {
  __typename?: 'WorkPolicyWithProgress';
  id: Scalars['ID'];
  interval: WorkPolicyInterval;
  message?: Maybe<Scalars['String']>;
  minimumDaysProgress?: Maybe<Scalars['Int']>;
  /** @deprecated Use minimumDaysProgress instead. */
  progress: Scalars['Int'];
  specificDaysProgress?: Maybe<Array<Scalars['Int']>>;
  tooltipMessage?: Maybe<Scalars['String']>;
};

export type WorkflowDocumentOverviewForVisitor = {
  __typename?: 'WorkflowDocumentOverviewForVisitor';
  /** Is document complete for this visit */
  completionStatus: VisitDocumentCompletionStatus;
  id: Scalars['ID'];
  /** Is document required in workflow */
  isRequired: Scalars['Boolean'];
  /** Name of the document */
  name: Scalars['String'];
};

export type WorkingHours = {
  __typename?: 'WorkingHours';
  day?: Maybe<Scalars['Int']>;
  timeFrames: Array<TimeFrame>;
};

export type WorkingHoursInput = {
  day?: InputMaybe<DayOfWeek>;
  timeFrames: Array<TimeFrameInput>;
};

/** A zone is an area within a space that represents a collection of desks or a table */
export type Zone = {
  __typename?: 'Zone';
  desks: Array<Desk>;
  filteredDesksByAmenityIds: Array<Desk>;
  /** Zone ID is a unique identifier for the zone */
  id: Scalars['ID'];
  /** Name of the zone */
  name: Scalars['String'];
  /** Identifies the type of this zone. Zones can be Pods or Table */
  type: ZoneType;
};


/** A zone is an area within a space that represents a collection of desks or a table */
export type ZoneDesksArgs = {
  filters?: InputMaybe<Array<DeskFilters>>;
};


/** A zone is an area within a space that represents a collection of desks or a table */
export type ZoneFilteredDesksByAmenityIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type ZoneDeskReservation = {
  __typename?: 'ZoneDeskReservation';
  reservations: Array<Reservation>;
  zoneId: Scalars['ID'];
};

/** Describes the type of zone */
export enum ZoneType {
  /** A pod is a collection of desks */
  Pod = 'POD',
  /** A zone with the table type is typically a large table with many seats */
  Table = 'TABLE'
}

export type AuthContextQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthContextQuery = { __typename?: 'Query', getCurrentUserAndOrgs?: { __typename?: 'CurrentUserAndOrgs', userId: string, user: { __typename?: 'User', id: string, name?: string | null, timeZone?: string | null, defaultLocation?: string | null, avatar?: string | null, primaryEmail?: { __typename?: 'Email', email: string } | null }, organizations: Array<{ __typename?: 'Organization', id: string, name?: string | null, slug?: string | null, avatar?: string | null }> } | null };

export type CancelEmployeeVisitMutationMutationVariables = Exact<{
  employeeVisitId: Scalars['ID'];
}>;


export type CancelEmployeeVisitMutationMutation = { __typename?: 'Mutation', cancelEmployeeVisit?: any | null };

export type EndOrCancelReservationMutationMutationVariables = Exact<{
  reservationId: Scalars['ID'];
}>;


export type EndOrCancelReservationMutationMutation = { __typename?: 'Mutation', endOrCancelReservation: { __typename?: 'EndOrCancelReservationResponse', reservationId: string } };

export type GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQueryVariables = Exact<{
  userIds: Array<Scalars['ID']> | Scalars['ID'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  endTime: Scalars['ISODateTime'];
}>;


export type GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery = { __typename?: 'Query', getDeskReservationsForUsersAtLocationGroupedByDateAscending: { __typename?: 'DateGroupedUserReservations', reservationsByDate: Array<{ __typename?: 'UserDeskReservationByDate', date: any, reservations: Array<{ __typename?: 'UserTruncatedReservation', id: string, type: string, startTime: string, endTime?: string | null, visibility: DeskReservationVisibility, healthCheckpointStatus?: HealthCheckpointStatus | null, reservee: { __typename?: 'UserTruncatedReservee', userId?: string | null, participationStatus?: ParticipationStatus | null }, truncatedConfirmation?: { __typename?: 'UserTruncatedDeskConfirmation', seatReservationId: string } | null, seat: { __typename?: 'Desk', name: string, level?: { __typename?: 'Level', id: string, name: string } | null, location: { __typename?: 'Location', name: string }, zone?: { __typename?: 'Zone', name: string } | null } }> }>, users: Array<{ __typename?: 'Reservee', user?: { __typename?: 'User', id: string, givenName?: string | null } | null }> } };

export type GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQueryVariables = Exact<{
  userIds: Array<Scalars['ID']> | Scalars['ID'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  endTime: Scalars['ISODateTime'];
}>;


export type GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery = { __typename?: 'Query', getEmployeeVisitsForUsersAtLocationGroupedByDateAscending: Array<{ __typename?: 'EmployeeVisitsByDate', date: any, employeeVisits: Array<{ __typename?: 'EmployeeVisit', id: string, startTime: any, endTime: any, userId: string, locationId: string }> }> };

export type GetLocationsByOrgIdQueryVariables = Exact<{
  id: Scalars['ID'];
  userId: Scalars['ID'];
  date?: InputMaybe<Scalars['Date']>;
}>;


export type GetLocationsByOrgIdQuery = { __typename?: 'Query', getOrganizationById?: { __typename?: 'Organization', id: string, name?: string | null, locations: Array<{ __typename?: 'Location', id: string, name: string, timezone?: string | null, totalSpaces: number, latitude?: number | null, longitude?: number | null, campus?: { __typename?: 'Campus', id: string, name: string } | null, workingHours: Array<{ __typename?: 'WorkingHours', day?: number | null, timeFrames: Array<{ __typename?: 'TimeFrame', start: string, end: string }> }>, customWorkingHours: Array<{ __typename?: 'CustomWorkingHours', id: string, date: any, timeFrames: Array<{ __typename?: 'TimeFrame', start: string, end: string }> }>, levels: Array<{ __typename?: 'Level', id: string, zones: Array<{ __typename?: 'Zone', id: string, name: string }> }>, officeAccess: Array<{ __typename?: 'OfficeAccess', id: string, schedule: Array<string> }> }> } | null };

export type JoinOfficeMutationVariables = Exact<{
  userId: Scalars['ID'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  endTime: Scalars['ISODateTime'];
  type: DeskConfirmationType;
}>;


export type JoinOfficeMutation = { __typename?: 'Mutation', joinOffice?: any | null };

export type GetDeskReservationsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  startTime: Scalars['Date'];
  endTime: Scalars['Date'];
}>;


export type GetDeskReservationsQuery = { __typename?: 'Query', getDeskReservationsByUserIds: Array<{ __typename?: 'UserDeskReservation', userId: string, reservations: Array<{ __typename?: 'Reservation', id: string, timeZone?: string | null, startTime: string, visibility: DeskReservationVisibility, type: string, seat: { __typename?: 'Desk', id: string, name: string, type: BookingPolicyType, rawType: Array<DeskType>, zone?: { __typename?: 'Zone', id: string, name: string } | null, level?: { __typename?: 'Level', id: string } | null } }> }> };

export type GetIsFavoritesEnabledQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIsFavoritesEnabledQuery = { __typename?: 'Query', isFavoritesEnabled: boolean };

export type GetFeatureFlagQueryVariables = Exact<{
  flagType?: InputMaybe<FeatureFlagType>;
}>;


export type GetFeatureFlagQuery = { __typename?: 'Query', isFeatureFlagEnabledForOrgOrMe: boolean };

export type GetPagedUsersQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<PagedUserFilterInput>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetPagedUsersQuery = { __typename?: 'Query', getPagedUsers: { __typename?: 'PagedUserResult', hasNextPage: boolean, nextPageOffset: number, accountUsersTotal: number, accountUsers: Array<{ __typename?: 'AccountUser', id: string, name?: string | null, isMyFavorite?: boolean | null, avatar?: string | null, department?: string | null, defaultLocation?: { __typename?: 'Location', id: string, name: string } | null, primaryEmail?: { __typename?: 'Email', email: string } | null }> } };

export type GetPagedUsersV2QueryVariables = Exact<{
  organizationId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<PagedUserFilterInput>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetPagedUsersV2Query = { __typename?: 'Query', getPagedUsersV2: { __typename?: 'PagedUserResult', hasNextPage: boolean, nextPageOffset: number, accountUsersTotal: number, accountUsers: Array<{ __typename?: 'AccountUser', id: string, name?: string | null, isMyFavorite?: boolean | null, avatar?: string | null, department?: string | null, defaultLocation?: { __typename?: 'Location', id: string, name: string } | null, primaryEmail?: { __typename?: 'Email', email: string } | null }> } };

export type AddUserToFavoritesMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type AddUserToFavoritesMutation = { __typename?: 'Mutation', addUserToFavorites?: any | null };

export type RemoveUserFromFavoritesMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type RemoveUserFromFavoritesMutation = { __typename?: 'Mutation', removeUserFromFavorites?: any | null };

export type GetUserLocaleQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserLocaleQuery = { __typename?: 'Query', getMyLanguageTag: { __typename?: 'MyLanguageTagResponse', languageTag?: string | null }, getUserSetting?: { __typename?: 'UserSetting', value?: string | null } | null };


export const AuthContextDocument = gql`
    query AuthContext {
  getCurrentUserAndOrgs {
    userId
    user {
      id
      name
      timeZone
      defaultLocation
      avatar
      primaryEmail {
        email
      }
    }
    organizations {
      id
      name
      slug
      avatar
    }
  }
}
    `;

/**
 * __useAuthContextQuery__
 *
 * To run a query within a React component, call `useAuthContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthContextQuery(baseOptions?: Apollo.QueryHookOptions<AuthContextQuery, AuthContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthContextQuery, AuthContextQueryVariables>(AuthContextDocument, options);
      }
export function useAuthContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthContextQuery, AuthContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthContextQuery, AuthContextQueryVariables>(AuthContextDocument, options);
        }
export type AuthContextQueryHookResult = ReturnType<typeof useAuthContextQuery>;
export type AuthContextLazyQueryHookResult = ReturnType<typeof useAuthContextLazyQuery>;
export type AuthContextQueryResult = Apollo.QueryResult<AuthContextQuery, AuthContextQueryVariables>;
export const CancelEmployeeVisitMutationDocument = gql`
    mutation CancelEmployeeVisitMutation($employeeVisitId: ID!) {
  cancelEmployeeVisit(employeeVisitId: $employeeVisitId)
}
    `;
export type CancelEmployeeVisitMutationMutationFn = Apollo.MutationFunction<CancelEmployeeVisitMutationMutation, CancelEmployeeVisitMutationMutationVariables>;

/**
 * __useCancelEmployeeVisitMutationMutation__
 *
 * To run a mutation, you first call `useCancelEmployeeVisitMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEmployeeVisitMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEmployeeVisitMutationMutation, { data, loading, error }] = useCancelEmployeeVisitMutationMutation({
 *   variables: {
 *      employeeVisitId: // value for 'employeeVisitId'
 *   },
 * });
 */
export function useCancelEmployeeVisitMutationMutation(baseOptions?: Apollo.MutationHookOptions<CancelEmployeeVisitMutationMutation, CancelEmployeeVisitMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelEmployeeVisitMutationMutation, CancelEmployeeVisitMutationMutationVariables>(CancelEmployeeVisitMutationDocument, options);
      }
export type CancelEmployeeVisitMutationMutationHookResult = ReturnType<typeof useCancelEmployeeVisitMutationMutation>;
export type CancelEmployeeVisitMutationMutationResult = Apollo.MutationResult<CancelEmployeeVisitMutationMutation>;
export type CancelEmployeeVisitMutationMutationOptions = Apollo.BaseMutationOptions<CancelEmployeeVisitMutationMutation, CancelEmployeeVisitMutationMutationVariables>;
export const EndOrCancelReservationMutationDocument = gql`
    mutation EndOrCancelReservationMutation($reservationId: ID!) {
  endOrCancelReservation(reservationId: $reservationId) {
    reservationId
  }
}
    `;
export type EndOrCancelReservationMutationMutationFn = Apollo.MutationFunction<EndOrCancelReservationMutationMutation, EndOrCancelReservationMutationMutationVariables>;

/**
 * __useEndOrCancelReservationMutationMutation__
 *
 * To run a mutation, you first call `useEndOrCancelReservationMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndOrCancelReservationMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endOrCancelReservationMutationMutation, { data, loading, error }] = useEndOrCancelReservationMutationMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useEndOrCancelReservationMutationMutation(baseOptions?: Apollo.MutationHookOptions<EndOrCancelReservationMutationMutation, EndOrCancelReservationMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndOrCancelReservationMutationMutation, EndOrCancelReservationMutationMutationVariables>(EndOrCancelReservationMutationDocument, options);
      }
export type EndOrCancelReservationMutationMutationHookResult = ReturnType<typeof useEndOrCancelReservationMutationMutation>;
export type EndOrCancelReservationMutationMutationResult = Apollo.MutationResult<EndOrCancelReservationMutationMutation>;
export type EndOrCancelReservationMutationMutationOptions = Apollo.BaseMutationOptions<EndOrCancelReservationMutationMutation, EndOrCancelReservationMutationMutationVariables>;
export const GetDeskReservationsForUsersAtLocationGroupedByDateAscendingDocument = gql`
    query GetDeskReservationsForUsersAtLocationGroupedByDateAscending($userIds: [ID!]!, $locationId: ID!, $startTime: ISODateTime!, $endTime: ISODateTime!) {
  getDeskReservationsForUsersAtLocationGroupedByDateAscending(
    userIds: $userIds
    locationId: $locationId
    startTime: $startTime
    endTime: $endTime
  ) {
    reservationsByDate {
      date
      reservations {
        id
        type
        startTime
        endTime
        visibility
        healthCheckpointStatus
        reservee {
          userId
          participationStatus
        }
        truncatedConfirmation {
          seatReservationId
        }
        seat {
          level {
            id
            name
          }
          location {
            name
          }
          name
          zone {
            name
          }
        }
      }
    }
    users {
      user {
        id
        givenName
      }
    }
  }
}
    `;

/**
 * __useGetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery__
 *
 * To run a query within a React component, call `useGetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      locationId: // value for 'locationId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery(baseOptions: Apollo.QueryHookOptions<GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery, GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery, GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQueryVariables>(GetDeskReservationsForUsersAtLocationGroupedByDateAscendingDocument, options);
      }
export function useGetDeskReservationsForUsersAtLocationGroupedByDateAscendingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery, GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery, GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQueryVariables>(GetDeskReservationsForUsersAtLocationGroupedByDateAscendingDocument, options);
        }
export type GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQueryHookResult = ReturnType<typeof useGetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery>;
export type GetDeskReservationsForUsersAtLocationGroupedByDateAscendingLazyQueryHookResult = ReturnType<typeof useGetDeskReservationsForUsersAtLocationGroupedByDateAscendingLazyQuery>;
export type GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQueryResult = Apollo.QueryResult<GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery, GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQueryVariables>;
export const GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingDocument = gql`
    query GetEmployeeVisitsForUsersAtLocationGroupedByDateAscending($userIds: [ID!]!, $locationId: ID!, $startTime: ISODateTime!, $endTime: ISODateTime!) {
  getEmployeeVisitsForUsersAtLocationGroupedByDateAscending(
    userIds: $userIds
    locationId: $locationId
    startTime: $startTime
    endTime: $endTime
  ) {
    date
    employeeVisits {
      id
      startTime
      endTime
      userId
      locationId
    }
  }
}
    `;

/**
 * __useGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      locationId: // value for 'locationId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery, GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery, GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQueryVariables>(GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingDocument, options);
      }
export function useGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery, GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery, GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQueryVariables>(GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingDocument, options);
        }
export type GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQueryHookResult = ReturnType<typeof useGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery>;
export type GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingLazyQueryHookResult = ReturnType<typeof useGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingLazyQuery>;
export type GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQueryResult = Apollo.QueryResult<GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery, GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQueryVariables>;
export const GetLocationsByOrgIdDocument = gql`
    query getLocationsByOrgId($id: ID!, $userId: ID!, $date: Date) {
  getOrganizationById(id: $id) {
    id
    name
    locations {
      id
      name
      timezone
      totalSpaces
      latitude
      longitude
      campus {
        id
        name
      }
      workingHours {
        day
        timeFrames {
          start
          end
        }
      }
      customWorkingHours(startFromDateTime: $date) {
        id
        date
        timeFrames {
          start
          end
        }
      }
      levels {
        id
        zones {
          id
          name
        }
      }
      officeAccess(userId: $userId) {
        id
        schedule
      }
    }
  }
}
    `;

/**
 * __useGetLocationsByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetLocationsByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsByOrgIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetLocationsByOrgIdQuery(baseOptions: Apollo.QueryHookOptions<GetLocationsByOrgIdQuery, GetLocationsByOrgIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsByOrgIdQuery, GetLocationsByOrgIdQueryVariables>(GetLocationsByOrgIdDocument, options);
      }
export function useGetLocationsByOrgIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsByOrgIdQuery, GetLocationsByOrgIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsByOrgIdQuery, GetLocationsByOrgIdQueryVariables>(GetLocationsByOrgIdDocument, options);
        }
export type GetLocationsByOrgIdQueryHookResult = ReturnType<typeof useGetLocationsByOrgIdQuery>;
export type GetLocationsByOrgIdLazyQueryHookResult = ReturnType<typeof useGetLocationsByOrgIdLazyQuery>;
export type GetLocationsByOrgIdQueryResult = Apollo.QueryResult<GetLocationsByOrgIdQuery, GetLocationsByOrgIdQueryVariables>;
export const JoinOfficeDocument = gql`
    mutation JoinOffice($userId: ID!, $locationId: ID!, $startTime: ISODateTime!, $endTime: ISODateTime!, $type: DeskConfirmationType!) {
  joinOffice(
    userId: $userId
    locationId: $locationId
    startTime: $startTime
    endTime: $endTime
    type: $type
  )
}
    `;
export type JoinOfficeMutationFn = Apollo.MutationFunction<JoinOfficeMutation, JoinOfficeMutationVariables>;

/**
 * __useJoinOfficeMutation__
 *
 * To run a mutation, you first call `useJoinOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinOfficeMutation, { data, loading, error }] = useJoinOfficeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      locationId: // value for 'locationId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useJoinOfficeMutation(baseOptions?: Apollo.MutationHookOptions<JoinOfficeMutation, JoinOfficeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinOfficeMutation, JoinOfficeMutationVariables>(JoinOfficeDocument, options);
      }
export type JoinOfficeMutationHookResult = ReturnType<typeof useJoinOfficeMutation>;
export type JoinOfficeMutationResult = Apollo.MutationResult<JoinOfficeMutation>;
export type JoinOfficeMutationOptions = Apollo.BaseMutationOptions<JoinOfficeMutation, JoinOfficeMutationVariables>;
export const GetDeskReservationsDocument = gql`
    query GetDeskReservations($ids: [ID!]!, $startTime: Date!, $endTime: Date!) {
  getDeskReservationsByUserIds(
    ids: $ids
    startTime: $startTime
    endTime: $endTime
  ) {
    userId
    reservations {
      id
      timeZone
      startTime
      visibility
      seat {
        id
        name
        type
        zone {
          id
          name
        }
        level {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetDeskReservationsQuery__
 *
 * To run a query within a React component, call `useGetDeskReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeskReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeskReservationsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetDeskReservationsQuery(baseOptions: Apollo.QueryHookOptions<GetDeskReservationsQuery, GetDeskReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeskReservationsQuery, GetDeskReservationsQueryVariables>(GetDeskReservationsDocument, options);
      }
export function useGetDeskReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeskReservationsQuery, GetDeskReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeskReservationsQuery, GetDeskReservationsQueryVariables>(GetDeskReservationsDocument, options);
        }
export type GetDeskReservationsQueryHookResult = ReturnType<typeof useGetDeskReservationsQuery>;
export type GetDeskReservationsLazyQueryHookResult = ReturnType<typeof useGetDeskReservationsLazyQuery>;
export type GetDeskReservationsQueryResult = Apollo.QueryResult<GetDeskReservationsQuery, GetDeskReservationsQueryVariables>;
export const GetIsFavoritesEnabledDocument = gql`
    query getIsFavoritesEnabled {
  isFavoritesEnabled
}
    `;

/**
 * __useGetIsFavoritesEnabledQuery__
 *
 * To run a query within a React component, call `useGetIsFavoritesEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsFavoritesEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsFavoritesEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsFavoritesEnabledQuery(baseOptions?: Apollo.QueryHookOptions<GetIsFavoritesEnabledQuery, GetIsFavoritesEnabledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIsFavoritesEnabledQuery, GetIsFavoritesEnabledQueryVariables>(GetIsFavoritesEnabledDocument, options);
      }
export function useGetIsFavoritesEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsFavoritesEnabledQuery, GetIsFavoritesEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIsFavoritesEnabledQuery, GetIsFavoritesEnabledQueryVariables>(GetIsFavoritesEnabledDocument, options);
        }
export type GetIsFavoritesEnabledQueryHookResult = ReturnType<typeof useGetIsFavoritesEnabledQuery>;
export type GetIsFavoritesEnabledLazyQueryHookResult = ReturnType<typeof useGetIsFavoritesEnabledLazyQuery>;
export type GetIsFavoritesEnabledQueryResult = Apollo.QueryResult<GetIsFavoritesEnabledQuery, GetIsFavoritesEnabledQueryVariables>;
export const GetFeatureFlagDocument = gql`
    query getFeatureFlag($flagType: FeatureFlagType) {
  isFeatureFlagEnabledForOrgOrMe(flagType: $flagType)
}
    `;

/**
 * __useGetFeatureFlagQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagQuery({
 *   variables: {
 *      flagType: // value for 'flagType'
 *   },
 * });
 */
export function useGetFeatureFlagQuery(baseOptions?: Apollo.QueryHookOptions<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, options);
      }
export function useGetFeatureFlagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(GetFeatureFlagDocument, options);
        }
export type GetFeatureFlagQueryHookResult = ReturnType<typeof useGetFeatureFlagQuery>;
export type GetFeatureFlagLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagLazyQuery>;
export type GetFeatureFlagQueryResult = Apollo.QueryResult<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>;
export const GetPagedUsersDocument = gql`
    query getPagedUsers($organizationId: ID!, $limit: Int, $filters: PagedUserFilterInput, $offset: Int) {
  getPagedUsers(
    organizationId: $organizationId
    limit: $limit
    filters: $filters
    offset: $offset
  ) {
    hasNextPage
    nextPageOffset
    accountUsersTotal
    accountUsers {
      id
      name
      isMyFavorite
      defaultLocation {
        id
        name
      }
      avatar
      isMyFavorite
      department
      primaryEmail {
        email
      }
    }
  }
}
    `;

/**
 * __useGetPagedUsersQuery__
 *
 * To run a query within a React component, call `useGetPagedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedUsersQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPagedUsersQuery(baseOptions: Apollo.QueryHookOptions<GetPagedUsersQuery, GetPagedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedUsersQuery, GetPagedUsersQueryVariables>(GetPagedUsersDocument, options);
      }
export function useGetPagedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedUsersQuery, GetPagedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedUsersQuery, GetPagedUsersQueryVariables>(GetPagedUsersDocument, options);
        }
export type GetPagedUsersQueryHookResult = ReturnType<typeof useGetPagedUsersQuery>;
export type GetPagedUsersLazyQueryHookResult = ReturnType<typeof useGetPagedUsersLazyQuery>;
export type GetPagedUsersQueryResult = Apollo.QueryResult<GetPagedUsersQuery, GetPagedUsersQueryVariables>;
export const GetPagedUsersV2Document = gql`
    query getPagedUsersV2($organizationId: ID!, $limit: Int, $filters: PagedUserFilterInput, $offset: Int) {
  getPagedUsersV2(
    organizationId: $organizationId
    limit: $limit
    filters: $filters
    offset: $offset
  ) {
    hasNextPage
    nextPageOffset
    accountUsersTotal
    accountUsers {
      id
      name
      isMyFavorite
      defaultLocation {
        id
        name
      }
      avatar
      isMyFavorite
      department
      primaryEmail {
        email
      }
    }
  }
}
    `;

/**
 * __useGetPagedUsersV2Query__
 *
 * To run a query within a React component, call `useGetPagedUsersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetPagedUsersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagedUsersV2Query({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPagedUsersV2Query(baseOptions: Apollo.QueryHookOptions<GetPagedUsersV2Query, GetPagedUsersV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagedUsersV2Query, GetPagedUsersV2QueryVariables>(GetPagedUsersV2Document, options);
      }
export function useGetPagedUsersV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagedUsersV2Query, GetPagedUsersV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagedUsersV2Query, GetPagedUsersV2QueryVariables>(GetPagedUsersV2Document, options);
        }
export type GetPagedUsersV2QueryHookResult = ReturnType<typeof useGetPagedUsersV2Query>;
export type GetPagedUsersV2LazyQueryHookResult = ReturnType<typeof useGetPagedUsersV2LazyQuery>;
export type GetPagedUsersV2QueryResult = Apollo.QueryResult<GetPagedUsersV2Query, GetPagedUsersV2QueryVariables>;
export const AddUserToFavoritesDocument = gql`
    mutation AddUserToFavorites($userId: String!) {
  addUserToFavorites(userId: $userId)
}
    `;
export type AddUserToFavoritesMutationFn = Apollo.MutationFunction<AddUserToFavoritesMutation, AddUserToFavoritesMutationVariables>;

/**
 * __useAddUserToFavoritesMutation__
 *
 * To run a mutation, you first call `useAddUserToFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToFavoritesMutation, { data, loading, error }] = useAddUserToFavoritesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddUserToFavoritesMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToFavoritesMutation, AddUserToFavoritesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToFavoritesMutation, AddUserToFavoritesMutationVariables>(AddUserToFavoritesDocument, options);
      }
export type AddUserToFavoritesMutationHookResult = ReturnType<typeof useAddUserToFavoritesMutation>;
export type AddUserToFavoritesMutationResult = Apollo.MutationResult<AddUserToFavoritesMutation>;
export type AddUserToFavoritesMutationOptions = Apollo.BaseMutationOptions<AddUserToFavoritesMutation, AddUserToFavoritesMutationVariables>;
export const RemoveUserFromFavoritesDocument = gql`
    mutation RemoveUserFromFavorites($userId: String!) {
  removeUserFromFavorites(userId: $userId)
}
    `;
export type RemoveUserFromFavoritesMutationFn = Apollo.MutationFunction<RemoveUserFromFavoritesMutation, RemoveUserFromFavoritesMutationVariables>;

/**
 * __useRemoveUserFromFavoritesMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromFavoritesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromFavoritesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromFavoritesMutation, { data, loading, error }] = useRemoveUserFromFavoritesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFromFavoritesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromFavoritesMutation, RemoveUserFromFavoritesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromFavoritesMutation, RemoveUserFromFavoritesMutationVariables>(RemoveUserFromFavoritesDocument, options);
      }
export type RemoveUserFromFavoritesMutationHookResult = ReturnType<typeof useRemoveUserFromFavoritesMutation>;
export type RemoveUserFromFavoritesMutationResult = Apollo.MutationResult<RemoveUserFromFavoritesMutation>;
export type RemoveUserFromFavoritesMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromFavoritesMutation, RemoveUserFromFavoritesMutationVariables>;
export const GetUserLocaleDocument = gql`
    query GetUserLocale($userId: ID!) {
  getMyLanguageTag {
    languageTag
  }
  getUserSetting(userId: $userId, settingSlug: "24-hour-time") {
    value
  }
}
    `;

/**
 * __useGetUserLocaleQuery__
 *
 * To run a query within a React component, call `useGetUserLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLocaleQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserLocaleQuery(baseOptions: Apollo.QueryHookOptions<GetUserLocaleQuery, GetUserLocaleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserLocaleQuery, GetUserLocaleQueryVariables>(GetUserLocaleDocument, options);
      }
export function useGetUserLocaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLocaleQuery, GetUserLocaleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserLocaleQuery, GetUserLocaleQueryVariables>(GetUserLocaleDocument, options);
        }
export type GetUserLocaleQueryHookResult = ReturnType<typeof useGetUserLocaleQuery>;
export type GetUserLocaleLazyQueryHookResult = ReturnType<typeof useGetUserLocaleLazyQuery>;
export type GetUserLocaleQueryResult = Apollo.QueryResult<GetUserLocaleQuery, GetUserLocaleQueryVariables>;