import Cookies from 'js-cookie';
import { config } from 'config';

type RobinSession = {
  access_token: string;
};

export function useAccessToken(): string | null {
  const sessionCookie = Cookies.get(config.sessionCookieName);

  const robinSession = (sessionCookie && JSON.parse(sessionCookie)) as
    | RobinSession
    | undefined;

  return robinSession?.access_token ?? null;
}
