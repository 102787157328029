import { RRule } from 'rrule';
import moment, { Moment } from 'moment';
import { WorkingHours } from 'generated';
import {
  OrganizationByIdQueryLocation,
  OrganizationByIdQueryOfficeAccess,
} from 'contexts';

/**
 * An array containing only the days that the user has access.
 * Each value is in the range 0-6 and corresponds to a day of
 * the week (Sunday = 0, Monday = 1, etc.).
 */
type AccessDays = number[];

// Constructs a list of days to represent office access in the pass UI.
export const getAccessDays = (schedule: string[]): AccessDays => {
  if (!schedule) {
    return [];
  }
  const rrule = RRule.fromString(schedule.join('\n'));
  const access = rrule.options.byweekday.map((day) => {
    return (day + 1) % 7;
  });
  access.sort();
  return access;
};

export function getTimeFrames(
  workingHours: WorkingHours[],
  selectedDay: number
) {
  return workingHours.find(({ day }) => day === selectedDay)?.timeFrames;
}

export function isOfficeClosed(
  date: Moment,
  location?: OrganizationByIdQueryLocation
) {
  if (!location || !location.workingHours) {
    return false;
  }
  const day = date.day();
  const timeFrames = getTimeFrames(location.workingHours, day);

  // The office is represented as closed if the custom work hours
  // returns an empty array for the timeframes on a particular day
  const customWorkHoursForDate = location?.customWorkingHours.find(
    (customWorkHour) => moment(customWorkHour.date).isSame(date, 'day')
  );

  return customWorkHoursForDate
    ? customWorkHoursForDate.timeFrames.length === 0
    : !timeFrames || timeFrames.length === 0;
}

export const hasAccessOnDay = (
  selectedDate: Moment,
  officeAccess?: OrganizationByIdQueryOfficeAccess
) => {
  if (officeAccess && officeAccess.length > 0) {
    const accessDays = [
      ...new Set(
        officeAccess.reduce<number[]>((acc, offAcc) => {
          return [...acc, ...getAccessDays(offAcc.schedule)];
        }, [])
      ),
    ];
    return accessDays.indexOf(selectedDate.day()) >= 0 ? true : false;
  } else {
    return false;
  }
};
