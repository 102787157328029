/* eslint-disable max-len */
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const RobinLoader = (): JSX.Element => {
  return (
    <LoadingContainer>
      <LoaderOverlay>
        <SVG
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 76 75"
        >
          <path
            d="M3.55061 42.3499L2.01467 43.8832C-0.668768 46.5626 -0.668768 50.9065 2.01334 53.5855C4.69677 56.2645 9.04658 56.2642 11.73 53.5845L13.266 52.0514C15.9481 49.372 15.9495 45.0281 13.266 42.3491C10.5838 39.67 6.23411 39.6704 3.55061 42.3499Z"
            fill="#A81F6B"
          />
          <path
            d="M43.9485 2.00986L42.4126 3.54313C39.7291 6.22255 39.7291 10.5664 42.4112 13.2454C45.0947 15.9244 49.4444 15.9239 52.1279 13.2446L53.6639 11.7113C56.346 9.03183 56.3473 4.68797 53.6639 2.00898C50.9818 -0.670017 46.6306 -0.669595 43.9485 2.00986Z"
            fill="#A81F6B"
          />
          <path
            d="M22.9765 61.7571L21.4433 63.2885C18.7599 65.9682 18.7585 70.3124 21.442 72.9908C24.1241 75.6699 28.4752 75.6699 31.1573 72.9901L32.6919 71.4589C35.3753 68.7792 35.3753 64.4355 32.6919 61.7565C30.0098 59.0775 25.66 59.0781 22.9765 61.7571Z"
            fill="#A81F6B"
          />
          <path
            d="M71.5576 32.6486L73.0936 31.1154C75.777 28.436 75.777 24.0921 73.0936 21.4131C70.4115 18.7341 66.0617 18.7345 63.3782 21.414L61.8423 22.9472C59.1602 25.6267 59.1588 29.9706 61.8409 32.6496C64.5244 35.3285 68.8742 35.3281 71.5576 32.6486Z"
            fill="#A81F6B"
          />
          <path
            d="M3.54735 32.651C6.23079 35.3305 10.5806 35.3309 13.264 32.652C15.9461 29.973 15.9461 25.6291 13.2627 22.9496L11.7281 21.4164C9.04465 18.7369 4.69485 18.7365 2.01142 21.4155C-0.670692 24.0944 -0.670693 28.4383 2.01274 31.1178L3.54735 32.651Z"
            fill="#A81F6B"
          />
          <path
            d="M52.1279 61.7521C49.4444 59.0723 45.0947 59.0718 42.4112 61.7509C39.7291 64.43 39.7291 68.7735 42.4126 71.4532L43.9458 72.9846C46.6292 75.6642 50.979 75.6649 53.6625 72.9858C56.3446 70.3067 56.3446 65.9625 53.6611 63.2835L52.1279 61.7521Z"
            fill="#A81F6B"
          />
          <path
            d="M71.5576 42.3542C68.8742 39.6745 64.5244 39.6743 61.8409 42.3532C59.1588 45.0322 59.1602 49.3761 61.8423 52.0556L63.3782 53.5888C66.0617 56.2683 70.4115 56.2687 73.0936 53.5897C75.777 50.9107 75.777 46.5667 73.0936 43.8873L71.5576 42.3542Z"
            fill="#A81F6B"
          />
          <path
            d="M22.9779 13.2445C25.6614 15.924 30.0111 15.9244 32.6946 13.2454C35.3767 10.5664 35.3767 6.22255 32.6932 3.5431L31.1573 2.00983C28.4752 -0.669627 24.1241 -0.669976 21.442 2.00898C18.7585 4.68798 18.7599 9.0318 21.4433 11.7113L22.9779 13.2445Z"
            fill="#A81F6B"
          />
          <path
            d="M24.1961 29.8899C24.1961 26.8068 26.7184 24.3075 29.83 24.3075H45.4467C48.5582 24.3075 51.0805 26.8068 51.0805 29.8899V45.3637C51.0805 48.4467 48.5582 50.946 45.4467 50.946H29.83C26.7184 50.946 24.1961 48.4467 24.1961 45.3637V29.8899Z"
            fill="#A81F6B"
          />
        </SVG>
      </LoaderOverlay>
    </LoadingContainer>
  );
};

//loading-container
const LoadingContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
`;

//animate-simple-flicker
const FlickerAnimation = keyframes`
  0%   { opacity:1; }
  50%  { opacity:0.2; }
  100% { opacity:1; }
`;

const LoaderOverlay = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  animation: ${FlickerAnimation} 2s infinite;
`;

const SVG = styled.svg`
  height: 50px;
  width: 50px;
  left: 50%;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
`;
