import React from 'react';
import { Body05, Heading04, Link } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

export const TableError = (): JSX.Element => {
  const { t } = useTranslation('peopleTable');

  return (
    <ErrorWrapper>
      <ErrorHeading>{t('error.header')}</ErrorHeading>
      <ErrorSubHeading>{t('error.subheader')}</ErrorSubHeading>
      <Link onClick={() => window.location.reload()}>{t('error.cta')}</Link>
    </ErrorWrapper>
  );
};

const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 70px;
  align-items: center;
`;

const HeaderStyles = css`
  max-width: 320px;
  text-align: center;
`;

const ErrorHeading = styled(Heading04)`
  ${HeaderStyles}
  margin-bottom: 22px;
`;

const ErrorSubHeading = styled(Body05)`
  ${HeaderStyles}
  margin-bottom: 18px;
`;
