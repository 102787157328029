import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query getLocationsByOrgId($id: ID!, $userId: ID!, $date: Date) {
    getOrganizationById(id: $id) {
      id
      name
      locations {
        id
        name
        timezone
        totalSpaces
        latitude
        longitude
        campus {
          id
          name
        }
        workingHours {
          day
          timeFrames {
            start
            end
          }
        }
        customWorkingHours(startFromDateTime: $date) {
          id
          date
          timeFrames {
            start
            end
          }
        }
        levels {
          id
          zones {
            id
            name
          }
        }
        officeAccess(userId: $userId) {
          id
          schedule
        }
      }
    }
  }
`;
