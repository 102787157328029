import { useEffect } from 'react';
import { useAmplitude } from 'contexts';

export const usePageViewEvent = (): void => {
  const { trackPageView } = useAmplitude();

  useEffect(() => {
    trackPageView();
    //eslint-disable-next-line
  }, []);
};
