import { gql, useMutation } from '@apollo/client';
import { useToast } from 'contexts';
import { useTranslation } from 'react-i18next';

const ADD_USER_QUERY = gql`
  mutation AddUserToFavorites($userId: String!) {
    addUserToFavorites(userId: $userId)
  }
`;

const REMOVE_USER_QUERY = gql`
  mutation RemoveUserFromFavorites($userId: String!) {
    removeUserFromFavorites(userId: $userId)
  }
`;

type UseTeamAPI = {
  saveFavorite: (userId: string) => Promise<void>;
  removeFavorite: (userId: string) => Promise<void>;
};

/**
 * A hook for managing getting and setting user favorites.  For paginated favorites, use
 * the usePagedUsers query
 */
export function useTeam(): UseTeamAPI {
  const { t } = useTranslation('error');
  const { error } = useToast();

  const [addUserToFavorites] = useMutation(ADD_USER_QUERY, {
    onError: () => {
      error(t('try_again'));
    },
  });

  const [removeUserFromFavorites] = useMutation(REMOVE_USER_QUERY, {
    onError: () => {
      error(t('try_again'));
    },
  });

  const saveFavorite = async (userId: string) => {
    await addUserToFavorites({
      variables: {
        userId,
      },
    });
  };

  const removeFavorite = async (userId: string) => {
    await removeUserFromFavorites({
      variables: {
        userId,
      },
    });
  };

  return {
    saveFavorite,
    removeFavorite,
  };
}
