var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { ChevronRight } from '../Icons';
import { LAUNCHNOTES_SELECTOR_ID_WITH_HASH } from '../../constants/launchNotes';
import { useHeaderContext } from '../../contexts';
var StyledListItem = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: block;\n  border-bottom: ", ";\n"], ["\n  position: relative;\n  display: block;\n  border-bottom: ", ";\n"])), function (props) {
    return props.divider ? "1px solid ".concat(Colors.Tan70) : 'none';
});
var MenuItemLink = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  font-size: 0.875rem;\n  padding: 8px 16px;\n  text-align: left;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  background-color: ", ";\n  color: ", ";\n  line-height: normal;\n  text-decoration: none;\n\n  &:hover,\n  &:focus-visible {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  &:focus-visible {\n    box-shadow: 0 0 10px ", ";\n  }\n\n  div {\n    flex: 1;\n  }\n\n  path {\n    stroke: ", ";\n    stroke-width: 6px;\n  }\n"], ["\n  background: none;\n  border: none;\n  font-size: 0.875rem;\n  padding: 8px 16px;\n  text-align: left;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  background-color: ", ";\n  color: ", ";\n  line-height: normal;\n  text-decoration: none;\n\n  &:hover,\n  &:focus-visible {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  &:focus-visible {\n    box-shadow: 0 0 10px ", ";\n  }\n\n  div {\n    flex: 1;\n  }\n\n  path {\n    stroke: ", ";\n    stroke-width: 6px;\n  }\n"])), function (props) {
    return props.subMenuIsOpen ? Colors.Maroon100 : Colors.White0;
}, function (props) { return (props.subMenuIsOpen ? Colors.White0 : Colors.Gray100); }, Colors.Maroon100, Colors.White0, Colors.Maroon90, function (props) { return (props.subMenuIsOpen ? Colors.White0 : Colors.Gray40); });
var MenuItemButton = styled(MenuItemLink.withComponent('button'))(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var MenuListItem = function (_a) {
    var item = _a.item, renderSubmenu = _a.renderSubmenu, onClick = _a.onClick, closeSubMenu = _a.closeSubMenu, toggleSubMenu = _a.toggleSubMenu, subMenuIsOpen = _a.subMenuIsOpen;
    var items = item.items, label = item.label, divider = item.divider, href = item.href, renderElement = item.renderElement;
    var hasSubMenu = items;
    var currentUser = useHeaderContext().currentUser;
    var handleClick = function () {
        if (hasSubMenu) {
            toggleSubMenu();
        }
        else if (onClick) {
            onClick(item);
            closeSubMenu();
        }
    };
    var handleMouseEnter = function () {
        toggleSubMenu();
    };
    return (_jsxs(_Fragment, { children: [item.selectorId && currentUser && (_jsx(_Fragment, { children: _jsx("launchnotes-embed", { token: process.env.REACT_APP_LAUNCH_NOTES_CONFIG_TOKEN, project: process.env.REACT_APP_LAUNCH_NOTES_CONFIG_PROJECT, "toggle-selector": LAUNCHNOTES_SELECTOR_ID_WITH_HASH, heading: "What's New From Robin", "heading-color": Colors.Gray110, subheading: "Learn about the latest Robin product releases, updates and enhancements", "subheading-color": Colors.Gray80, "primary-color": Colors.Tan20, "widget-placement": "auto-start", "widget-offset-skidding": "0", "widget-offset-distance": "16", "show-unread": true, "anonymous-user-id": currentUser.id, "unread-placement": "right", "unread-offset-skidding": "0", "unread-offset-distance": "-12", "unread-background-color": Colors.Magenta100, "unread-text-color": Colors.White0 }) })), _jsxs(StyledListItem, __assign({ divider: divider }, { children: [href ? (_jsxs(MenuItemLink, __assign({ href: href, tabIndex: 0, subMenuIsOpen: subMenuIsOpen, onClick: handleClick, onMouseEnter: handleMouseEnter }, { children: [renderElement !== undefined ? renderElement : _jsx("div", { children: label }), hasSubMenu && (_jsx(ChevronRight, { width: 11, height: 11, stroke: Colors.Gray40 }))] }))) : (_jsxs(MenuItemButton, __assign({ type: "button", tabIndex: 0, subMenuIsOpen: subMenuIsOpen, onClick: handleClick, onMouseEnter: handleMouseEnter }, { children: [renderElement !== undefined ? (renderElement) : (_jsx("div", __assign({ id: item.selectorId }, { children: label }))), hasSubMenu && (_jsx(ChevronRight, { width: 11, height: 11, stroke: Colors.Gray40 }))] }))), hasSubMenu &&
                        (renderSubmenu === null || renderSubmenu === void 0 ? void 0 : renderSubmenu({
                            isOpen: subMenuIsOpen,
                            items: items,
                        }))] }))] }));
};
var templateObject_1, templateObject_2, templateObject_3;
