import { EmployeeVisitByDate } from 'hooks';
import { Moment } from 'moment';
import {
  GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery,
  HealthCheckpointStatus,
  ParticipationStatus,
} from 'generated';

export type GetDeskReservationsForUsersAtLocationGroupedByDateAscending_Original =
  NonNullable<
    // eslint-disable-next-line max-len
    GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery['getDeskReservationsForUsersAtLocationGroupedByDateAscending']
  >;
type GetDeskReservationForUsersAtLocation_Reservation =
  GetDeskReservationsForUsersAtLocationGroupedByDateAscending_Original['reservationsByDate'][0]['reservations'][0];

export type DeskReservationByDateReservation_WithoutReservationsByDate = Omit<
  GetDeskReservationsForUsersAtLocationGroupedByDateAscending_Original['reservationsByDate'][0],
  'reservations'
>;

export type Reservation = {
  date: Moment;
  reservation?: GetDeskReservationForUsersAtLocation_Reservation;
};

export type AssignedReservation = Omit<
  GetDeskReservationForUsersAtLocation_Reservation,
  'type'
> & {
  type: 'assigned';
};
export type HoteledReservation = Omit<
  GetDeskReservationForUsersAtLocation_Reservation,
  'type'
> & {
  type: 'hoteled';
};
export type HotReservation = Omit<
  GetDeskReservationForUsersAtLocation_Reservation,
  'type'
> & {
  type: 'hot';
};
export type ReverseHoteledReservation = Omit<
  GetDeskReservationForUsersAtLocation_Reservation,
  'type'
> & {
  type: 'reverse_hoteled';
};

export type SharedReservation = Omit<
  GetDeskReservationForUsersAtLocation_Reservation,
  'type'
> & {
  type: 'shared';
};

export type TypedReservation =
  | HoteledReservation
  | AssignedReservation
  | HotReservation
  | ReverseHoteledReservation
  | SharedReservation;

export type DeskReservationsByDateWithTypedReservations =
  DeskReservationByDateReservation_WithoutReservationsByDate & {
    reservations: TypedReservation[];
  };

type GetDeskReservationForUsersAtLocationWithTypedReservations = Omit<
  GetDeskReservationsForUsersAtLocationGroupedByDateAscending_Original,
  'reservationsByDate'
> & {
  reservationsByDate: DeskReservationsByDateWithTypedReservations[];
};

export type GetReservationsResponse = Omit<
  GetDeskReservationsForUsersAtLocationGroupedByDateAscendingQuery,
  'getDeskReservationsForUsersAtLocationGroupedByDateAscending'
> & {
  // eslint-disable-next-line max-len
  getDeskReservationsForUsersAtLocationGroupedByDateAscending: GetDeskReservationForUsersAtLocationWithTypedReservations;
};

// Type guards
export const isHoteledReservation = (
  r: TypedReservation
): r is HoteledReservation => r.type === 'hoteled';
export const isHotReservation = (r: TypedReservation): r is HotReservation =>
  r.type === 'hot';
export const isAssignedReservation = (
  r: TypedReservation
): r is AssignedReservation => r.type === 'assigned' || r.type === 'shared';
export const isReverseHoteledReservation = (
  r: TypedReservation
): r is ReverseHoteledReservation => r.type === 'reverse_hoteled';
export const isAssignedConfirmedReservation = (
  r: TypedReservation
): r is AssignedReservation =>
  (r.type === 'assigned' || r.type === 'shared') &&
  ((r.truncatedConfirmation &&
    r.reservee.participationStatus === ParticipationStatus.Accepted) ||
    r.healthCheckpointStatus === HealthCheckpointStatus.Complete);

export type UserWeekDay = {
  date: Moment;
  visit?: EmployeeVisitByDate;
  reservation?: {
    date: moment.Moment;
    reservation: TypedReservation | undefined;
  };
};
