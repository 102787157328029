import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { BrownFontStack, Colors } from '@robinpowered/design-system';
import BuildingSolid from '@robinpowered/icons/BuildingSolid';
import HomeSolid from '@robinpowered/icons/HomeSolid';
import { useTranslation } from 'react-i18next';
import { Popover } from './Popover';
import { joinOfficeMutation } from 'graphql/joinOffice';
import { useMutation } from '@apollo/client';
import {
  useAmplitude,
  useAuthContext,
  useLocationsContext,
  useToast,
  useWhosInContext,
} from 'contexts';
import { DeskConfirmationType } from 'generated';
import moment, { Moment } from 'moment';

const ICON_SIZE = 15;

type Props = {
  date: Moment;
  closePopover: () => void;
};

export const UpdateStatusPopover = ({ date, closePopover }: Props) => {
  const { t } = useTranslation(['compareSchedules', 'common']);
  const { currentUser } = useAuthContext();
  const { trackEvent } = useAmplitude();
  const { selectedLocation } = useLocationsContext();
  const { employeeVisitsByDateRequest } = useWhosInContext();
  const { error, success } = useToast();

  const [joinOffice] = useMutation(joinOfficeMutation, {
    onError: () => error(t('common:try_again')),
    onCompleted: () => success(t('common:success')),
  });

  const handleJoinOffice = useCallback(async () => {
    // @TODO: if we ever enable location selection, we need to switch these
    // preferredlocations to whatever the selected loc is
    await joinOffice({
      variables: {
        userId: currentUser?.id,
        locationId: selectedLocation?.id,
        startTime: moment()
          .tz(selectedLocation?.timezone || moment.tz.guess())
          .set(date.toObject())
          .startOf('day')
          .toISOString(),
        endTime: moment()
          .tz(selectedLocation?.timezone || moment.tz.guess())
          .set(date.toObject())
          .endOf('day')
          .toISOString(),
        type: DeskConfirmationType.Dashboard,
      },
    });
    await employeeVisitsByDateRequest?.refetch();
    trackEvent('updated-status-from-compare-schedules');
    closePopover();
  }, [
    joinOffice,
    currentUser?.id,
    selectedLocation,
    date,
    employeeVisitsByDateRequest,
    closePopover,
    trackEvent,
  ]);

  return (
    <Popover>
      <HeadingReg>{t('popover.updatestatus.title')}</HeadingReg>
      <SelectRow
        tabIndex={0}
        onClick={closePopover}
        aria-label={t('popover.updatestatus.aria.close')}
      >
        <IconWrapper>
          <HomeSolid size={ICON_SIZE} color={Colors.Gray60} />
        </IconWrapper>
        <HeadingSM>{t('popover.updatestatus.remote')}</HeadingSM>
      </SelectRow>

      <SelectRow
        tabIndex={0}
        onClick={handleJoinOffice}
        aria-label={t('popover.updatestatus.aria.joinoffice')}
      >
        <IconWrapper>
          <BuildingSolid size={ICON_SIZE} color={Colors.Gray60} />
        </IconWrapper>
        <HeadingSM> {t('popover.updatestatus.inoffice')}</HeadingSM>
      </SelectRow>
    </Popover>
  );
};

const SelectRow = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: transparent;

  &:hover,
  &:focus {
    background-color: ${Colors.Tan30};
    border: 1px solid ${Colors.Gray30};
  }
`;

const IconWrapper = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${Colors.Tan20};
`;

// @TODO: Need to swap this when typography component is ready
const HeadingSM = styled.span`
  font-family: ${BrownFontStack};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-left: 8px;
`;

const HeadingReg = styled.h3`
  font-family: ${BrownFontStack};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 8px;
`;
