import { UserWeekDay } from '../../../../../../constants';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import {
  BookDeskPopover,
  CancelPlansPopover,
  DayPopover,
  StatusButton,
  UpdateStatusPopover,
} from './components';
import moment from 'moment';
import { FeatureFlagType } from 'generated';
import { useFeatureFlag } from 'hooks';
import { usePopover } from 'hooks/usePopover';
import { useAmplitude, useAuthContext, useLocationsContext } from 'contexts';
import { hasAccessOnDay, isOfficeClosed } from 'utilities/OfficeAccess';

type Props = {
  day: UserWeekDay;
  avatar?: string | null;
  name?: string | null;
  isCurrentUser?: boolean;
};

export const GridCell = ({
  day,
  isCurrentUser = false,
  avatar,
  name,
}: Props): JSX.Element => {
  const { orgSlug } = useAuthContext();
  const { trackEvent } = useAmplitude();
  const { selectedLocation } = useLocationsContext();
  const { hasPopover, setHasPopover, popoverRef } = usePopover();

  const featureFlag = useFeatureFlag(FeatureFlagType.EmployeeVisits);
  const featureEnabled =
    featureFlag.data?.isFeatureFlagEnabledForOrgOrMe && !featureFlag.loading;

  const isDisabled = useMemo(() => {
    return (
      day.date.isBefore(moment(), 'day') ||
      isOfficeClosed(day.date, selectedLocation) ||
      !hasAccessOnDay(day.date, selectedLocation?.officeAccess)
    );
  }, [day.date, selectedLocation]);
  const userStatus =
    day.reservation?.reservation || day.visit ? 'inoffice' : 'remote';

  return (
    <GridCellContainer>
      <PopoverContainer ref={popoverRef}>
        {userStatus === 'inoffice' || isCurrentUser ? (
          <StatusButton
            tabIndex={0}
            status={userStatus}
            onClick={() => {
              if (!hasPopover) {
                trackEvent('opened-compare-schedules-popover');
              }
              setHasPopover(!hasPopover);
            }}
            disabled={isDisabled}
          />
        ) : (
          <StatusButton
            status={userStatus}
            disabled={isDisabled}
            tabIndex={-1}
          />
        )}

        {hasPopover && (
          <>
            {!isCurrentUser && (
              <DayPopover
                avatar={avatar}
                name={name}
                day={day}
                closePopover={() => setHasPopover(false)}
              />
            )}
            {isCurrentUser &&
              (userStatus === 'inoffice' ? (
                <CancelPlansPopover
                  day={day}
                  closePopover={() => setHasPopover(false)}
                />
              ) : featureEnabled ? (
                <UpdateStatusPopover
                  date={day.date}
                  closePopover={() => setHasPopover(false)}
                />
              ) : (
                <BookDeskPopover
                  date={day.date}
                  link={`/${orgSlug}/office?&start=${encodeURIComponent(
                    day.date.toISOString()
                  )}`}
                  closePopover={() => setHasPopover(false)}
                />
              ))}
          </>
        )}
      </PopoverContainer>
    </GridCellContainer>
  );
};

export const GridCellContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-of-type {
    justify-content: flex-start;
    grid-column-start: 1;
    grid-column-end: 6;
  }
`;

const PopoverContainer = styled.div`
  position: relative;
`;
