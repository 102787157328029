var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { keyframes, Global, css } from '@emotion/react';
import { Colors, Button, Row, Heading02, Heading05, Body06, UtilityButton, } from '@robinpowered/design-system';
import Close from '@robinpowered/icons/Close';
import { AddSpaceCard } from './MarketingCards/AddSpaceCard';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvents } from '../../constants/amplitudeEvents';
import { useAmplitude } from '../../contexts';
var IOS = 'https://apps.apple.com/us/app/robin-powered/id948856282';
var ANDROID = 'https://play.google.com/store/apps/details?id=com.robinpowered.compass';
export var DownloadMobileAppModal = function (_a) {
    var setShowDownloadRobinAppModal = _a.setShowDownloadRobinAppModal;
    var t = useTranslation('downloadMobileAppModal').t;
    var trackEvent = useAmplitude().trackEvent;
    useEffect(function () {
        trackEvent(AmplitudeEvents.SHOW_MOBILE_WEB_TAKEOVER);
    }, [trackEvent]);
    var mobileAppLink = useMemo(function () {
        if (/Android/i.test(navigator.userAgent)) {
            return ANDROID;
        }
        if (/(iPhone|iPod)/i.test(navigator.userAgent)) {
            return IOS;
        }
        return 'https://robin.app/go/mobile';
    }, []);
    var handleDownloadAppAction = function () {
        window.location.href = mobileAppLink;
        trackEvent(AmplitudeEvents.SELECT_DOWNLOAD_MOBILE_WEB_TAKEOVER);
        setShowDownloadRobinAppModal(false);
    };
    var handleDismissModal = function () {
        setShowDownloadRobinAppModal(false);
        trackEvent(AmplitudeEvents.CLOSE_MOBILE_WEB_TAKEOVER);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Global, { styles: globalStyle }), _jsx(Overlay, { children: _jsxs(ModalScreen, { children: [_jsx(ModalHeader, { children: _jsx(UtilityButton, { onClick: handleDismissModal, style: { marginLeft: 'auto', marginRight: 0 }, icon: Close }) }), _jsxs(ContentContainer, { children: [_jsx(CardsContainer, { children: _jsx(AddSpaceCard, {}) }), _jsx(Heading02, { children: t('title') }), _jsx(ListContainer, { children: _jsxs(List, { children: [_jsx(ListItem, { children: _jsx(Body06, { children: t('list_bullet_1') }) }), _jsx(ListItem, { children: _jsx(Body06, { children: t('list_bullet_2') }) }), _jsx(ListItem, { children: _jsx(Body06, { children: t('list_bullet_3') }) })] }) })] }), _jsxs(ModalFooter, { children: [_jsx(Heading05, { children: t('button_heading') }), _jsx(Button, __assign({ variant: "primary", onClick: handleDownloadAppAction, style: { height: 44 } }, { children: t('button_label') }))] })] }) })] }));
};
/**
 * Is removed when the component unmounts
 */
var globalStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  html,\n  body {\n    overflow: hidden;\n  }\n"], ["\n  html,\n  body {\n    overflow: hidden;\n  }\n"])));
var fadeIn = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    from {\n       opacity: 0;\n    }\n    to {\n        opacity: 1;\n    }\n"], ["\n    from {\n       opacity: 0;\n    }\n    to {\n        opacity: 1;\n    }\n"])));
var Overlay = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  animation: ", " 0.3s ease-out;\n  background-color: rgba(0, 0, 0, 0.4);\n  bottom: 0;\n  display: block;\n  height: auto;\n  left: 0;\n  min-height: 100%;\n  overflow: hidden;\n  position: fixed;\n  right: 0;\n  top: 0;\n  width: 100vw;\n  z-index: 1050;\n"], ["\n  animation: ", " 0.3s ease-out;\n  background-color: rgba(0, 0, 0, 0.4);\n  bottom: 0;\n  display: block;\n  height: auto;\n  left: 0;\n  min-height: 100%;\n  overflow: hidden;\n  position: fixed;\n  right: 0;\n  top: 0;\n  width: 100vw;\n  z-index: 1050;\n"])), fadeIn);
var slideUp = keyframes(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    0% {\n        transform: translateY(100%);\n        visibility: visible;\n    }\n    100% {\n        transform: translateY(0%);\n        visibility: visible;\n    }\n"], ["\n    0% {\n        transform: translateY(100%);\n        visibility: visible;\n    }\n    100% {\n        transform: translateY(0%);\n        visibility: visible;\n    }\n"])));
var ModalScreen = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  visibility: hidden;\n  background-color: ", ";\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  animation: ", " 0.2s ease-out forwards;\n  animation-delay: 0.5s;\n  text-align: center;\n"], ["\n  visibility: hidden;\n  background-color: ", ";\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  animation: ", " 0.2s ease-out forwards;\n  animation-delay: 0.5s;\n  text-align: center;\n"])), Colors.Tan10, slideUp);
var ModalHeader = styled(Row)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex-grow: 0;\n  padding: 16px;\n"], ["\n  flex-grow: 0;\n  padding: 16px;\n"])));
var ContentContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 0px 16px;\n"], ["\n  padding: 0px 16px;\n"])));
var ModalFooter = styled(Row)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  flex-direction: column;\n  justify-content: space-between;\n  gap: 8px;\n  padding: 16px;\n"], ["\n  flex-direction: column;\n  justify-content: space-between;\n  gap: 8px;\n  padding: 16px;\n"])));
var ListContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 16px 16px 0px;\n  margin-left: 8px;\n"], ["\n  padding: 16px 16px 0px;\n  margin-left: 8px;\n"])));
var List = styled.ul(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  list-style: disc;\n  color: ", ";\n"], ["\n  list-style: disc;\n  color: ", ";\n"])), Colors.Gray100);
var ListItem = styled.li(templateObject_11 || (templateObject_11 = __makeTemplateObject([""], [""])));
var CardsContainer = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin: 10px auto 22px;\n  max-width: fit-content;\n  padding: 0 24px 24px;\n"], ["\n  margin: 10px auto 22px;\n  max-width: fit-content;\n  padding: 0 24px 24px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
