import React from 'react';
import { Skeleton } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { useTableContext } from 'contexts';
import { TextCell } from './TextCell';

type Props = {
  copy?: string | null;
  noResult?: boolean;
};
export const TextCellContainer = ({
  copy,
  noResult = false,
}: Props): JSX.Element | null => {
  const { tableLoading, showNoResultsCTA } = useTableContext();
  return (
    <>
      {tableLoading || showNoResultsCTA ? (
        <SkeletonWrapper>
          <Skeleton
            width="100%"
            height="16px"
            hasAnimation={!showNoResultsCTA}
          />
        </SkeletonWrapper>
      ) : !copy ? null : (
        <TextCell copy={copy} noResult={noResult} />
      )}
    </>
  );
};

const SkeletonWrapper = styled.div`
  display: flex;
  align-content: center;
`;
