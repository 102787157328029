import { PageMessage, RobinLoader } from 'components';
import { config } from 'config';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { robinEnv, dashboardApiUrl } = config;

const RedirectToDashboardHomepage: React.FC<{
  errorMessage?: string | null;
}> = ({ errorMessage }) => {
  const { t } = useTranslation('error');
  useEffect(() => {
    if (robinEnv !== 'development') {
      window.location.href = dashboardApiUrl as string;
    }
  }, []);
  if (dashboardApiUrl === 'development') {
    const message: string = errorMessage ?? t('404');
    return <PageMessage title={message} />;
  }

  return <RobinLoader />;
};

export default RedirectToDashboardHomepage;
