import { gql, useQuery } from '@apollo/client';
import { i18n } from '../i18n';
import { useEffect } from 'react';
import moment from 'moment';
import { useApolloContext } from 'contexts';
import { GetUserLocaleQuery } from 'generated';

export const GET_USER_LOCALE_QUERY = gql`
  query GetUserLocale($userId: ID!) {
    getMyLanguageTag {
      languageTag
    }
    getUserSetting(userId: $userId, settingSlug: "24-hour-time") {
      value
    }
  }
`;

type UserLocale = {
  loading: boolean;
  lang: string;
  use24HourTime: boolean;
};

export const useUserLocale = (userId?: string): UserLocale => {
  const { tenantId } = useApolloContext();
  const result = useQuery<GetUserLocaleQuery>(GET_USER_LOCALE_QUERY, {
    skip: !userId || !tenantId,
    variables: {
      userId: userId,
    },
  });
  const lang = result.data?.getMyLanguageTag.languageTag ?? 'en';
  const use24HourTime = result.data?.getUserSetting?.value
    ? JSON.parse(result.data?.getUserSetting.value)
    : false;
  const loading = lang !== i18n.language;

  useEffect(() => {
    if (loading) {
      void Promise.resolve()
        .then(
          async () => lang !== 'en' && (await import(`moment/locale/${lang}`))
        )
        .then(() => i18n.changeLanguage(lang))
        .then(() => moment.locale(lang));
    }
  }, [lang, loading]);

  return {
    loading,
    use24HourTime,
    lang,
  };
};
