var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect } from 'react';
import { useIdle } from 'react-use';
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback';
import { useHeaderContext } from '../../contexts';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
var PRESENCE_CHECK_INTERVAL = 60 * 60000; // 1 hour.
var REPORT_PRESENCE_MUTATION = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation PostWebDashboardPresence(\n    $ipAddressGuess: IPAddress\n    $clientName: String!\n  ) {\n    reportUserPresenceFromWebClient(\n      input: { ipAddressGuess: $ipAddressGuess, clientName: $clientName }\n    ) {\n      uuid\n    }\n  }\n"], ["\n  mutation PostWebDashboardPresence(\n    $ipAddressGuess: IPAddress\n    $clientName: String!\n  ) {\n    reportUserPresenceFromWebClient(\n      input: { ipAddressGuess: $ipAddressGuess, clientName: $clientName }\n    ) {\n      uuid\n    }\n  }\n"])));
export var PresenceNotifierContainer = function () {
    var _a, _b;
    var headerContext = useHeaderContext();
    var _c = __read(useMutation(REPORT_PRESENCE_MUTATION), 1), reportPresence = _c[0];
    var isIdle = useIdle(PRESENCE_CHECK_INTERVAL, false);
    var throttledPresence = useThrottledCallback(function () {
        fetch('https://jsonip.com')
            .then(function (response) { return response.json(); })
            .then(function (_a) {
            var ip = _a.ip;
            return reportPresence({
                variables: {
                    clientName: 'robin/web/dashboard',
                    ipAddressGuess: ip,
                },
            });
        });
    }, [(_a = headerContext === null || headerContext === void 0 ? void 0 : headerContext.activeOrg) === null || _a === void 0 ? void 0 : _a.id, reportPresence], PRESENCE_CHECK_INTERVAL, {
        trailing: false,
        leading: true,
    });
    useEffect(function () {
        var _a;
        if (!isIdle && ((_a = headerContext === null || headerContext === void 0 ? void 0 : headerContext.activeOrg) === null || _a === void 0 ? void 0 : _a.id)) {
            throttledPresence();
        }
    }, [isIdle, throttledPresence, (_b = headerContext === null || headerContext === void 0 ? void 0 : headerContext.activeOrg) === null || _b === void 0 ? void 0 : _b.id]);
    return null;
};
var templateObject_1;
