import React from 'react';
import { Body05, Heading04, Link } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { SearchNotFound } from 'components';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

type Props = {
  onClick: () => void;
};

export const TableEmpty = ({ onClick }: Props): JSX.Element => {
  const { t } = useTranslation('peopleTable');

  return (
    <EmptyWrapper>
      <SearchNotFound />
      <EmptyHeading>{t('search.empty.header')}</EmptyHeading>
      <EmptySubHeading>{t('search.empty.subheader')}</EmptySubHeading>
      <Link onClick={onClick}>{t('search.empty.cta')}</Link>
    </EmptyWrapper>
  );
};

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 45px 0;
  align-items: center;
`;

const HeaderStyles = css`
  max-width: 300px;
  text-align: center;
`;

const EmptyHeading = styled(Heading04)`
  ${HeaderStyles}
  margin-bottom: 22px;
`;

const EmptySubHeading = styled(Body05)`
  ${HeaderStyles}
  margin-bottom: 18px;
`;
