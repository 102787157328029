import { useCallback, useEffect } from 'react';

export const useEscape = (onkeydown: () => void) => {
  const handleEscapePress = useCallback(
    (event: KeyboardEvent) => {
      const { keyCode } = event;
      if (keyCode === 27) {
        onkeydown();
      }
    },
    [onkeydown]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleEscapePress);
    return () => {
      window.removeEventListener('keydown', handleEscapePress);
    };
  }, [handleEscapePress]);
};
