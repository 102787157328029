import { gql } from '@apollo/client';

export const GET_DESK_RES_BY_DATE_ASC = gql`
  query GetDeskReservationsForUsersAtLocationGroupedByDateAscending(
    $userIds: [ID!]!
    $locationId: ID!
    $startTime: ISODateTime!
    $endTime: ISODateTime!
  ) {
    getDeskReservationsForUsersAtLocationGroupedByDateAscending(
      userIds: $userIds
      locationId: $locationId
      startTime: $startTime
      endTime: $endTime
    ) {
      reservationsByDate {
        date

        reservations {
          id
          type
          startTime
          endTime
          visibility
          healthCheckpointStatus
          reservee {
            userId
            participationStatus
          }
          truncatedConfirmation {
            seatReservationId
          }
          seat {
            level {
              id
              name
            }
            location {
              name
            }
            name
            zone {
              name
            }
          }
        }
      }
      users {
        user {
          id
          givenName
        }
      }
    }
  }
`;
