import styled from '@emotion/styled';
import { Banner, Box, Colors } from '@robinpowered/design-system';
import Suggestion from '@robinpowered/icons/Suggestion';
import {
  useAuthContext,
  useLocationsContext,
  useTableContext,
  useWhosInContext,
} from 'contexts';
import { useTranslation } from 'react-i18next';
import {
  OrganizationDetails,
  SearchBarContainer,
  WeekPicker,
  LocationPickerButton,
  ButtonGroup,
} from './components';

type Props = {
  handleOpenLocationPicker: () => void;
};

export const TableHeader = ({
  handleOpenLocationPicker,
}: Props): JSX.Element => {
  const { t } = useTranslation('peopleTable');
  const { currentOrg } = useAuthContext();
  const { totalOrgUsers, tableView, showNoResultsCTA } = useTableContext();
  const { selectedLocation } = useLocationsContext();
  const { daysMostFavoritesAreInOffice, loading } = useWhosInContext();
  const favoritesViewActive =
    tableView === 'FAVORITES' || tableView === 'COMPARE-SCHEDULE';

  return (
    <section aria-label={t('aria.search')}>
      <Box display={'flex'}>
        <OrganizationDetails
          avatar={currentOrg?.avatar}
          numberOfEmployees={totalOrgUsers}
          header={
            favoritesViewActive ? t('header.favorites') : currentOrg?.name
          }
        />
        {favoritesViewActive && (
          <ButtonGroupContainer>
            <ButtonGroup />
          </ButtonGroupContainer>
        )}
      </Box>

      {!showNoResultsCTA && tableView !== 'COMPARE-SCHEDULE' && (
        <SearchBarContainer />
      )}
      {!showNoResultsCTA && !loading && tableView === 'COMPARE-SCHEDULE' && (
        <>
          <MetaContainer>
            <LocationPickerButton onClick={handleOpenLocationPicker} />
            <WeekPicker />
          </MetaContainer>

          {selectedLocation?.name &&
            daysMostFavoritesAreInOffice.length > 0 &&
            daysMostFavoritesAreInOffice.length < 3 && (
              <Banner
                icon={() => <Suggestion color={Colors.Magenta100} size={18} />}
                message={t('header.banner', {
                  date: daysMostFavoritesAreInOffice[0].date.format(
                    'dddd, MMMM Do'
                  ),
                  secondDate:
                    daysMostFavoritesAreInOffice[1]?.date.format(
                      'dddd, MMMM Do'
                    ),
                  count: daysMostFavoritesAreInOffice.length,
                  location: selectedLocation?.name,
                })}
                style={{ marginBottom: 12 }}
              />
            )}
        </>
      )}
    </section>
  );
};

const MetaContainer = styled.div`
  display: flex;
  align-content: 'center';
  margin-bottom: 12px;
`;

const ButtonGroupContainer = styled.div`
  margin-left: auto;
`;
