import { Body02, Body04, Flex } from '@robinpowered/design-system';

type PropTypes = {
  title: string;
  message?: string;
};

export const PageMessage = ({ title, message }: PropTypes): JSX.Element => {
  return (
    <Flex
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      paddingBottom="25%"
    >
      <Body02>{title}</Body02>
      {message && <Body04 mt={2}>{message}</Body04>}
    </Flex>
  );
};
