import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStagedFavorites } from 'hooks';
import { useAuthContext, useTableContext } from 'contexts';
import { Skeleton } from '@robinpowered/design-system';
import { FavoriteCell } from './';

type Props = {
  userId?: string;
};

export const FavoriteCellContainer = ({ userId }: Props): JSX.Element => {
  const { t } = useTranslation('peopleTable');
  const { currentUser } = useAuthContext();
  const { useFavoritesRequest, tableLoading, showNoResultsCTA } =
    useTableContext();
  const { isUserStaged, handleAddOrRemoveFavorite } = useStagedFavorites();

  if (tableLoading || showNoResultsCTA || !userId) {
    return (
      <Skeleton width="100%" height="16px" hasAnimation={!showNoResultsCTA} />
    );
  }
  return (
    <>
      {currentUser?.id === userId ||
      !useFavoritesRequest?.data?.isFavoritesEnabled ? null : (
        <FavoriteCell
          isFavorite={isUserStaged(userId)}
          onClick={() => handleAddOrRemoveFavorite(userId)}
          addCopy={t('favorite.add')}
          removeCopy={t('favorite.remove')}
        />
      )}
    </>
  );
};
