var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { ChevronDown } from '../Icons';
import { Avatar } from './Avatar';
var Button = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  background: none;\n  border: none;\n  padding: 3px 8px;\n  border: 2px solid transparent;\n  border-radius: 32px;\n\n  :hover {\n    background: ", ";\n  }\n\n  &:focus-visible {\n    border-color: ", ";\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  cursor: pointer;\n  background: none;\n  border: none;\n  padding: 3px 8px;\n  border: 2px solid transparent;\n  border-radius: 32px;\n\n  :hover {\n    background: ", ";\n  }\n\n  &:focus-visible {\n    border-color: ", ";\n  }\n"])), Colors.Tan5, Colors.Maroon100);
var StyledChevronDown = styled(ChevronDown)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  transform: rotate(", ");\n  margin-left: 4px;\n  width: 8px;\n  height: 8px;\n\n  path {\n    stroke: ", ";\n    stroke-width: 8px;\n  }\n"], ["\n  transform: rotate(", ");\n  margin-left: 4px;\n  width: 8px;\n  height: 8px;\n\n  path {\n    stroke: ", ";\n    stroke-width: 8px;\n  }\n"])), function (props) { return (props.open ? '180deg' : '0deg'); }, Colors.Gray80);
export var AvatarButton = function (_a) {
    var isOpen = _a.isOpen, avatar = _a.avatar, userName = _a.userName, onClick = _a.onClick;
    return (_jsxs(Button, __assign({ type: "button", onClick: onClick, "aria-label": "User Dropdown", "aria-haspopup": "true", "aria-expanded": isOpen }, { children: [_jsx(Avatar, { src: avatar, name: userName !== null && userName !== void 0 ? userName : '', size: 32 }), _jsx(StyledChevronDown, { open: isOpen })] })));
};
var templateObject_1, templateObject_2;
