var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function SvgNotifications(props) {
    return (_jsxs("svg", __assign({ width: "1em", height: "1em", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("title", { children: "Notifications" }), _jsx("path", { d: "M26 21v1a1 1 0 00.7-1.7l-.7.7zM6 21l-.7-.7A1 1 0 006 22v-1zm3-8H8h1zm14 0h1-1zm3 7H6v2h20v-2zM6 21l.7.7a5.4 5.4 0 00.5-.5A19.5 19.5 0 009.4 18c.3-.6.6-1.4.6-2.1H8c0 .3-.1.7-.4 1.2a17.4 17.4 0 01-2.3 3.1l.7.7zm4-5V13H8v3h2zm0-3a6 6 0 016-6V5a8 8 0 00-8 8h2zm6-6a6 6 0 016 6h2a8 8 0 00-8-8v2zm7 6h-1v3h2V13h-1zm-1 3c0 .7.3 1.5.6 2a12 12 0 002.2 3.2 18.6 18.6 0 00.5.5l.7-.7.7-.7a3.3 3.3 0 01-.1-.1 16.2 16.2 0 01-2.2-3c-.3-.5-.4-1-.4-1.2h-2z", fill: "#4E6275" }), _jsx("path", { d: "M13 24c.5 1.2 1.7 2 3 2s2.5-.8 3-2", stroke: "#4E6275", strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" })] })));
}
export default SvgNotifications;
