import styled from '@emotion/styled';
import {
  BrownFontStack,
  Colors,
  CoreFontSize,
  CoreFontWeight,
} from '@robinpowered/design-system';

// A link styled to mimic the primary button
export const PrimaryLink = styled.a`
  margin-top: 12px;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  font-family: ${BrownFontStack};
  font-weight: ${CoreFontWeight.Medium};
  font-size: ${CoreFontSize.s14};
  line-height: 16px;
  padding: 8px 12px;
  outline: none;
  width: auto;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
  border: none;
  color: ${Colors.White0};
  background-color: ${Colors.Maroon100};
  &:hover,
  &:focus,
  &:active {
    background-color: ${Colors.Maroon110};
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 4px ${Colors.Blue100};
  }
`;
