import { useQuery } from '@apollo/client';
import { GetReservationsResponse } from '../constants';
import { useAuthContext } from 'contexts';
import { GET_DESK_RES_BY_DATE_ASC } from 'graphql/getDeskReservationsForUsers';
import moment, { Moment } from 'moment-timezone';
import { useMemo } from 'react';

/**
 * A hook which returns a list dates with desk reservations for the passed in users
 */
export function useDeskReservationsByDate(
  userIds: string[],
  startTime: Moment,
  endTime: Moment,
  locationId?: string
) {
  const { currentUser } = useAuthContext();

  const timeZone = useMemo(
    () => currentUser?.timeZone || moment.tz.guess(),
    [currentUser]
  );

  return useQuery<GetReservationsResponse>(GET_DESK_RES_BY_DATE_ASC, {
    skip: userIds.length === 0 || !locationId,
    variables: {
      userIds,
      locationId,
      startTime: startTime.clone().tz(timeZone).startOf('day').toISOString(),
      endTime: endTime.clone().tz(timeZone).endOf('day').toISOString(),
    },
  });
}
