import React from 'react';
import { DeskCell } from './DeskCell';
import { useAuthContext, useTableContext } from 'contexts';
import { createQueryString } from 'utilities';
import { DeskReservationVisibility } from 'generated';
import { Skeleton } from '@robinpowered/design-system';

type Props = {
  userId?: string;
};
export const DeskCellContainer = ({ userId }: Props): JSX.Element | null => {
  const { orgSlug } = useAuthContext();
  const { useDeskReservationsRequest, tableLoading, showNoResultsCTA } =
    useTableContext();

  const userDeskReservations =
    useDeskReservationsRequest?.data?.getDeskReservationsByUserIds.find(
      (user) => user.userId === userId
    );

  // Only show desk reservations with privacy set for all
  const filteredDeskReservations = userDeskReservations?.reservations.filter(
    (res) => res.visibility === DeskReservationVisibility.Everyone
  );

  // Just show the first assigned or hoteled desk we find even if user has multiple
  // but prioritize ASSIGNED to show first
  const userReservation =
    filteredDeskReservations?.find(
      (res) => res.type === 'assigned' || res.type === 'shared'
    ) ||
    filteredDeskReservations?.find(
      (res) => res.type === 'hoteled' || res.type === 'hot'
    );

  if (tableLoading || showNoResultsCTA) {
    return (
      <Skeleton
        width={'100%'}
        height={'16px'}
        hasAnimation={!showNoResultsCTA}
      />
    );
  }

  if (!userReservation) {
    return null;
  }

  return (
    <DeskCell
      name={`${
        userReservation?.seat?.zone?.name &&
        userReservation?.seat.zone?.name + ', '
      }${userReservation?.seat.name}`}
      link={createQueryString(
        userReservation.startTime,
        userReservation.seat.level?.id,
        userReservation.id,
        orgSlug
      )}
    />
  );
};
