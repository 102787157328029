import styled from '@emotion/styled';
import { BrownFontStack, Colors } from '@robinpowered/design-system';
import CalendarSolid from '@robinpowered/icons/CalendarSolid';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Popover } from './Popover';
import { PrimaryLink } from './PrimaryLink';

const ICON_SIZE = 15;

type Props = {
  date: Moment;
  link: string;
  closePopover: () => void;
};

export const BookDeskPopover = ({ date, link, closePopover }: Props) => {
  const { t } = useTranslation('compareSchedules');

  return (
    <Popover onBlur={() => closePopover()}>
      <Row>
        <IconWrapper>
          <CalendarSolid size={ICON_SIZE} color={Colors.Gray60} />
        </IconWrapper>
        <BodyReg>{date.format('ddd, MMM D')}</BodyReg>
      </Row>
      <PrimaryLink
        href={link}
        aria-label={t('popover.bookdesk.button')}
        style={{ width: '100%' }}
      >
        {t('popover.bookdesk.button')}
      </PrimaryLink>
    </Popover>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const BodyReg = styled.span`
  font-family: ${BrownFontStack};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
