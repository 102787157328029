import { FC, createContext, useContext, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ToastType, Toast, ToastContext, ToastOptions } from './types';
import { Toasts } from './Toasts';

export const toastContext = createContext<ToastContext>({
  toasts: [],
  success: () => undefined,
  error: () => undefined,
  clearToasts: () => undefined,
});

export const ToastProvider: FC<ToastOptions> = ({
  children,
  duration = 5000,
}) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [domNode, setDomNode] = useState<HTMLElement>(document.body);

  const appendToast = (type: ToastType, message: string) =>
    setToasts([{ type, message }]);
  const clearToasts = () => {
    setToasts([]);
    setDomNode(document.body);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      clearToasts();
    }, duration);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toasts]);

  const findDomNode = (domId?: string) => {
    const dn = domId ? document.getElementById(domId) : document.body;
    setDomNode(dn ?? document.body);
  };

  const api: ToastContext = {
    toasts,
    success: (message, domId) => {
      findDomNode(domId);
      appendToast(ToastType.SUCCESS, message);
    },
    error: (message, domId) => {
      findDomNode(domId);
      appendToast(ToastType.ERROR, message);
    },
    clearToasts,
  };

  return (
    <toastContext.Provider value={api}>
      {children}
      {createPortal(<Toasts toasts={toasts} />, domNode)}
    </toastContext.Provider>
  );
};

export const useToast = (): ToastContext => {
  return useContext(toastContext);
};
