import { PaginatedList } from '@robinpowered/design-system';
import { useTableContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const TablePagination = (): JSX.Element | null => {
  const { t } = useTranslation('peopleTable');
  const {
    totalOrgUsers,
    pageOffset,
    setPageOffset,
    currentNumberOfTableResults,
    pageLimit,
  } = useTableContext();

  if (currentNumberOfTableResults === 0) {
    return null;
  }
  return (
    <PaginatedList
      prevLabel={t('pagination.previous')}
      nextLabel={t('pagination.next')}
      onPrevClick={() => {
        setPageOffset(pageOffset - pageLimit);
        window.scrollTo(0, 0);
      }}
      onNextClick={() => {
        setPageOffset(pageOffset + pageLimit);
        window.scrollTo(0, 0);
      }}
      onPageClick={(page) => {
        // Need to manually set the offset to 0 if the page is 1
        setPageOffset(pageLimit * (page === 1 ? 0 : page - 1));
        window.scrollTo(0, 0);
      }}
      numRecords={totalOrgUsers}
      numRecordsPerPage={pageLimit}
      activePage={pageOffset / pageLimit + 1}
    />
  );
};
