import styled from '@emotion/styled';
import { Skeleton } from '@robinpowered/design-system';

export const SidebarLoader = (): JSX.Element => {
  return (
    <>
      <LoaderLi>
        <LoaderRow />
      </LoaderLi>
      <LoaderLi>
        <LoaderRow />
      </LoaderLi>
    </>
  );
};

const LoaderLi = styled.li`
  width: 124px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderRow = styled(Skeleton)`
  height: 16px;
  width: 100%;
`;
