import styled from '@emotion/styled';
import { Colors, BrownFontStack } from '@robinpowered/design-system';
import { HamburgerMenu } from 'components';
import { useTableContext } from 'contexts';
import { useFavoritesEnabled } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SidebarLoader } from './SidebarLoader';

export const SidebarNav = (): JSX.Element => {
  const { tableView, setTableView, setPageOffset } = useTableContext();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { data, loading, error } = useFavoritesEnabled();
  const { t } = useTranslation('common');

  return (
    <Nav>
      <NavHeader onClick={() => setIsNavOpen(!isNavOpen)}>
        <HamburgerMenu />
        <HeaderText>
          {tableView === 'ORGANIZATION' ? t('organization') : t('favorites')}
        </HeaderText>
      </NavHeader>
      <NavList isVisible={isNavOpen}>
        {loading ? (
          <SidebarLoader />
        ) : (
          <>
            <IconButton onClick={() => setIsNavOpen(!isNavOpen)}>
              <HamburgerMenu />
            </IconButton>
            <li>
              <NavButton
                onClick={() => {
                  setTableView('ORGANIZATION');
                  setPageOffset(0);
                }}
                isActive={tableView === 'ORGANIZATION'}
              >
                {t('organization')}
              </NavButton>
            </li>
            {data?.isFavoritesEnabled && !error && (
              <ListItem>
                <NavButton
                  onClick={() => {
                    setTableView('COMPARE-SCHEDULE');
                    setPageOffset(0);
                  }}
                  isActive={
                    tableView === 'FAVORITES' ||
                    tableView === 'COMPARE-SCHEDULE'
                  }
                >
                  {t('favorites')}
                </NavButton>
              </ListItem>
            )}
          </>
        )}
      </NavList>
    </Nav>
  );
};

const Nav = styled.nav`
  width: 100%;
  max-width: 156px;
  background-color: ${Colors.Tan30};
  position: relative;

  @media (max-width: 960px) {
    height: 100%;
    max-width: 100%;
    margin-top: 8px;
    margin-right: 12px;
    margin-left: 12px;
  }
`;

const NavHeader = styled.button`
  border: none;
  background-color: ${Colors.Tan30};
  display: none;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 4px;
  &:active {
    background-color: ${Colors.Tan10};
  }
  @media (max-width: 960px) {
    display: flex;
  }
`;

const HeaderText = styled.div`
  margin-left: 20px;
`;

const NavList = styled.ul<{ isVisible?: boolean }>`
  z-index: 999;
  margin: 20px 16px 0px 28px;
  > li {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  > li:first-of-type {
    margin-bottom: 12px;
  }

  transition: 0.3s ease-in-out;

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 156px;
    height: 100vh;
    left: -200px;
    top: -27px;
    padding-left: 16px;
    padding-top: 16px;
    background-color: ${Colors.White0};
    border-right: 1px solid #ded8d1;
    box-shadow: 2px 0px 6px rgba(173, 163, 170, 0.1);
    > li {
      margin-top: 8px;
      margin-bottom: 8px;
    }
    > li:first-of-type {
      margin-bottom: 8px;
    }
    ${(props) => props.isVisible && `transform: translate(160px, 0px)`}
  }
`;

const IconButton = styled.button`
  border: none;
  height: 36px;
  width: 36px;
  background-color: ${Colors.Tan30};
  display: none;
  align-items: center;
  width: fit-content;
  border-radius: 4px;
  margin-bottom: 12px;
  &:active {
    background-color: ${Colors.Tan10};
  }
  @media (max-width: 960px) {
    display: flex;
  }
`;

const NavButton = styled.button<{ isActive?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 28px;
  background-color: ${Colors.Tan30};
  border: none;
  font-family: ${BrownFontStack};
  font-weight: 400;
  font-size: 0.875rem;
  padding-left: 12px;
  font-style: normal;
  color: ${(props) => (props.isActive ? Colors.Maroon100 : Colors.Gray70)};
  border-radius: 4px;
  &:hover {
    background-color: ${Colors.Tan10};
  }
  @media (max-width: 960px) {
    overflow: hidden;
    background-color: ${Colors.White0};
    padding-left: 0px;
    &:hover {
      background-color: ${Colors.White0};
    }
  }
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
