var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useQuery } from '@apollo/client';
import { useHeaderContext } from '../contexts';
var GET_ACTIVE_ORGANIZATION = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query HeaderActiveOrganization($id: ID!) {\n    getOrganizationById(id: $id) {\n      id\n      hasActiveLocations\n      mapOnboardingStatus {\n        shouldShowSetup\n        location {\n          id\n        }\n        level {\n          id\n        }\n      }\n    }\n  }\n"], ["\n  query HeaderActiveOrganization($id: ID!) {\n    getOrganizationById(id: $id) {\n      id\n      hasActiveLocations\n      mapOnboardingStatus {\n        shouldShowSetup\n        location {\n          id\n        }\n        level {\n          id\n        }\n      }\n    }\n  }\n"])));
export var useActiveOrganization = function () {
    var _a = useHeaderContext(), activeOrg = _a.activeOrg, hasTenant = _a.hasTenant;
    return useQuery(GET_ACTIVE_ORGANIZATION, {
        skip: !(activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.id) || !hasTenant,
        variables: {
            id: activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.id,
        },
        nextFetchPolicy: 'cache-and-network',
    });
};
var templateObject_1;
