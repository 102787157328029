import { OperationVariables, QueryResult } from '@apollo/client';
import { useAuthContext } from 'contexts';
import {
  FeatureFlagType,
  GetDeskReservationsQuery,
  GetIsFavoritesEnabledQuery,
  GetPagedUsersQuery,
} from 'generated';
import {
  UPPER_LIMIT_COMPARE_SCHEDULE_USERS,
  UPPER_LIMIT_TABLE_USERS,
  useFavoritesEnabled,
  useFeatureFlag,
  usePagedUsers,
} from 'hooks';
import { useDeskReservations } from 'hooks/useDeskReservations';
import {
  createContext,
  useContext,
  useState,
  FC,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

export type TableViews = 'ORGANIZATION' | 'FAVORITES' | 'COMPARE-SCHEDULE';

type TableContextValue = {
  tableView: TableViews;
  setTableView: Dispatch<SetStateAction<TableViews>>;
  usePagedUsersRequest?: QueryResult<GetPagedUsersQuery, OperationVariables>;
  useDeskReservationsRequest?: QueryResult<
    GetDeskReservationsQuery,
    OperationVariables
  >;
  useFavoritesRequest?: QueryResult<
    GetIsFavoritesEnabledQuery,
    OperationVariables
  >;
  currentNumberOfTableResults: number;
  totalOrgUsers: number;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setPageOffset: Dispatch<SetStateAction<number>>;
  pageLimit: number;
  pageOffset: number;
  tableLoading: boolean;
  tableError: boolean;
  showNoResultsCTA: boolean;
};

const TableContext = createContext<TableContextValue>({
  tableView: 'ORGANIZATION',
  pageLimit: 0,
  setTableView: () => null,
  usePagedUsersRequest: undefined,
  useDeskReservationsRequest: undefined,
  useFavoritesRequest: undefined,
  currentNumberOfTableResults: 0,
  totalOrgUsers: 0,
  setSearchValue: () => null,
  searchValue: '',
  setPageOffset: () => null,
  pageOffset: 0,
  tableLoading: false,
  tableError: false,
  showNoResultsCTA: false,
});

export const TableProvider: FC = ({ children }) => {
  const { currentOrg } = useAuthContext();
  const [tableView, setTableView] = useState<TableViews>('ORGANIZATION');
  // The debounced value piped into the query
  const [searchFilter, setSearchFilter] = useState('');
  // The current value of the user's input
  const [searchValue, setSearchValue] = useState('');
  // For tracking the current page in the UI
  const [pageOffset, setPageOffset] = useState(0);
  // Users have to type at least 3 chars to get search results
  const MIN_SEARCH_THRESHOLD = 3;
  // State to prevent unnecessary recalls of usepageduser if the user
  // only types 1 or 2 chars, then clears their search
  const [minSearchThresholdReached, setMinSearchThresholdReached] =
    useState(false);

  // How many users we show on the current view at once
  // before showing pagination
  const pageLimit =
    tableView === 'COMPARE-SCHEDULE'
      ? UPPER_LIMIT_COMPARE_SCHEDULE_USERS
      : UPPER_LIMIT_TABLE_USERS;

  const featureFlag = useFeatureFlag(FeatureFlagType.PeopleSearchOptimization);
  const featureEnabled: boolean = (featureFlag.data
    ?.isFeatureFlagEnabledForOrgOrMe && !featureFlag.loading) as boolean;

  const usePagedUsersRequest = usePagedUsers({
    limit: pageLimit,
    favoriteFilter:
      tableView === 'FAVORITES' || tableView === 'COMPARE-SCHEDULE',
    searchFilter,
    pageOffset,
    peopleSearchOptimizationEnabled: featureEnabled,
  });

  const request = usePagedUsersRequest;

  const { refetch } = request;

  const useDeskReservationsRequest = useDeskReservations(
    usePagedUsersRequest.data?.getPagedUsers.accountUsers.map(
      (user) => user.id
    ) || []
  );

  const useFavoritesRequest = useFavoritesEnabled();

  useEffect(() => {
    setSearchValue('');
    setPageOffset(0);
  }, [tableView]);

  // This state is to prevent the page requests kicking off
  // every time
  useEffect(() => {
    if (
      searchValue.length >= MIN_SEARCH_THRESHOLD &&
      !minSearchThresholdReached
    ) {
      setMinSearchThresholdReached(true);
    }

    if (
      searchValue.length >= MIN_SEARCH_THRESHOLD ||
      (searchValue === '' && minSearchThresholdReached)
    ) {
      setPageOffset(0);
      setSearchFilter(searchValue);

      if (searchValue === '') {
        setMinSearchThresholdReached(false);
      }
    }
  }, [searchValue, minSearchThresholdReached]);

  // Whenever the users switches tabs, we want to refetch their faves
  // to show the new faves they may have added
  useEffect(() => {
    // apollo-client possible bug:
    // this refetch does not respect the skip parameter, resulting in this querying
    // running on page load and failing, checking orgId here prevents this
    if (currentOrg && currentOrg.id) {
      refetch({
        favoriteFilter: tableView === 'FAVORITES',
      });
    }

    // Don't include the org or req in the dependencies or it will render this
    // query several extra times on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableView]);

  const currentNumberOfTableResults =
    usePagedUsersRequest.data?.getPagedUsers.accountUsers.length || 0;

  const tableLoading =
    usePagedUsersRequest.loading ||
    useFavoritesRequest.loading ||
    useDeskReservationsRequest.loading;

  const value: TableContextValue = {
    tableView,
    setTableView,
    setSearchValue,
    searchValue,
    usePagedUsersRequest,
    useDeskReservationsRequest,
    useFavoritesRequest,
    currentNumberOfTableResults,
    totalOrgUsers:
      usePagedUsersRequest.data?.getPagedUsers.accountUsersTotal || 0,
    pageOffset,
    setPageOffset,
    pageLimit,
    tableLoading,
    tableError: !!(
      (usePagedUsersRequest.error && !usePagedUsersRequest.data) ||
      useFavoritesRequest.error ||
      useDeskReservationsRequest?.error
    ),
    showNoResultsCTA:
      currentNumberOfTableResults === 0 &&
      (tableView === 'FAVORITES' || tableView === 'COMPARE-SCHEDULE') &&
      searchFilter === '' &&
      !tableLoading,
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

export const useTableContext = (): TableContextValue => {
  return useContext(TableContext);
};
