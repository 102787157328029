import React from 'react';
import { Body06, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type Props = {
  copy?: string;
  noResult?: boolean;
};
export const TextCell = ({ copy, noResult = false }: Props): JSX.Element => {
  return (
    <TextCellWrapper color={noResult ? Colors.Gray80 : ''}>
      {copy}
    </TextCellWrapper>
  );
};

export const OverflowStyles = css`
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const TextCellWrapper = styled(Body06)`
  ${OverflowStyles}
`;
