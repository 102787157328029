import * as React from 'react';

export const SearchNotFound = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="132"
      height="109"
      fill="none"
      {...props}
    >
      <rect
        width="100.289"
        height="90.87"
        x="1.809"
        y="1.5"
        fill="#fff"
        rx="5.5"
      ></rect>
      <rect
        width="125.289"
        height="90.87"
        x="1.809"
        y="1.5"
        fill="#F0E4EB"
        fillOpacity="0.17"
        rx="5.5"
      ></rect>
      <rect
        width="125.289"
        height="90.87"
        x="1.809"
        y="1.5"
        stroke="#7F506C"
        strokeWidth="3"
        rx="5.5"
      ></rect>
      <path
        stroke="#7F506C"
        strokeLinecap="round"
        strokeWidth="3"
        d="M1.809 14.926h123.724"
      ></path>
      <circle cx="8.914" cy="8.604" r="2.347" fill="#7F506C"></circle>
      <circle cx="15.172" cy="8.604" r="2.347" fill="#7F506C"></circle>
      <circle cx="21.43" cy="8.604" r="2.347" fill="#7F506C"></circle>
      <rect
        width="8.546"
        height="39.335"
        x="100.522"
        y="64.763"
        fill="#F0E4EB"
        stroke="#7F506C"
        strokeWidth="3"
        rx="4.273"
        transform="rotate(-39.827 100.522 64.763)"
      ></rect>
      <circle
        cx="96.064"
        cy="52.747"
        r="21.592"
        fill="#F0E4EB"
        stroke="#7F506C"
        strokeWidth="3"
        transform="rotate(-39.827 96.064 52.747)"
      ></circle>
      <circle
        cx="96.064"
        cy="52.746"
        r="15.819"
        fill="#FCFAFC"
        stroke="#7F506C"
        strokeWidth="3"
        transform="rotate(-39.827 96.064 52.746)"
      ></circle>
    </svg>
  );
};
