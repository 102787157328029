var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useMemo, useContext } from 'react';
import moment from 'moment-timezone';
import { buildDatesArray } from '../utilities';
import { useGetUserSetting, useIsShowWeekendsEnabledForMe } from '../hooks';
// How many days to render in the 'week view'
export var DAYS_TO_RENDER = 7;
// Local storage keys for user settings
// Deprecated, now set as a user setting
export var HIDE_WEEKENDS_KEY = 'schedule:hide-weekends';
export var DateContext = React.createContext({
    weekStartDate: moment(),
    weekEndDate: moment(),
    hideWeekends: true,
    weekScheduleDates: [],
    weekIndex: 0,
    setWeekIndex: function () { return null; },
});
export function DateProvider(_a) {
    var _b;
    var children = _a.children, userId = _a.userId, tenantId = _a.tenantId, _c = _a.timezone, timezone = _c === void 0 ? moment.tz.guess() : _c;
    // 0 represents the base week a user starts on.  The current week a user is viewing
    // will be weekIndex + x (i.e. a week index of 1 is 1 week in the future)
    var _d = __read(useState(0), 2), weekIndex = _d[0], setWeekIndex = _d[1];
    var _e = useIsShowWeekendsEnabledForMe(tenantId), getShowWeekends = _e.getShowWeekends, setShowWeekends = _e.setShowWeekends;
    var hideWeekends = !((_b = getShowWeekends === null || getShowWeekends === void 0 ? void 0 : getShowWeekends.data) === null || _b === void 0 ? void 0 : _b.isShowWeekendsEnabledForMe.showWeekends);
    // Checking the local storage and saving hide weekends value to the gql is only meant to live here for a short while.
    // Safe to remove 4/2024.
    var hideWeekendsLocalStorage = localStorage.getItem(HIDE_WEEKENDS_KEY);
    if (hideWeekendsLocalStorage !== null) {
        setShowWeekends(!(hideWeekendsLocalStorage === 'true'));
        localStorage.removeItem(HIDE_WEEKENDS_KEY);
    }
    var userSettings = useGetUserSetting(userId, tenantId).data;
    var userWeekStartSetting = useMemo(function () {
        var _a, _b;
        return ((_a = userSettings === null || userSettings === void 0 ? void 0 : userSettings.getUserSetting) === null || _a === void 0 ? void 0 : _a.value)
            ? parseInt((_b = userSettings === null || userSettings === void 0 ? void 0 : userSettings.getUserSetting) === null || _b === void 0 ? void 0 : _b.value) % 7
            : 0;
    }, [userSettings]);
    var startOfUserWeek = useMemo(function () {
        // If user start setting is in the future, then set it back a week
        return moment()
            .tz(timezone)
            .isoWeekday(userWeekStartSetting)
            .startOf('day')
            .isAfter(moment().tz(timezone).startOf('day'))
            ? moment()
                .tz(timezone)
                .isoWeekday(userWeekStartSetting)
                .subtract(1, 'week')
                .startOf('day')
            : moment().tz(timezone).isoWeekday(userWeekStartSetting).startOf('day');
    }, [userWeekStartSetting, timezone]);
    // Range of dates a user is seeing - currently used in the Week Schedule
    // and Compare Favorites pages
    var weekScheduleDates = useMemo(function () {
        return buildDatesArray(startOfUserWeek.clone().add(weekIndex, 'weeks'), DAYS_TO_RENDER, timezone, hideWeekends);
    }, [startOfUserWeek, hideWeekends, weekIndex, timezone]);
    var api = {
        weekStartDate: weekScheduleDates[0].clone(),
        weekEndDate: weekScheduleDates[weekScheduleDates.length - 1]
            .clone()
            .endOf('day'),
        weekScheduleDates: weekScheduleDates,
        hideWeekends: hideWeekends,
        weekIndex: weekIndex,
        setWeekIndex: setWeekIndex,
    };
    return _jsx(DateContext.Provider, __assign({ value: api }, { children: children }));
}
export var useDateContext = function () {
    return useContext(DateContext);
};
