import React from 'react';
import { Link } from '@robinpowered/design-system';

type Props = {
  link: string | null;
  name?: string;
};

export const DeskCell = ({ link, name }: Props): JSX.Element => {
  return <>{link && name ? <Link href={link}>{name}</Link> : null}</>;
};
