import { useAmplitude, useTableContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import { NoResultsCTA } from './NoResultsCTA';

// Empty state for the table that allows users to search for other
// users to add to a particular group
export const NoResultsCTAContainer = (): JSX.Element => {
  const { t } = useTranslation('cta');
  const { setTableView, tableView } = useTableContext();
  const { trackEvent } = useAmplitude();

  return (
    <NoResultsCTA
      header={t(`${tableView.toLocaleLowerCase()}.header`)}
      body={t(`${tableView.toLocaleLowerCase()}.body`)}
      cta={t(`${tableView.toLocaleLowerCase()}.cta`)}
      onClick={() => {
        trackEvent('clicked-people-tab-add-favorite-cta');
        return setTableView('ORGANIZATION');
      }}
    />
  );
};
