import { useRef, useEffect, RefObject } from 'react';

// Traps focus/tab navigation within a ref, e.g. A modal
// Returns a generic HTML ref that can be added to a component
// e.g. a wrapper component around the content you want to lock focus to
export const useFocusTrap = <
  T extends HTMLElement = HTMLElement
>(): RefObject<T> => {
  const elRef = useRef<T>(null);

  const handleFocus = (e: KeyboardEvent) => {
    const focusableEls =
      elRef.current &&
      elRef.current.querySelectorAll(
        // eslint-disable-next-line max-len
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
      );

    const firstFocusableEl = focusableEls && focusableEls[0];
    const lastFocusableEl =
      focusableEls && focusableEls[focusableEls.length - 1];

    const isTabPressed = e.key === 'Tab';

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableEl) {
        (lastFocusableEl as HTMLElement).focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableEl) {
        (firstFocusableEl as HTMLElement)?.focus();
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    const currentRef = elRef.current;
    currentRef && currentRef.addEventListener('keydown', handleFocus);

    return () => {
      currentRef && currentRef.removeEventListener('keydown', handleFocus);
    };
  }, []);

  return elRef;
};
