var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useClickAway, useKey } from 'react-use';
import styled from '@emotion/styled';
import { useHeaderContext } from '../../contexts';
import { MenuSelect } from '../../components';
import { DropdownIcon } from './DropdownIcon';
import { buildUserOrgMenu } from './buildUserOrgMenu';
import { OrgMenuButton } from './OrgMenuButton';
var OrgName = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  display: inline-block;\n  vertical-align: middle;\n  font-weight: 500;\n  font-size: 0.875rem;\n"], ["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  display: inline-block;\n  vertical-align: middle;\n  font-weight: 500;\n  font-size: 0.875rem;\n"])));
export var OrganizationDropdown = function () {
    var _a, _b;
    var _c = useHeaderContext(), activeOrg = _c.activeOrg, userOrganizations = _c.userOrganizations, isLoading = _c.isLoading, error = _c.error;
    var _d = __read(useState(false), 2), menuOpen = _d[0], setMenuOpen = _d[1];
    var pathParts = ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.pathname) !== null && _b !== void 0 ? _b : '').split('/');
    pathParts.splice(1, 1); // Remove the org from the path
    var pathWithoutOrg = pathParts.join('/');
    var orgAriaLabel = "Select an organization - ".concat(activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.name, " currently selected");
    var menuRef = useRef(null);
    useClickAway(menuRef, function () {
        setMenuOpen(false);
    });
    useKey('Escape', function () { return setMenuOpen(false); });
    if (isLoading || error || !(userOrganizations === null || userOrganizations === void 0 ? void 0 : userOrganizations.length)) {
        return null;
    }
    return (_jsxs(OrgMenuButton, __assign({ ref: menuRef, onClick: function () { return setMenuOpen(function (open) { return !open; }); }, "aria-label": orgAriaLabel, "aria-expanded": menuOpen, "aria-haspopup": "true" }, { children: [(activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.logo) ? (_jsx("img", { id: "1", src: activeOrg.logo, alt: '', style: { maxHeight: 32, maxWidth: 120 } })) : (activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.avatar) ? (_jsx("img", { src: activeOrg.avatar, alt: '', style: { maxHeight: 32, borderRadius: '50%' } })) : (_jsx(OrgName, { children: activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.name })), _jsx(DropdownIcon, { open: menuOpen }), _jsx(MenuSelect, { style: {
                    top: 'calc(100% + 4px)',
                    left: 0,
                    width: '275px',
                    maxHeight: '375px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                }, isOpen: menuOpen, items: buildUserOrgMenu(userOrganizations, pathWithoutOrg, activeOrg), onItemClick: function () { return setMenuOpen(false); } })] })));
};
var templateObject_1;
