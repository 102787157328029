var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { Notifications } from '../../components';
import { useHeaderContext } from '../../contexts';
var NotificationWrapper = styled.a({
    position: 'relative',
    display: 'flex',
    padding: '3px',
    marginRight: '8px',
    borderRadius: '50%',
    border: '2px solid transparent',
    '&:hover': {
        backgroundColor: Colors.Tan5,
    },
    '&:focus-visible': {
        borderColor: Colors.Maroon100,
    },
});
var Badge = styled('span')({
    position: 'absolute',
    padding: '3px',
    borderRadius: '50%',
    top: '7px',
    right: '7px',
    backgroundColor: Colors.RedProduct,
    border: "1px solid ".concat(Colors.White0),
    '&:hover': {
        border: "1px solid ".concat(Colors.Tan5),
        background: Colors.Tan5,
    },
}, function (props) { return ({
    display: props.hasNotifications ? 'inline' : 'none',
}); });
export var NotificationMenuItem = function () {
    var _a = useHeaderContext(), activeOrg = _a.activeOrg, userPermissions = _a.userPermissions;
    var _b = userPermissions || {}, canApproveSpaceRequests = _b.canApproveSpaceRequests, totalPendingNotifications = _b.totalPendingNotifications;
    if (!canApproveSpaceRequests || !activeOrg) {
        return null;
    }
    return (_jsxs(NotificationWrapper, __assign({ href: "/".concat(activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.slug, "/notifications") }, { children: [_jsx(Notifications, { height: "27px", width: "27px" }), _jsx(Badge, { hasNotifications: totalPendingNotifications })] })));
};
