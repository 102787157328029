import React from 'react';
import styled from '@emotion/styled';
import { Body07, Colors } from '@robinpowered/design-system';
import { Moment } from 'moment';

type Props = {
  dates: Moment[];
  hideWeekends?: boolean;
};

export const GridHeader = ({ dates, hideWeekends }: Props): JSX.Element => {
  return (
    <GridHeaderContainer hideWeekends={hideWeekends}>
      {dates.map((date) => (
        <DateCell key={date.clone().format()}>
          <Body07 color={Colors.Gray100}>
            {date.clone().format('ddd DD')}
          </Body07>
        </DateCell>
      ))}
    </GridHeaderContainer>
  );
};

type GridHeaderContainerProps = {
  hideWeekends?: boolean;
};

const GridHeaderContainer = styled.div<GridHeaderContainerProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.hideWeekends ? `repeat(10, 1fr)` : `repeat(12, 1fr)`};
  background-color: ${Colors.Tan10};
  padding: 14px 20px;
  border-radius: 8px 8px 0 0;
  grid-column-start: 1;
  grid-column-end: -1;
`;

const DateCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-of-type {
    grid-column-start: 6;
  }
`;
