import {
  DeskReservationVisibility,
  GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery,
  ParticipationStatus,
} from 'generated';
import { useDateContext } from '@robinpowered/dashboard-apps-common';
import { useAuthContext, useLocationsContext, useTableContext } from 'contexts';
import { useDeskReservationsByDate, useGetEmployeeVisitsByDate } from 'hooks';
import { createContext, useContext, FC } from 'react';
import moment from 'moment-timezone';
import {
  GetReservationsResponse,
  isAssignedConfirmedReservation,
  isAssignedReservation,
  isHoteledReservation,
  isHotReservation,
  isReverseHoteledReservation,
  UserWeekDay,
} from '../constants';
import { OperationVariables, QueryResult } from '@apollo/client';

type WhosInContextValue = {
  employeeVisitsByDateRequest?: QueryResult<
    GetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingQuery,
    OperationVariables
  >;
  deskReservationsByDateRequest?: QueryResult<
    GetReservationsResponse,
    OperationVariables
  >;
  whosInWeekData?: {
    user: {
      name?: string | null;
      avatar?: string | null;
      id?: string;
      email?: string;
    };
    week: UserWeekDay[];
  }[];
  loading: boolean;
  daysMostFavoritesAreInOffice: {
    date: moment.Moment;
    numberOfUsersInThisDay: number;
  }[];
};

const WhosInContext = createContext<WhosInContextValue>({
  employeeVisitsByDateRequest: undefined,
  deskReservationsByDateRequest: undefined,
  whosInWeekData: undefined,
  loading: true,
  daysMostFavoritesAreInOffice: [],
});

export const WhosInProvider: FC = ({ children }) => {
  const { currentUser } = useAuthContext();
  const { usePagedUsersRequest } = useTableContext();
  const { selectedLocation } = useLocationsContext();
  const { weekStartDate, weekEndDate, weekScheduleDates } = useDateContext();

  const userIdsForReservations = currentUser?.id
    ? [
        currentUser.id,
        ...(usePagedUsersRequest?.data?.getPagedUsers.accountUsers.map(
          (user) => user.id
        ) || []),
      ]
    : usePagedUsersRequest?.data?.getPagedUsers.accountUsers.map(
        (user) => user.id
      ) || [];

  const deskReservationsByDateRequest = useDeskReservationsByDate(
    userIdsForReservations,
    weekStartDate,
    weekEndDate,
    selectedLocation?.id
  );

  const employeeVisitsByDateRequest = useGetEmployeeVisitsByDate(
    userIdsForReservations,
    weekStartDate,
    weekEndDate,
    selectedLocation?.id
  );

  const whosInWeekData = [
    currentUser,
    ...(usePagedUsersRequest?.data?.getPagedUsers.accountUsers || []),
  ].map((user) => {
    const userVisits =
      employeeVisitsByDateRequest.data?.getEmployeeVisitsForUsersAtLocationGroupedByDateAscending
        .map((dates) => {
          return dates.employeeVisits.find(
            (visit) => visit.userId === user?.id
          );
        })
        .filter((visit) => visit !== undefined);

    const response =
      deskReservationsByDateRequest.data
        ?.getDeskReservationsForUsersAtLocationGroupedByDateAscending
        ?.reservationsByDate;

    const userReservations = response?.map((date) => {
      return {
        date: moment(date.date),
        reservation: date.reservations.find((res) => {
          if (res.reservee.userId !== user?.id) {
            return false;
          }

          if (res.visibility === DeskReservationVisibility.JustMe) {
            // The following checks are the business rules we use to check if
            // a reservation counts towards 'whos in'.
            return false;
          }
          if (isAssignedConfirmedReservation(res)) {
            return true;
          }

          // filter out reservations for the current
          // if the reservation is today, and the end time is before now
          // this indicates that the user cancelled their reservation
          if (
            res.reservee.userId === currentUser?.id &&
            moment(res.startTime).isSame(moment(), 'date') &&
            moment(res.endTime).isBefore(moment())
          ) {
            return false;
          }
          return (
            res.reservee.participationStatus !== ParticipationStatus.Declined &&
            (isHotReservation(res) ||
              isHoteledReservation(res) ||
              isReverseHoteledReservation(res) ||
              isAssignedReservation(res))
          );
        }),
      };
    });

    const userWeek = weekScheduleDates.map((date) => {
      const userVisit = userVisits?.find((visit) =>
        moment(visit?.startTime)
          .tz(selectedLocation?.timezone || moment.tz.guess())
          .isSame(date, 'date')
      );
      const userReservation = userReservations?.find((reservation) =>
        moment(reservation?.date)
          .tz(selectedLocation?.timezone || moment.tz.guess())
          .isSame(date, 'date')
      );

      return { date: date, visit: userVisit, reservation: userReservation };
    });

    return {
      user: {
        name: user?.name,
        avatar: user?.avatar,
        id: user?.id,
        email: user?.primaryEmail?.email,
      },
      week: userWeek,
    };
  });

  // An array of {date, daycount} to keep track which dates have the highest
  // number of users in
  const daysOfWeekWithWhosInCounts = weekScheduleDates.map((date) => {
    // Go through each user in the whos in week data.  Check if they
    // have a visit or reservation for that day, if they do, then return
    // them in the list.  The list length then tells us how many were in that date
    const numberOfUsersInThisDay = whosInWeekData.filter((user) => {
      const userDay = user.week.find((day) => day.date.isSame(date));

      if (user.user.id === currentUser?.id) {
        return false;
      }

      return !!userDay?.reservation?.reservation || !!userDay?.visit;
    }).length;

    return { date, numberOfUsersInThisDay };
  });

  const daysOfWeekWithWhosInCountsNotInPast = daysOfWeekWithWhosInCounts.filter(
    (day) => day.date.isSameOrAfter(moment(), 'date')
  );

  // Which day has the most of the user's favorites being in office
  // Prevent the most popular day being in the past
  const largestNumOfUsersInOnADay = daysOfWeekWithWhosInCountsNotInPast.reduce(
    (acc, curr) => {
      return curr.numberOfUsersInThisDay >= acc
        ? curr.numberOfUsersInThisDay
        : acc;
    },
    0
  );

  // If there is a 'tie' of 2 days, then we list them.  Any more and we will not
  // consider any days to be the 'best'
  const daysMostFavoritesAreInOffice =
    daysOfWeekWithWhosInCountsNotInPast.filter(
      (day) => day.numberOfUsersInThisDay === largestNumOfUsersInOnADay
    );

  const loading =
    deskReservationsByDateRequest.loading ||
    employeeVisitsByDateRequest.loading;

  const value: WhosInContextValue = {
    employeeVisitsByDateRequest,
    deskReservationsByDateRequest,
    whosInWeekData,
    loading,
    daysMostFavoritesAreInOffice,
  };

  return (
    <WhosInContext.Provider value={value}>{children}</WhosInContext.Provider>
  );
};

export const useWhosInContext = (): WhosInContextValue => {
  return useContext(WhosInContext);
};
