import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { Toast, ToastType } from './types';

type ToastsProps = {
  toasts: Toast[];
};

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div<{ type: ToastType }>`
  text-align: center;
  width: 500px;
  border-radius: 6px;
  line-height: 1.2;
  padding: 20px;
  color: ${Colors.White0};
  background: ${({ type }) =>
    type === ToastType.SUCCESS ? Colors.Green100 : Colors.Red100};
`;

export const Toasts: FC<ToastsProps> = ({ toasts }) => {
  if (!toasts.length) {
    return null;
  }
  return (
    <Container>
      {toasts.map(({ type, message }) => (
        <Card type={type} key={message}>
          {message}
        </Card>
      ))}
    </Container>
  );
};
