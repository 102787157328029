var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-bitwise */
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Color from 'color';
import { Colors } from '@robinpowered/design-system';
var initials = function (name) {
    var _a = __read(name.split(' '), 2), firstName = _a[0], lastName = _a[1];
    return firstName && lastName
        ? "".concat(firstName.charAt(0).toUpperCase()).concat(lastName.charAt(0).toUpperCase())
        : "".concat(firstName.charAt(0).toUpperCase());
};
var randomColorFromString = function (str) {
    var hash = 0;
    if (str.length === 0) {
        return Colors.Gray40;
    }
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    var color = '#';
    for (var j = 0; j < 3; j++) {
        var value = (hash >> (j * 8)) & 255;
        color += "00".concat(value.toString(16)).substr(-2);
    }
    return color;
};
// Get contrast color
var contrastColor = function (hex) {
    var bg = Color(hex);
    if (bg.contrast(Color(Colors.White0)) > 4.5) {
        return Colors.White0;
    }
    return Colors.Gray110;
};
var AvatarContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 100%;\n  overflow: hidden;\n  width: ", ";\n  height: ", ";\n  color: ", ";\n  background-color: ", ";\n"], ["\n  border-radius: 100%;\n  overflow: hidden;\n  width: ", ";\n  height: ", ";\n  color: ", ";\n  background-color: ", ";\n"])), function (_a) {
    var size = _a.size;
    return "".concat(size || 34, "px");
}, function (_a) {
    var size = _a.size;
    return "".concat(size || 34, "px");
}, function (_a) {
    var fgColor = _a.fgColor;
    return fgColor;
}, function (_a) {
    var bgColor = _a.bgColor;
    return bgColor;
});
var AvatarInitials = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  /*\n    NOTE: calculation is based on default width/height of 34px,\n    and font size of 12px.\n  */\n  font-size: ", ";\n  line-height: ", ";\n  font-weight: 500;\n  justify-content: center;\n  align-items: center;\n  user-select: none;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  /*\n    NOTE: calculation is based on default width/height of 34px,\n    and font size of 12px.\n  */\n  font-size: ", ";\n  line-height: ", ";\n  font-weight: 500;\n  justify-content: center;\n  align-items: center;\n  user-select: none;\n"])), function (_a) {
    var size = _a.size;
    return size ? "".concat(Math.round(size / 2.7), "px") : '12px';
}, function (_a) {
    var size = _a.size;
    return size ? "".concat(Math.round(size / 2.7), "px") : '12px';
});
var AvatarImg = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  border-radius: 100%; // Safari Fix\n  object-fit: cover;\n"], ["\n  width: 100%;\n  height: 100%;\n  border-radius: 100%; // Safari Fix\n  object-fit: cover;\n"])));
export var Avatar = function (_a) {
    var src = _a.src, name = _a.name, color = _a.color, children = _a.children, className = _a.className, size = _a.size;
    var avatarImage = new Image();
    var _b = __read(useState(false), 2), imageLoading = _b[0], setImageLoading = _b[1];
    var _c = __read(useState(false), 2), imageError = _c[0], setImageError = _c[1];
    useEffect(function () {
        if (src) {
            avatarImage.onload = function () { return setImageLoading(false); };
            avatarImage.onerror = function () {
                setImageError(true);
                setImageLoading(false);
            };
            avatarImage.src = src;
            setImageLoading(true);
        }
    }, [src]); // eslint-disable-line react-hooks/exhaustive-deps
    var bgColor = randomColorFromString(name);
    var fgColor = contrastColor(bgColor);
    var avatarContents = '';
    if (imageError) {
        avatarContents = (_jsx(AvatarInitials, __assign({ size: size }, { children: initials(name || '') })));
    }
    else if (src) {
        bgColor = 'white';
        if (imageLoading) {
            // TODO: proper loading
            avatarContents = null;
        }
        else {
            avatarContents = _jsx(AvatarImg, { src: src, alt: name });
        }
    }
    else if (children) {
        avatarContents = children;
        if (color) {
            bgColor = color;
        }
    }
    else {
        avatarContents = (_jsx(AvatarInitials, __assign({ size: size }, { children: initials(name || '') })));
    }
    return (_jsx(AvatarContainer, __assign({ title: name, bgColor: bgColor, fgColor: fgColor, className: className, size: size }, { children: avatarContents })));
};
var templateObject_1, templateObject_2, templateObject_3;
