import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './en';
import { fr } from './fr';
import { fr_ca } from './fr-ca';
import { de } from './de';
import { es } from './es';
import { ja } from './ja';
// interface Resources {
//   en: typeof en;
//   fr: typeof fr;
//   'fr-CA': typeof fr_ca;
//   de: typeof de;
//   es: typeof es;
//   ja: typeof ja;
// }
export var defaultNS = 'common';
export var resources = { en: en, fr: fr, 'fr-CA': fr_ca, de: de, es: es, ja: ja };
export var i18n = i18next.createInstance().use(initReactI18next);
i18n.init({
    fallbackLng: 'en',
    resources: resources,
});
//.then(() => addTranslations(resources));
// const addTranslations = (resources: Resources): void => {
//   Object.entries(resources).forEach(([lng, namespaces]) => {
//     Object.entries(namespaces).forEach(([ns, resources]) => {
//       i18n.addResourceBundle(lng, ns, resources);
//     });
//   });
// };
