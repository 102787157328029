import styled from '@emotion/styled';
import {
  Body06,
  Colors,
  Heading02,
  Skeleton,
} from '@robinpowered/design-system';
import StarOutline from '@robinpowered/icons/StarOutline';
import { AnonymousOrg } from 'components';
import { useTableContext } from 'contexts';
import { useTranslation } from 'react-i18next';

type Props = {
  avatar?: string | null;
  header?: string | null;
  numberOfEmployees: number;
};

export const OrganizationDetails = ({
  avatar,
  header,
  numberOfEmployees,
}: Props) => {
  const { t } = useTranslation('peopleTable');
  const { tableView } = useTableContext();

  if (!header) {
    return <Skeleton height={50} width={200} marginBottom={'24px'} />;
  }

  return (
    <OrganizationDetailsWrapper>
      {tableView === 'FAVORITES' || tableView === 'COMPARE-SCHEDULE' ? (
        <FavoriteIcon>
          <StarOutline color={Colors.Magenta100} size={35} />
        </FavoriteIcon>
      ) : avatar ? (
        <OrganizationLogo src={avatar} alt={t('search.logo.alt', header)} />
      ) : (
        <AnonymousOrg />
      )}
      <OrganizationMetaWrapper>
        <Heading02>{header}</Heading02>
        <Body06 color={Colors.Gray80} marginTop={'4px'}>
          {t('search.employees', { count: numberOfEmployees })}
        </Body06>
      </OrganizationMetaWrapper>
    </OrganizationDetailsWrapper>
  );
};

const OrganizationDetailsWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;
const OrganizationLogo = styled.img`
  display: block;
  height: 54px;
  width: 54px;
  border-radius: 8px;
`;
const OrganizationMetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
const FavoriteIcon = styled.div`
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.Magenta10};
  border-radius: 8px;
`;
