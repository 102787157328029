import { useAmplitude, useTableContext } from 'contexts';
import { useState, useCallback, useEffect } from 'react';
import { useTeam } from './useTeam';

type UseStagedFavorites = {
  handleAddOrRemoveFavorite: (userId: string) => void;
  isUserStaged: (userId: string) => boolean;
};

// Hook used for 'staging' favorited users to allow a user to subsequently
// click multiple favorites quickly, then debounce the query for performance
export const useStagedFavorites = (): UseStagedFavorites => {
  const { saveFavorite, removeFavorite } = useTeam();
  const [stagedUserIds, setStagedUserIds] = useState<string[]>([]);
  const { usePagedUsersRequest } = useTableContext();
  const { trackEvent } = useAmplitude();

  const isUserStaged = useCallback(
    (userId: string): boolean =>
      !!stagedUserIds?.find((stagedUserId) => stagedUserId === userId),
    [stagedUserIds]
  );

  const handleAddOrRemoveFavorite = useCallback(
    (userId: string) => {
      if (isUserStaged(userId)) {
        // Remove user
        setStagedUserIds(
          stagedUserIds?.filter((stagedUserId) => stagedUserId !== userId)
        );
        trackEvent('removed-person-from-favorites', {
          application: 'dashboard-people-tab',
        });
        removeFavorite(userId);
      } else {
        // Add user
        trackEvent('added-person-from-favorites', {
          application: 'dashboard-people-tab',
        });
        setStagedUserIds([...(stagedUserIds || []), userId]);
        saveFavorite(userId);
      }
    },
    [isUserStaged, removeFavorite, saveFavorite, trackEvent, stagedUserIds]
  );

  useEffect(() => {
    if (!usePagedUsersRequest?.loading) {
      setStagedUserIds(
        usePagedUsersRequest?.data?.getPagedUsers.accountUsers
          .filter((user) => user.isMyFavorite)
          .map((user) => user.id) || []
      );
    }
  }, [
    usePagedUsersRequest,
    usePagedUsersRequest?.data?.getPagedUsers.accountUsers,
    usePagedUsersRequest?.loading,
  ]);

  return {
    handleAddOrRemoveFavorite,
    isUserStaged,
  };
};
