import React, { useMemo } from 'react';
import { useDateContext } from '@robinpowered/dashboard-apps-common';
import {
  Heading03,
  Row,
  Colors,
  Button,
  Heading06,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { UtilityButton } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import ChevronLeftOutline from '@robinpowered/icons/ChevronLeftOutline';
import ChevronRightOutline from '@robinpowered/icons/ChevronRightOutline';

export const WeekPicker = (): JSX.Element => {
  const {
    weekStartDate: weekStart,
    setWeekIndex: onWeekStartChanged,
    weekIndex,
  } = useDateContext();
  const { t } = useTranslation('peopleTable');
  const displayDate = useMemo(
    () => `${t('header.weekPicker.date', { date: weekStart.format(`MMM D`) })}`,
    [t, weekStart]
  );
  const navigateRight = () => {
    onWeekStartChanged(weekIndex + 1);
  };
  const navigateLeft = () => {
    if (weekIndex > 0) {
      onWeekStartChanged(weekIndex - 1);
    }
  };

  const resetPicker = () => {
    onWeekStartChanged(0);
  };

  return (
    <ToolbarGroup alignItems="center">
      <WeekButton
        variant="secondary"
        onClick={resetPicker}
        aria-label={t('aria.favorites')}
      >
        <Heading06 color={Colors.Gray100}>
          {t('header.weekPicker.button')}
        </Heading06>
      </WeekButton>
      <UtilityButton
        onClick={navigateLeft}
        aria-label={t('navLeft')}
        disabled={weekIndex === 0}
        icon={ChevronLeftOutline}
      />
      <UtilityButton
        onClick={navigateRight}
        aria-label={t('navRight')}
        icon={ChevronRightOutline}
      />

      <Heading03>{displayDate}</Heading03>
    </ToolbarGroup>
  );
};

const ToolbarGroup = styled(Row)`
  gap: 8px;
`;

const WeekButton = styled(Button)`
  margin-left: 8px;
`;
