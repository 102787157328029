import React from 'react';
import styled from '@emotion/styled';
import { useDateContext } from '@robinpowered/dashboard-apps-common';
import { useAuthContext, useTableContext, useWhosInContext } from 'contexts';
import { GridHeader, GridRow } from './components';
import { Colors, Skeleton } from '@robinpowered/design-system';
import { NoResultsCTAContainer } from '../';

export const ScheduleViewContainer = (): JSX.Element => {
  const { currentUser } = useAuthContext();
  const { tableLoading, showNoResultsCTA } = useTableContext();
  const { whosInWeekData, loading } = useWhosInContext();
  const { weekScheduleDates, hideWeekends } = useDateContext();

  return (
    <Grid>
      <GridHeader dates={weekScheduleDates} hideWeekends={hideWeekends} />
      {loading || tableLoading ? (
        <>
          <Skeleton width={'calc(100% - 28px)'} height={40} margin={'14px'} />
          <Skeleton width={'calc(100% - 28px)'} height={40} margin={'14px'} />
          <Skeleton width={'calc(100% - 28px)'} height={40} margin={'14px'} />
          <Skeleton width={'calc(100% - 28px)'} height={40} margin={'14px'} />
          <Skeleton width={'calc(100% - 28px)'} height={40} margin={'14px'} />
        </>
      ) : showNoResultsCTA ? (
        <NoResultsWrapper>
          <NoResultsCTAContainer />
        </NoResultsWrapper>
      ) : (
        whosInWeekData?.map((whosInUser) => {
          return (
            <GridRow
              key={whosInUser.user?.id}
              avatar={whosInUser.user?.avatar}
              name={whosInUser.user?.name}
              email={whosInUser.user?.email}
              week={whosInUser.week}
              isCurrentUser={whosInUser.user?.id === currentUser?.id}
              hideWeekends={hideWeekends}
            />
          );
        })
      )}
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  border: 1px solid ${Colors.Gray10};
  border-radius: 8px;
`;

const NoResultsWrapper = styled.div`
  padding: 80px 0;
`;
