import React from 'react';
import { ButtonV4 } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { Popover } from './Popover';
import {
  UserWeekDay,
  isAssignedConfirmedReservation,
  isAssignedReservation,
} from '../../../../../../../../constants';
import { DayInfo } from './DayInfo';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { useToast, useWhosInContext } from 'contexts';
import { endOrCancelReservationMutation } from 'graphql/cancelReservations';
import { cancelEmployeeVisitMutation } from 'graphql/cancelEmployeeVisit';

type Props = {
  day: UserWeekDay;
  closePopover: () => void;
};

export const CancelPlansPopover = ({ day, closePopover }: Props) => {
  const { t } = useTranslation(['compareSchedules', 'common']);
  const { error } = useToast();
  const { deskReservationsByDateRequest, employeeVisitsByDateRequest } =
    useWhosInContext();
  const handleError = () => error(t('common:try_again'));

  const [cancelReservation] = useMutation(endOrCancelReservationMutation, {
    onError: handleError,
  });

  const [cancelEmployeeVisit] = useMutation(cancelEmployeeVisitMutation, {
    onError: handleError,
  });

  const reservation = day.reservation?.reservation;
  const visit = day.visit;
  const showReservationButton =
    (reservation &&
      !isAssignedConfirmedReservation(reservation) &&
      isAssignedReservation(reservation)) ||
    (reservation && !isAssignedReservation(reservation));

  const showCancelButton = visit || showReservationButton;

  return (
    <Popover>
      <DayInfo day={day} />

      {showCancelButton && (
        <ButtonGroup>
          {showReservationButton && (
            <ButtonV4
              destructive
              isLoading={deskReservationsByDateRequest?.loading}
              size="small"
              variant="secondary"
              label={t('popover.cancelplans.cancel')}
              aria-label={t('popover.cancelplans.cancel')}
              onClick={async () => {
                await cancelReservation({
                  variables: {
                    reservationId: day.reservation?.reservation?.id,
                  },
                });
                deskReservationsByDateRequest?.refetch();
                closePopover();
              }}
            />
          )}
          {visit && (
            <ButtonV4
              size="small"
              isLoading={
                deskReservationsByDateRequest?.loading ||
                employeeVisitsByDateRequest?.loading
              }
              variant="secondary"
              label={t('popover.cancelplans.not_in')}
              aria-label={t('popover.cancelplans.not_in')}
              onClick={async () => {
                await cancelEmployeeVisit({
                  variables: { employeeVisitId: day.visit?.id },
                });
                employeeVisitsByDateRequest?.refetch();
                deskReservationsByDateRequest?.refetch();
                closePopover();
              }}
            />
          )}
        </ButtonGroup>
      )}
    </Popover>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  align-items: stretch;
  gap: 8px;
  margin-top: 16px;
`;
