import { gql } from '@apollo/client';

export const joinOfficeMutation = gql`
  mutation JoinOffice(
    $userId: ID!
    $locationId: ID!
    $startTime: ISODateTime!
    $endTime: ISODateTime!
    $type: DeskConfirmationType!
  ) {
    joinOffice(
      userId: $userId
      locationId: $locationId
      startTime: $startTime
      endTime: $endTime
      type: $type
    )
  }
`;
