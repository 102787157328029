import styled from '@emotion/styled';
import { Body06, Colors } from '@robinpowered/design-system';
import CalendarSolid from '@robinpowered/icons/CalendarSolid';
import DirectorySolid from '@robinpowered/icons/DirectorySolid';
import { useTableContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const ButtonGroup = (): JSX.Element => {
  const { t } = useTranslation('peopleTable');
  const { tableView, setTableView } = useTableContext();

  return (
    <>
      <ToggleButton
        onClick={() => setTableView('COMPARE-SCHEDULE')}
        active={tableView === 'COMPARE-SCHEDULE'}
        aria-label={t('header.button_group.compare')}
      >
        <CalendarSolid
          height={16}
          width={16}
          color={
            tableView === 'COMPARE-SCHEDULE' ? Colors.White0 : Colors.Gray50
          }
        />
        <Tooltip>
          <TooltipCopy>{t('header.tooltip.schedule')}</TooltipCopy>
        </Tooltip>
      </ToggleButton>
      <ToggleButton
        onClick={() => {
          setTableView('FAVORITES');
        }}
        active={tableView === 'FAVORITES'}
        aria-label={t('header.button_group.favorites')}
      >
        <DirectorySolid
          height={16}
          width={16}
          color={tableView === 'FAVORITES' ? Colors.White0 : Colors.Gray50}
        />
        <Tooltip>
          <TooltipCopy>{t('header.tooltip.detail')}</TooltipCopy>
        </Tooltip>
      </ToggleButton>
    </>
  );
};

type ToggleButtonProps = {
  active?: boolean;
};

const ToggleButton = styled.button<ToggleButtonProps>`
  cursor: pointer;
  height: 32px;
  width: 42px;
  background-color: ${(props) =>
    props.active ? Colors.Gray50 : Colors.White0};
  border: 1px solid ${Colors.Gray50};
  position: relative;

  &:first-of-type {
    border-radius: 16px 0px 0px 16px;
  }

  &:last-of-type {
    border-radius: 0px 16px 16px 0px;
  }
  &:hover {
    div {
      transition: 0.2s;
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }
`;

const Tooltip = styled.div`
  background: ${Colors.Gray100};
  position: absolute;
  width: fit-content;
  border-radius: 4px;
  padding: 8px;
  display: block;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  white-space: nowrap;
  /* center above the element */
  bottom: calc(100% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TooltipCopy = styled(Body06)`
  color: ${Colors.White0};
`;
