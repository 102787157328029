var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useQuery } from '@apollo/client';
import { useHeaderContext } from '../contexts';
export var FEATURE_FLAG_QUERY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query FeatureFlagQuery($flagType: FeatureFlagType) {\n    isFeatureFlagEnabledForOrgOrMe(flagType: $flagType)\n  }\n"], ["\n  query FeatureFlagQuery($flagType: FeatureFlagType) {\n    isFeatureFlagEnabledForOrgOrMe(flagType: $flagType)\n  }\n"])));
export var useFeatureFlag = function (flagType) {
    var activeOrg = useHeaderContext().activeOrg;
    return useQuery(FEATURE_FLAG_QUERY, {
        variables: { flagType: flagType },
        nextFetchPolicy: 'cache-only',
        skip: !(activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.id),
    });
};
var templateObject_1;
