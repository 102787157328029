import * as React from 'react';

export const HamburgerMenu = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" fill="none">
      <path
        stroke="#5C5258"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M2.5 5h20M2.5 13h20M2.5 21h20"
      />
    </svg>
  );
};
