import React from 'react';
import InfoOutline from '@robinpowered/icons/InfoOutline';
import { Body06, Colors, Link } from '@robinpowered/design-system';
import styled from '@emotion/styled';

type Props = {
  copy: string;
  tooltip: string;
  tooltipLink: string;
  tooltipLinkCopy: string;
};

export const DetailColumn = ({
  copy,
  tooltip,
  tooltipLink,
  tooltipLinkCopy,
}: Props): JSX.Element => {
  return (
    <DetailColumnWrapper>
      {copy}
      <TooltipWrapper tabIndex={0}>
        <InfoOutline width={20} height={20} color={Colors.Gray70} />
        <Tooltip>
          <TooltipCopy as={'p'} color={Colors.Gray70}>
            {tooltip}
          </TooltipCopy>
          <TooltipLink href={tooltipLink} target="_blank" tabIndex={0}>
            {tooltipLinkCopy}
          </TooltipLink>
        </Tooltip>
      </TooltipWrapper>
    </DetailColumnWrapper>
  );
};

const DetailColumnWrapper = styled.div`
  display: flex;
  align-content: center;
`;

const TooltipWrapper = styled.div`
  margin-left: 6px;
  position: relative;

  &:hover,
  &:focus,
  &:focus-within {
    div {
      transition: 0.2s;
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }
`;

const Tooltip = styled.div`
  background: ${Colors.White0};
  position: absolute;
  width: 265px;
  border-radius: 8px;
  left: -118px;
  top: 30px;
  padding: 16px;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.06));
  display: block;
  transform: translateZ(0);
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.3s;
  z-index: 100;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid ${Colors.White0};
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    left: 120px;
    top: -15px;
  }
`;

const TooltipCopy = styled(Body06)`
  max-width: 265px;
  margin-bottom: 12px;
  word-break: break-word;
  white-space: normal;
`;

const TooltipLink = styled(Link)`
  margin-top: 20px;
`;
