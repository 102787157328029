import { gql, useQuery } from '@apollo/client';
import { useAuthContext } from 'contexts';
import { GetPagedUsersQuery } from 'generated';

export const GET_PAGED_USERS = gql`
  query getPagedUsers(
    $organizationId: ID!
    $limit: Int
    $filters: PagedUserFilterInput
    $offset: Int
  ) {
    getPagedUsers(
      organizationId: $organizationId
      limit: $limit
      filters: $filters
      offset: $offset
    ) {
      hasNextPage
      nextPageOffset
      accountUsersTotal

      accountUsers {
        id
        name
        isMyFavorite
        defaultLocation {
          id
          name
        }
        avatar
        isMyFavorite
        department
        primaryEmail {
          email
        }
      }
    }
  }
`;

export type PagedUserResult = NonNullable<GetPagedUsersQuery['getPagedUsers']>;
export type PagedUser = PagedUserResult['accountUsers'][0];

type PagedUserParams = {
  limit: number;
  favoriteFilter?: boolean;
  searchFilter?: string;
  pageOffset?: number;
  peopleSearchOptimizationEnabled: boolean;
};

export const GET_PAGED_USERS_V2 = gql`
  query getPagedUsersV2(
    $organizationId: ID!
    $limit: Int
    $filters: PagedUserFilterInput
    $offset: Int
  ) {
    getPagedUsers: getPagedUsersV2(
      organizationId: $organizationId
      limit: $limit
      filters: $filters
      offset: $offset
    ) {
      hasNextPage
      nextPageOffset
      accountUsersTotal
      accountUsers {
        id
        name
        isMyFavorite
        defaultLocation {
          id
          name
        }
        avatar
        isMyFavorite
        department
        primaryEmail {
          email
        }
      }
    }
  }
`;

// Setting an arbitrary upper limit for users
// loaded in to the table
export const UPPER_LIMIT_TABLE_USERS = 50;

// The upper limit for compare schedule view
export const UPPER_LIMIT_COMPARE_SCHEDULE_USERS = 15;

/**
 * A hook which returns a list of all users for an org, with a filter to target favorites
 */
export function usePagedUsers({
  limit,
  favoriteFilter = false,
  searchFilter,
  pageOffset,
  peopleSearchOptimizationEnabled,
}: PagedUserParams) {
  const {
    currentOrg,
    currentUser,
    loading: orgLoading,
    error: orgError,
  } = useAuthContext();
  return useQuery<GetPagedUsersQuery>(
    peopleSearchOptimizationEnabled ? GET_PAGED_USERS_V2 : GET_PAGED_USERS,
    {
      skip: orgLoading || !!orgError || !currentOrg?.id || !currentUser?.id,
      errorPolicy: 'all',
      variables: {
        limit,
        offset: pageOffset,
        filters: {
          favoriteFilter,
          userNameFilter: searchFilter,
        },
        organizationId: currentOrg?.id,
      },
    }
  );
}
