export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type Toast = {
  type: ToastType;
  message: string;
};

export type ToastContext = {
  toasts: Toast[];
  success: (message: string, domId?: string) => void;
  error: (message: string, domId?: string) => void;
  clearToasts: () => void;
};

export type ToastOptions = {
  duration?: number;
};
