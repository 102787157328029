import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  FeatureFlagType,
  GetFeatureFlagQuery,
  GetFeatureFlagQueryVariables,
} from 'generated';

export const FEATURE_FLAG_QUERY = gql`
  query getFeatureFlag($flagType: FeatureFlagType) {
    isFeatureFlagEnabledForOrgOrMe(flagType: $flagType)
  }
`;

export const useFeatureFlag = (flagType: FeatureFlagType) => {
  const { tenantId } = useApolloContext();
  return useQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(
    FEATURE_FLAG_QUERY,
    {
      skip: !tenantId,
      variables: {
        flagType,
      },
    }
  );
};
