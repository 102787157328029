import { gql, OperationVariables, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import { GetIsFavoritesEnabledQuery } from 'generated';

export const FAVORITES_ENABLED = gql`
  query getIsFavoritesEnabled {
    isFavoritesEnabled
  }
`;

export const useFavoritesEnabled = () => {
  const { tenantId } = useApolloContext();
  return useQuery<GetIsFavoritesEnabledQuery, OperationVariables>(
    FAVORITES_ENABLED,
    {
      skip: !tenantId,
    }
  );
};
