import styled from '@emotion/styled';
import BuildingSolid from '@robinpowered/icons/BuildingSolid';
import HomeSolid from '@robinpowered/icons/HomeSolid';
import { Colors } from '@robinpowered/design-system';

type UserStatus = 'inoffice' | 'remote';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  status: UserStatus;
}

export const StatusButton = ({
  status,
  disabled,
  ...props
}: Props): JSX.Element => {
  return (
    <IconButton
      status={status}
      clickable={!!props.onClick}
      disabled={disabled}
      {...props}
    >
      {status === 'inoffice' ? (
        <BuildingSolid
          height={16}
          width={16}
          color={disabled ? Colors.Magenta60 : Colors.White0}
        />
      ) : (
        <HomeSolid
          height={16}
          width={16}
          color={disabled ? Colors.Gray40 : Colors.Gray60}
        />
      )}
    </IconButton>
  );
};

type IconButtonProps = {
  clickable: boolean;
  status: UserStatus;
};

const IconButton = styled.button<IconButtonProps>`
  height: 32px;
  width: 32px;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ status }) =>
    status === 'inoffice' ? Colors.Magenta80 : Colors.Tan20};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  &:focus,
  &:active {
    outline: none;
    box-shadow: ${({ clickable }) =>
      clickable && ` 0 0 0 4px ${Colors.Blue100}`};
  }
  &:hover,
  &:focus {
    background-color: ${({ status }) =>
      status === 'inoffice' ? Colors.Magenta110 : Colors.Tan20};
  }

  &:hover:not(:focus) {
    ${({ status, clickable }) =>
      status === 'remote' && clickable && `border: 1px solid ${Colors.Gray30}`};
  }

  &:disabled {
    background-color: transparent;
    cursor: default;
    box-shadow: none;

    &:hover {
      border: none;
    }
  }
`;
