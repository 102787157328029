import { gql } from '@apollo/client';

export const GET_EMPLOYEE_VISITS_BY_DATE_ASC = gql`
  query GetEmployeeVisitsForUsersAtLocationGroupedByDateAscending(
    $userIds: [ID!]!
    $locationId: ID!
    $startTime: ISODateTime!
    $endTime: ISODateTime!
  ) {
    getEmployeeVisitsForUsersAtLocationGroupedByDateAscending(
      userIds: $userIds
      locationId: $locationId
      startTime: $startTime
      endTime: $endTime
    ) {
      date

      employeeVisits {
        id
        startTime
        endTime
        userId
        locationId
      }
    }
  }
`;
